import React from 'react';
import { toAbsoluteUrl } from "../../../../_metronic";
import PieChart from './piechart';
import './patientTrackingDetail.scss';
const color=type=>{
    switch(type){
        case (1):
            return {primary:'#e13b92',optional:'#e13b9233'};
            break;
        case (2):    
           return {primary:'#8950FC',optional:'#8950fc38'};
           break;
        case (3):    
           return {primary:'#8950FC',optional:'#8950fc38'};
           break;
        case (4):    
           return {primary:'#8950FC',optional:'#8950fc38'};
           break;
        default: 
           return {primary:'#8950FC ',optional:'#8950fc38'};
           
    }
}
const Tracking=props =>(
<>
<div className="card card-custom gutter-b card-stretch mt-3 ml-2 mr-2">
<div className="card-body pt-0 pb-0">
<div className="d-flex flex-wrap align-items-center mb-0">
<div className="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
<i class={`${props.events.extendedProps.icon} icon-size`} style={{color:color(props.events.extendedProps.type).primary}}></i>												</div>
<div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
<a href="#" className="text-dark-75 font-weight-bold text-hover-primary font-size-lg">{props.events.title} </a>
<span className="text-muted  font-size-smprops.e my-1">{props.events.extendedProps.description}</span>
</div>
<div className="d-flex align-items-center py-lg-0 py-2">
	<div className="d-flex flex-column text-right">
	<PieChart percentage={props.events.extendedProps.per} label={props.events.title} colors={color(props.events.extendedProps.type)} />
	</div>
</div>
</div>
</div>
</div>
</>
);

export default Tracking;