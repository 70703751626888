import { put, call,delay  } from 'redux-saga/effects';
import axios from 'axios';
import * as actions from '../actions';
import * as Constants from '../../shared/constants';
import Qs from 'qs'

export function* initGetQuestionTypeSaga(action) {
    try {
      axios.defaults.withCredentials = true;
      const response = yield axios.get( process.env.REACT_APP_API_URL+'question-type-function');
      yield put(actions.getQuestionSuccess(response.data));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
      }
    }
  }

  export function* initCreateQuestionSaga(action) {
    try {
      axios.defaults.withCredentials = true;
      const response = yield axios.post( process.env.REACT_APP_API_URL+'create-question',action.data);
      yield put(actions.showSnackBarNotification({
        message:action.data.id==''?'Question Created successfully':'Question updated successfully',
        type:Constants.notificationType.SUCCESS,
        open:true
       }));
       yield put(actions.clearForm());
       yield delay(500);

      yield put(actions.createQuestionSuccess(response.data));
    } catch (error) {
      yield put(actions.showSnackBarNotification({
        message:action.data.id==''?'There is error in Creating Question':'There is error in creating Question',
        type:Constants.notificationType.ERROR,
        open:true
  }));
      if(error.response.status==401){
        yield put(actions.logout());
      }
      yield put(actions.createQuestionFailed(error));
    }
  }


  export function* initGetAllQuestionSaga(action) {
    try {
      axios.defaults.withCredentials = true;
    //  const response = yield axios.get( `${process.env.REACT_APP_API_URL}getall-question?per_page=${action.data.perPage}&page=${action.data.page}&orderColumn=${action.data.orderColumn}&orderBy=${action.data.orderBy}`);
    let columns=[{data:"question",name:"question",searchable:true,orderable:true,search:{value:"",regex:false}}];
    // {params:{draw: "1", start: "0",columns:columns, order: [{column: "1", dir: "asc"}],length: "10"},paramsSerializer: params => {
    //   return Qs.stringify(params)
    // }}
    const response = yield axios.get( 
      `${process.env.REACT_APP_API_URL}getall-question`,
      {params:{...action.data},paramsSerializer: params => {
      return Qs.stringify(params)
    }});
      yield put(actions.getAllQuestionSuccess(response.data));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
      }else{
        yield put(actions.getAllQuestionFailed(error));
      }
    }
  }

  export function* initGetConnectedQuestionSaga(action) {
    try {
      axios.defaults.withCredentials = true;
      const response = yield axios.get( process.env.REACT_APP_API_URL+'connected-questions');
      yield put(actions.getConnectedQuestionSuccess(response.data));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
      }else{
        yield put(actions.getConnectedQuestionFailed(error));
      }
    }
  }


  export function* initGetQuestionPropertiesSaga(action) {
    try {
      axios.defaults.withCredentials = true;
      const response = yield axios.get( process.env.REACT_APP_API_URL+'get-question-properties/'+action.id);
      yield put(actions.getQuestionPropertiesSuccess(response.data));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
      }
    }
  }

  export function* initEditQuestionSaga(action) {
    try {
      axios.defaults.withCredentials = true;
      const response = yield axios.get(`${process.env.REACT_APP_API_URL}edit-question/${action.data.id}`);
      yield put(actions.editQuestionSuccess(response.data));
    } catch (error) {
  
      if(error.response.status==401){
        yield put(actions.logout());
      }
      yield put(actions.editQuestionFailed(error));
    }
  }
