import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  states:{
      options:[]
   },
   cities:{
       options:[]
   }
};

const getStateSuccess = (state, action) => {
    let data=[{
        value:"",
        displayValue:"Select State"
    }];
     if(action.data.states.length>0){
        for(let i=0;i<action.data.states.length;i++){
         data.push({value:action.data.states[i].state_id,displayValue:action.data.states[i].name});
         }
     }
   
 let abc=updateObject( state, {
    states:{options:data}
    });
   return abc;
};

const getCitySuccess = (state, action) => {
    let data=[{
        value:"",
        displayValue:"Select City"
    }];
     if(action.data.cities.length>0){
        for(let i=0;i<action.data.cities.length;i++){
         data.push({value:action.data.cities[i].city_id,displayValue:action.data.cities[i].name});
         }
     }
   
 let abc=updateObject( state, {
    cities:{options:data}
    });
   return abc;
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_STATE_SUCCESS:
           return getStateSuccess(state,action);
        case actionTypes.GET_CITY_REQUEST:
            return    updateObject( state, {
                cities:{options:[{
                    value:"",
                    displayValue:"Select City"
                }]}
                })
        case actionTypes.GET_CITY_SUCCESS:
            return getCitySuccess(state,action);   
        default: 
            return state;
    }
};

export default reducer;
