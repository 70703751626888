import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import {formBuilder} from '../../shared/formBuilder';
const initialState = {
    codes:{
        options:[{
        value:'',
        displayValue:'Select Program'
    }]
   
    
},
programFields:[]
};


const getProgramCode = (state, action) => {
   return state;
};
const getProgramCodeSuccess = (state, action) => {
   
    let data=[{
        value:'',
        displayValue:'Select Program'
    }];
    if(action.data.length>0){
        for(let i=0;i<action.data.length;i++){
        data.push({value:action.data[i].id,displayValue:action.data[i].code});
        }
    }
    return updateObject( state, {
        codes:{options:[...data]}
    });
};
const getProgramCodeDetailSuccess= (state,action) => {
   let fields= formBuilder(action.data);
    let updatedState= updateObject(state,{
        programFields:fields
    })
    return updatedState;
}

const getProgramCodeDetailRequest=(state,action)=>{
    // let fields=formBuilder([
    //     {
    //         "title": "Patient Body Composition",
    //         "Fields": [
    //             {
    //                 "label": "Height",
    //                 "type": "text",
    //                 "name": "height",
    //                 "required": true
    //             },
    //             {
    //                 "label": "Weight",
    //                 "type": "text",
    //                 "name": "weight",
    //                 "required": true
    //             },
    //             {
    //                 "label": "BMI",
    //                 "type": "text",
    //                 "name": "bmi",
    //                 "required": true
    //             },
    //             {
    //                 "label": "body composition analyser machine not working",
    //                 "type": "checkbox",
    //                 "name": "b_composition",
    //                 "required": false
    //             },
    //             {
    //                 "label": "Total Fat",
    //                 "type": "text",
    //                 "name": "tfat",
    //                 "required": true
    //             },
    //             {
    //                 "label": "Visceral Fat",
    //                 "type": "text",
    //                 "name": "vfat",
    //                 "required": true
    //             },
    //             {
    //                 "label": "Body Age",
    //                 "type": "text",
    //                 "name": "bodyA",
    //                 "required": true
    //             }
    //         ]
    //     },
    //     {
    //         "title": "Health Risk Assessment",
    //         "Fields": [
    //             {
    //                 "label": "On an average,how much water do you drink in a day",
    //                 "type": "radio",
    //                 "name": "water",
    //                 "options": [
    //                     {
    //                         "value": "1",
    //                         "displayValue": "Less than 1 L"
    //                     },
    //                     {
    //                         "value": "2",
    //                         "displayValue": "1-2L"
    //                     },
    //                     {
    //                         "value": "3",
    //                         "displayValue": "2-4L"
    //                     },
    //                     {
    //                         "value": "4",
    //                         "displayValue": "More than 4L"
    //                     }
    //                 ],
    //                 "required": true
    //             },
    //             {
    //                 "label": "Question type: Multiple Selection",
    //                 "type": "multiselect_checkbox",
    //                 "name": "water1",
    //                 "options": [
    //                     {
    //                         "value": "1",
    //                         "displayValue": "Vegetarian"
    //                     },
    //                     {
    //                         "value": "2",
    //                         "displayValue": "Lactose Intolerant"
    //                     },
    //                     {
    //                         "value": "3",
    //                         "displayValue": "Gluten Free"
    //                     },
    //                     {
    //                         "value": "4",
    //                         "displayValue": "Low Fat"
    //                     },
    //                     {
    //                         "value": "5",
    //                         "displayValue": "Other"
    //                     }
    //                 ],
    //                 "required": true
    //             },
    //             {
    //                 "label": "Question type: Date",
    //                 "type": "date",
    //                 "name": "date",
    //                 "required": true
    //             },
    //             {
    //                 "label": "Question type: Text (Summary)",
    //                 "type": "textarea",
    //                 "name": "textarea",
    //                 "required": true
    //             },
    //             {
    //                 "label": "Question type: File Upload",
    //                 "type": "pdf",
    //                 "name": "image",
    //                 "required": true
    //             }
    //         ]
    //     }
    // ]);
    // let updatedState= updateObject(state,{
    //     programFields:fields
    // })
    // return updatedState;
    return state;
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_PROGRAM_CODES_SUCCESS:
            return getProgramCodeSuccess(state,action);
        case actionTypes.GET_PROGRAM_CODE_DETAIL:
            return getProgramCodeDetailRequest(state,action);    
        case actionTypes.GET_PROGRAM_CODE_DETAIL_SUCCESS:
            return getProgramCodeDetailSuccess(state,action);    
        default: 
            return state;
    }
};

export default reducer;