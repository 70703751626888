import * as actionTypes from './actionTypes';


export const logout=()=>{
    return {
      type: actionTypes.LOGOUT

    }
  };

  export const clearForm=()=>{
    return {
      type:actionTypes.CLEAR_FORM
    }
  }
  
