import { put,takeLatest} from 'redux-saga/effects';
import axios from 'axios';
import * as actions from '../actions';
import * as Constants from '../../shared/constants';
import Qs from 'qs'

export function* initAssignDietChartSaga(action) {
    try {
      axios.defaults.withCredentials = true;
      const response = yield axios.post( process.env.REACT_APP_API_URL+'assign-diet-chart',action.data);
      yield put(actions.showSnackBarNotification({
        message:'Assign Diet Chart Changed successfully',
        type:Constants.notificationType.SUCCESS,
        open:true
       }));
       yield put(actions.getPatientProfileDetailRequest(action.data.cust_app_id));
      yield put(actions.assignDietChartSuccess(response.data));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
      }
      yield put(actions.showSnackBarNotification({
        message:error.response.data.error,
        type:Constants.notificationType.ERROR,
        open:true
  }));
      yield put(actions.assignDietChartFailed(error));
    }
  }

