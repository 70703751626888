import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Builder from "./Builder";
import Dashboard from "./Dashboard";
import CDashboard from './Counsellor-Dashboard/Dashboard';
import DocsPage from "./docs/DocsPage";
import { LayoutSplashScreen } from "../../../_metronic";
import PatientProfile  from "./Patient-Profile"; 
import KeyParametersListing  from "./Patient-Profile/key-parameters/KeyParametersListing.js"; 
import PatientTrackingDetail from './Patient-Traking-Detail';
import PatientList from './Patients/List'; 
import PatientList1 from './Patients/List1'; 

import PatientAdd from './Patients/Add'; 
import Broadcast from './Broadcast';
import CallSchedule from './call-schedule';
import CallDetail from './call-detail';
const EditPatientProfile= lazy(()=>
import('./Patients/EditPatientProfile')
);
const GoogleMaterialPage = lazy(() =>
  import("./google-material/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./react-bootstrap/ReactBootstrapPage")
);

export default function HomePage() {
 
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to='/dashboard' />
        }
        <Route path="/builder" component={Builder} />
        <Route path="/dashboard" component={CDashboard} />
        <Route path="/dashboard-1" component={Dashboard} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/docs" component={DocsPage} />
        <Route path="/patient1" exact component={PatientList} />
        <Route path="/patient-add" exact component={PatientAdd} />
        <Route path="/patient-profile/:id" exact component={PatientProfile} />
        <Route path="/patient-profile/:id/:module_id" exact component={KeyParametersListing} />
        <Route path="/patient-tracking-detail" exact component={PatientTrackingDetail} />
        <Route path="/broadcast" exact component={Broadcast} />
        <Route path="/call-schedule/:id" component={CallSchedule } />
        <Route path="/call-detail/:patient_id/:id" component={CallDetail} />
        <Route path="/patient" exact component={PatientList1} />
        <Route path="/edit-patient-profile/:id" exact component={EditPatientProfile} />


        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
