import React from "react";
import { CircularProgress, Typography } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { connect } from 'react-redux';
import * as actionsMethods from '../../../store/actions/index';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { removeKtdatatableCache} from '../../../shared/utility';

class Day extends React.Component {
  constructor(props){
      super(props);
	 removeKtdatatableCache();
  this.state = {
  };
}
	columns=()=>{
		var _this = this;
		return [
		{
		  field: 'code',
		  title: 'Program name'
		},
		{
		  field: 'name',
		  title: 'User name'
		},
		{
		  field: 'agenda',
		  title: 'Agenda'
		},
		{
		  field: 'date',
		  title: 'Date'
		}]
	}
  componentDidMount() {
		const column=this.columns();
		var _this=this;
		$(document).ready(function() {
		  window.KTDatatableRemoteAjaxDemo.init(process.env.REACT_APP_API_URL+'dashboard-todo',column,"");
		});
	}
  componentDidUpdate(prevProps,prevState){
  }

  render() {
	
    

    return (
		<>
        <div className="card card-custom">
				<div className="card-header flex-wrap border-0 pt-6 pb-0">
					<div className="card-title">
						<h3 className="card-label">To Do
						<span className="d-block text-muted pt-2 font-size-sm">Two Tasks required immediate actions</span></h3>
					</div>
					<div className="card-toolbar">
					</div>
				</div>
				<div className="card-body">
					<div className="mb-7">
						<div className="row align-items-center">
							<div className="col-lg-9 col-xl-8">
								<div className="row align-items-center">
									<div className="col-md-4 my-2 my-md-0">
										
									</div>
								</div>
							</div>
						</div>
					</div>
				
					<div className="datatable datatable-bordered datatable-head-custom" id="kt_datatable"></div>
				</div>
			</div>
		</>
    );

  }
}

const mapStateToProps= state=>{
    return {
        questionList:state.questionList
    }
   }
   const mapDispatchToProps = dispatch => {
     return {
        getAllQuestionRequest: data => dispatch(actionsMethods.getAllQuestionRequest(data))
 
   }
   }
   export default connect(mapStateToProps, mapDispatchToProps)(Day);
