import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {  useDispatch,useSelector } from "react-redux";
import {  hideSnackBarNotification } from '../../store/actions';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackbarsAlert= props => {
  const snackbarData = useSelector(state => state.snackbar);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(hideSnackBarNotification({
      message:'',
      type:'info',
      open:false
  }));
  };

    return (
      <Snackbar anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
 open={snackbarData.open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snackbarData.type}>
        {snackbarData.message}
        </Alert>
      </Snackbar>
  );
    }

  export default SnackbarsAlert;
