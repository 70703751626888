import { put,takeLatest} from 'redux-saga/effects';
import axios from 'axios';
import * as actions from '../actions';
import * as Constants from '../../shared/constants';
import Qs from 'qs'

export function* initSendDocSmsSaga(action) {
    try {
      axios.defaults.withCredentials = true;
      const response = yield axios.post( process.env.REACT_APP_API_URL+'send-doc-sms',action.data);
      yield put(actions.showSnackBarNotification({
        message:'Document SMS Sent successfully',
        type:Constants.notificationType.SUCCESS,
        open:true
       }));
      //  yield put(actions.getPatientProfileDetailRequest(action.data.cust_app_id));
      yield put(actions.sendDocSmsSuccess(response.data));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
      }
      yield put(actions.showSnackBarNotification({
        message:error.response.data.error,
        type:Constants.notificationType.ERROR,
        open:true
  }));
      yield put(actions.sendDocSmsFailed(error));
    }
  }

