import React from 'react';


const KeyParametersField = props=>(
	<>
	{(() => {
		switch(props.type) {
			case "file": 
				return (<span className="files-container">
					{props.answer.map((formElement,index) => {
						return <a className="btn btn-primary" href={formElement} target="_blank" key={index}>View</a>
						})
					}
				</span>);
			break;
			
			case "file_group": 
				return (<span className="files-container">
					{props.answer.map((formElement,index) => {
						return <a className="btn btn-primary" href={formElement} target="_blank" key={index}>View</a>
						})
					}
				</span>);
			break;
			
			case "multiselect_options": 
				return (<ul>
					{props.answer.map((formElement,index) => {
						return <li key={index}>{formElement}</li>
						})
					}
				</ul>);
			break;
			
			case "select2": 
				return (<ul>
					{props.answer.map((formElement,index) => {
						return <li key={index}>{formElement}</li>
						})
					}
				</ul>);
			break;
			
			case "checkbox": 
				return (<ul>
					{props.answer.map((formElement,index) => {
						return <li key={index}>{formElement}</li>
						})
					}
				</ul>);
			break;
			
			default: 
				return props.answer;
			break;
		}
	})()}
	</>
);

export default KeyParametersField;