import { data } from "jquery";

export const DYNAMIC_FIELDS=(fieldObj,cal_w=6)=>{
  let validation={};
  let errorMessage={};
  let  elementConfig={};
  let isradio=0;
  let elementType= 'input';
  if(fieldObj.mandatory==1){
validation={required:true};
errorMessage={required:"This field is required"};
  }
  if(fieldObj.type=='select' || fieldObj.type=='radio' || fieldObj.type=='checkbox' || fieldObj.type=='select2'){
    isradio=fieldObj.type=='radio'?1:0;
    cal_w=fieldObj.type=='radio'?12:cal_w;
    elementType=fieldObj.type=='checkbox' || fieldObj.type=='select2'?'multiple_autocomplete':'select';
    const options=fieldObj.type=='select'?[{value:'',displayValue:fieldObj.placeholder}]:[];
    for(let i=0;i<fieldObj.question_option.length;i++){
      options.push({value:fieldObj.question_option[i].id , displayValue:fieldObj.question_option[i].name,linked_question:fieldObj.question_option[i].linked_question});
    }
    elementConfig={
      options:options
    }
  }else if(fieldObj.type=='textarea'){
    cal_w=12;
    elementType='textarea';
    elementConfig={
      placeholder:fieldObj.placeholder
    }
  }else if(fieldObj.type=='datepicker'){
    elementConfig={
      type:'date',
      placeholder:fieldObj.placeholder
    };
  }else{
    elementConfig={
      type:fieldObj.type,
      placeholder:fieldObj.placeholder
    }
  }
  if(fieldObj.question_properties.length>0){
    for(let p=0;p<fieldObj.question_properties.length;p++){
      console.log(fieldObj.question_properties[p]);

      let attr=convertPropertyToAtt(fieldObj.question_properties[p]);
      elementConfig={
        ...elementConfig,
        ...attr
      }
    }
   
  }  
  return ({
    elementType: elementType,
    label:fieldObj.name,
    col:cal_w,
    fullRow:0,
    error:'',
    divider:0,
    heading:'',
    isradio:isradio,
    elementConfig:elementConfig,
    value: '',
    valueObj:'',
    validation:validation,
    errorMessage:errorMessage,
    valid: fieldObj.mandatory==1?false:true,
    touched: false,
    dbId:fieldObj.id,
    connectedQuestion:0,
    data:{},
    fieldType:fieldObj.type,
    mandatory:fieldObj.mandatory,
    properties:fieldObj.question_properties
});
};

export const DYNAMIC_TASK_FIELD=(fieldObj,cal_w=12)=>{
  let validation={};
  let errorMessage={};
  let  elementConfig={};
  let isradio=0;
  let elementType= 'mcheckbox';
  return ({
    elementType: elementType,
    label:fieldObj.name,
    col:cal_w,
    fullRow:0,
    error:'',
    divider:0,
    heading:'',
    isradio:isradio,
    elementConfig:elementConfig,
    value: '',
    valueObj:'',
    validation:validation,
    errorMessage:errorMessage,
    valid:true,
    touched: false,
    dbId:fieldObj.id,
    connectedQuestion:0,
    data:{},
    mandatory:fieldObj.mandatory
});
};



function convertPropertyToAtt(data){
 console.log("dddddd",data);
  let arraya=data.value.split(",");
  console.log("dddsfsdfsfs",arraya);
  if(arraya.length>1){
    let type=[];
   for(let j=0;j<arraya.length;j++){
     if(arraya[j]=='png'){
      type.push('image/png');
     }else if(arraya[j]=='jpeg'){
      type.push('image/jpeg');
     }else if(arraya[j]=='jpg'){
      type.push('image/jpg');
     }else if(arraya[j]=='gif'){
      type.push('image/gif');
     }else if(arraya[j]=='pdf'){
      type.push('application/pdf');
     }
   }
   if(type.length>0){
      return {accept:type.join(',')}
   }
   return {};
  }else{
  switch(data.master_values){
    case 'min':
      return {min:data.value};
    case 'max':
      return {max:data.value};
    default:
      return {[data.master_values]:data.value};    
  }
}
}