import React from "react";
import { CircularProgress, Typography } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { connect } from 'react-redux';
import * as actionsMethods from '../../../store/actions/index';
import {Link} from 'react-router-dom';
import TableCell from '@material-ui/core/TableCell';




    const columnStyleWithWidth = {
      top: "0px",
      left: "0px",
      zIndex: "100",
      position: "sticky",
      backgroundColor: "#fff",
      width: "150px"
   }

class Month extends React.Component {
  constructor(props){
      super(props);
  
  this.state = {
    page: 0,
    count: 1,
    rowsPerPage: 5,
    start:0,
    sortOrder:{name: "id", direction: "desc"},
    search:{value:'',regex:false},
    order:[{column:0, dir:'desc'}],
    serverSortingCol : [
        {data:"id",name:"id",searchable:true,orderable:true,search:{value:"",regex:false}},
        {data:"question",name:"question",searchable:true,orderable:true,search:{value:"",regex:false}},
        {data:"type_name",name:"type_name",searchable:true,orderable:true,search:{value:"",regex:false}},
        {data:"is_connected",name:"is_connected",searchable:true,orderable:true,search:{value:"",regex:false}},
    ],
    data: [["Loading Data..."]],
    columns: [
      {
        name: "id",
        label: "ID",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            // See the console for a detailed look at this object.
        
            return value;
          }
        },
      },
	  {
        name: "question",
        label: "Question",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            // See the console for a detailed look at this object.
        
            return value;
          }
        },
      },
      {
        name: "type_name",
        label: "Type",
        options: {},
      },
      {
        name: "is_connected",
        label: "Connections",
        options: {
            filter: false,
            sort:true,
           
            customBodyRender: (value, tableMeta, updateValue) => {
  
              return (<>
              {value==1 &&(<span className="label label-lg label-light-success label-inline">Yes</span>)}
              {value==0 && (<span className="label label-lg label-light-warning label-inline">No</span>)
              }

              </>);

        },
    }
      },
      {
        name: "id",
        label: "Action",
        options: {
            filter: false,
            sort:false,
            customHeadRender: ({index, ...column}) =>{
              return (
                <TableCell key={index} style={columnStyleWithWidth}>
                    <div >{column.label} </div>   
                </TableCell>
              )
           },
            customBodyRender: (value, tableMeta, updateValue) => {
              let editurl=`#`;
              return (<>{value>0 && ( <Link to={editurl} className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <path d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z" fill="#000000" fillRule="nonzero" transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)"></path>
                <path d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z" fill="#000000" fillRule="nonzero" opacity="0.3"></path>
                </g>
                </svg>
              </span>
            </Link>)}
              {value>0 && (<a href="#" className="btn btn-icon btn-light btn-hover-primary btn-sm">
	<span className="svg-icon svg-icon-md svg-icon-primary">
	<svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
	<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
	<rect x="0" y="0" width="24" height="24"></rect>
	<path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fillRule="nonzero"></path>
	<path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"></path>
	</g>
	</svg>
	</span>
</a>)}</>);
        },
      }
    }
    ],
    isLoading: true
  };
}

  componentDidMount() {
    this.props.getAllQuestionRequest({start:this.state.start,length:this.state.rowsPerPage,columns:this.state.serverSortingCol,order:this.state.order});
  }
  componentDidUpdate(prevProps,prevState){
     const {questionList}=this.props;
      if(questionList.data !=prevProps.questionList.data){
        this.setState({ 
            data: questionList.data, 
           page: questionList.page, 
           isLoading: false, 
            count: questionList.totalRows,
          });     
    }
  }


  sort = (page, sortOrder) => {
  let Index=this.state.serverSortingCol.findIndex(item=>item.name==sortOrder.name);
  this.props.getAllQuestionRequest({start:this.state.start,length:this.state.rowsPerPage,columns:this.state.serverSortingCol,order: [{column: Index, dir:sortOrder.direction}]});
     this.setState({ isLoading: true,
    order:[{column: Index, dir:sortOrder.direction}],
  sortOrder:{...sortOrder}});
   
  }
  changePage = (page, sortOrder) => {
   
      this.props.getAllQuestionRequest({start:page*this.state.rowsPerPage,length:this.state.rowsPerPage,columns:this.state.serverSortingCol,order:this.state.order,search:this.state.search});
    this.setState({
      isLoading: true,
    });

  };
  searchText=(text)=>{
      let searchObj={value:text,regex:false};
      this.props.getAllQuestionRequest({start:0*this.state.rowsPerPage,length:this.state.rowsPerPage,columns:this.state.serverSortingCol,order:this.state.order,search:searchObj});
      this.setState({
        isLoading: true,
        search:searchObj
      });

  }

  render() {

    const { data, page, count, isLoading, rowsPerPage, sortOrder } = this.state;

    const options = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'vertical',
      serverSide: true,
      count: count,
      rowsPerPage: rowsPerPage,
      rowsPerPageOptions: [],
      sortOrder: sortOrder,
      customToolbar: () => {
        return (
            <>
          
  
        </>
        );
      },
      onTableChange: (action, tableState) => {
     
        // a developer could react to change on an action basis or
        // examine the state as a whole and do whatever they want

        switch (action) {
          case 'changePage':
            this.changePage(tableState.page, tableState.sortOrder);
            break;
          case 'sort':
            this.sort(tableState.page, tableState.sortOrder);
            break;
          case 'search':
              this.searchText(tableState.searchText);
              break;  
          default:
            console.log('action not handled.');
        }
      }
    };

    

    return (
        
        <MUIDataTable title="" data={data} columns={this.state.columns} options={options} />
    );

  }
}

const mapStateToProps= state=>{
    return {
        questionList:state.questionList
    }
   }
   const mapDispatchToProps = dispatch => {
     return {
        getAllQuestionRequest: data => dispatch(actionsMethods.getAllQuestionRequest(data))
 
   }
   }
   export default connect(mapStateToProps, mapDispatchToProps)(Month);
