import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = { 
	tabs:[]
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.CHECK_PROGRAM_MODULES_SUCCESS:
            let newState= updateObject( state,{
               tabs:[ ...action.data]
            });
		 return newState;
        default: 
            return state;
    }
};

export default reducer;
