import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  isSubmitting:false
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.ASSIGN_DIET_CHART_REQUEST:
            return updateObject( state, {
                isSubmitting:true
            });
        case actionTypes.ASSIGN_DIET_CHART_SUCCESS:
        case actionTypes.ASSIGN_DIET_CHART_FAILED:
             return updateObject( state, {
                isSubmitting:false
            });
        default: 
            return state;
    }
};

export default reducer;
