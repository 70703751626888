


import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    function_to_call: [],
    id: '',
    is_connected: 0,
    mandatory: 0,
    options: [],
    placeholder: "",
    program_specific: 0,
    properties: [],
    question: "",
    type: 0,
    units: [],
    redirect: false
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.EDIT_QUESTION_REQUEST:
            return updateObject( state, {
                function_to_call: [],
                id: '',
                is_connected: 0,
                mandatory: 0,
                options: [],
                placeholder: "",
                program_specific: 0,
                properties: [],
                question: "",
                type: 0,
                units: [],
                redirect: false
                });
        case actionTypes.EDIT_QUESTION_SUCCESS:
      return updateObject( state, {
        ...action.data,
        redirect: false
        });

        case actionTypes.EDIT_QUESTION_FAILED:
            return updateObject( state, {
                function_to_call: [],
                id: '',
                is_connected: 0,
                mandatory: 0,
                options: [],
                placeholder: "",
                program_specific: 0,
                properties: [],
                question: "",
                type: 0,
                units: [],
                redirect: true
                });
        default: 
            return state;
    }
};

export default reducer;
