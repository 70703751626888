import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
   patientInfo:{
    age: "",
    code: [],
    cust_app_id: '',
    email: "",
    follow_up: "",
    gender: "",
    last_active: "",
    mode: "",
    name: "",
    onboarding_date: "",
    phone: "",
    picture: "",
    diets:[],
    programDoc:[],
    height:'',
    waist_size:'',
    weight:'',
    status:''
   }
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_PATIENT_PROFILE_DETAIL_SUCCESS:
      return updateObject( state, {
          patientInfo:{...action.data}
        });
        default: 
            return state;
    }
};

export default reducer;
