import React from 'react';
import Input from '../../../../components/UI/Input';
import { updateObject,checkValidity,checkValidityType } from '../../../../shared/utility';
import {TaskType,dynamicFields,otherFields} from './Fields';
import '../teleCallCreate.css';
import { connect } from 'react-redux';
import * as actionsMethods from '../../../../store/actions/index';
import * as Constants from '../../../../shared/constants';
import clsx from "clsx";


class CreateTask extends React.Component {
    constructor(props){
        super(props);
        this.state={
            isSubmitting:false,
            loadingButtonStyle:{
                paddingRight: "1rem"
              },
              loading:false,
            taskType:TaskType,
            options:{},
            nestedOptions:{},
            otherFields:otherFields,
            formisValid:false
        };
    }  
    resetForm=async()=>{
        await  this.setState({
            isSubmitting:false,
            loadingButtonStyle:{
                paddingRight: "1rem"
              },
              loading:false,
            taskType:TaskType,
            options:{},
            nestedOptions:{},
            otherFields:otherFields,
            formisValid:false,
            taskTypeFormIsValid:false,
            optionsFormIsValid:false,
            otherFieldsFormIsValid:true,
            nestedOptionsFormIsValid:true,
        });
        this.props.getTaskType();

 
     } 
   componentDidMount(){
       this.props.getTaskType();
   }

   componentDidUpdate(prevProps,prevState){
       const {taskType,isSubmitting}=this.props;
    if(taskType != prevProps.taskType){
        let updatedFormElement = updateObject(this.state.taskType['type_id'], {
          elementConfig:taskType
      });
      const updatedTaskList = updateObject(this.state.taskType, {
        ['type_id']: updatedFormElement
    });
    this.setState({taskType : updatedTaskList});
      }

      if(isSubmitting !=prevProps.isSubmitting && isSubmitting==false){
          this.setState({
            loading:false,
            loadingButtonStyle: {paddingRight: "1rem"},
            isSubmitting:false
          });
          if(this.props.isSubmitSuccess==true){
            this.resetForm();
          }
      }
   }
    inputChangedHandler = (event, inputIdentifier,value) => {
        if(inputIdentifier=='type_id'){
           
           this.setState({options:dynamicFields(event.target.value)});
        }
        this.commonChangedHandler(event, inputIdentifier,value,'taskType');

      }
      optionChangedHandler = (event, inputIdentifier,value) => {
          if(inputIdentifier=='connected_task'){
              if(event.target.checked){
                this.setState({nestedOptions:NestedOptionField()});
              }else{
                  this.setState({nestedOptions:{}});
              }
          }
           this.commonChangedHandler(event, inputIdentifier,value,'options');
      }
      otherChangedHandler = (event, inputIdentifier,value) => {
        this.commonChangedHandler(event, inputIdentifier,value,'otherFields');
      }
      nOptionChangedHandler = (event, inputIdentifier,value) => {
        this.commonChangedHandler(event, inputIdentifier,value,'nestedOptions');
      }
      commonChangedHandler = (event, inputIdentifier,value,stateName) => {
         let valueObj=[];
         let   actualValue;
          if(inputIdentifier=='file_format'){
            valueObj=value;
            actualValue=[];
                value.forEach(item=>{
                    actualValue.push(item.value);
                });
          }else{
    actualValue=value==undefined?event.target.value:value.value;
        actualValue= actualValue==undefined?event.target.checked:actualValue;
        if(inputIdentifier=='mandatory'){
          actualValue=event.target.checked?1:0;
        }
          }
        const updatedFormElement = updateObject(this.state[stateName][inputIdentifier], {
            value: actualValue,
           valueObj:valueObj,
                valid: checkValidity(actualValue, this.state[stateName][inputIdentifier].validation),
                touched: true,
                error:checkValidityType(actualValue, this.state[stateName][inputIdentifier].validation)==''?'':this.state[stateName][inputIdentifier].errorMessage[checkValidityType(actualValue, this.state[stateName][inputIdentifier].validation)],
          });
          const updatedOrderForm = updateObject(this.state[stateName], {
              [inputIdentifier]: updatedFormElement
          });
          let formisValid=true;
          for (let inputIdentifier in updatedOrderForm) {
              formisValid= updatedOrderForm[inputIdentifier].valid &&formisValid ;
          }
          let Vname=`${stateName}FormIsValid`;
          this.setState({[stateName]: updatedOrderForm, [Vname]: formisValid});
    }
   arrayConvertion=(data)=>{
       let dataArray=[];
    for (let key in data) {
        dataArray.push({
            id: key,
            config: data[key]
        });
    }
       return dataArray;
   }
   handleSubmit= (e)=>{
    e.preventDefault();
    if(!this.isFormValid()){
        this.props.showSnackBarNotification({
            message:'Please Fill required fields',
            type:Constants.notificationType.ERROR,
            open:true
          });
          return;
    }
    const formData = {};
    for (let formElementIdentifier in this.state.taskType) {
        formData[formElementIdentifier] = this.state.taskType[formElementIdentifier].value;
    }
    for (let formElementIdentifier in this.state.otherFields) {
        formData[formElementIdentifier] = this.state.otherFields[formElementIdentifier].value;
    }
    for (let formElementIdentifier in this.state.options) {
        formData[formElementIdentifier] = this.state.options[formElementIdentifier].value;
    }
    let updateObject={...formData,
      };
 this.setState({loadingButtonStyle:{ paddingRight: "3.5rem" },loading:true,isSubmitting:true});
    this.props.createTask(updateObject);
}
isFormValid=()=>{
    if(this.state.taskTypeFormIsValid && this.state.optionsFormIsValid &&  this.state.otherFieldsFormIsValid){
        return true;
    }
    return false;
   }
render(){ 
const formElementsArray =this.arrayConvertion(this.state.taskType);
const optionArray =this.arrayConvertion(this.state.options);
const NoptionArray =this.arrayConvertion(this.state.nestedOptions);
const otherFieldArray = this.arrayConvertion(this.state.otherFields);


let form = (
    <form className="kt-form" id="kt_form" onSubmit={e=>this.handleSubmit(e)}>
        <div className="kt-wizard-v1__form row">
        {formElementsArray.map((formElement,index) => (
            <Input 
                key={index}
                isradio={formElement.config.isradio==1?1:0}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.isProgram==1?this.props.codes:formElement.config.elementConfig}
                col={formElement.config.col}
                label={formElement.config.label}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                fullRow={formElement.config.fullRow}
                heading={formElement.config.heading}
                divider={formElement.config.divider}
                leftText={formElement.config.leftText}
                error={formElement.config.error}
                sendOtp={formElement.config.otp_resend==true?true:false}
                skipOtp={formElement.config.skip_otp==true?true:false}
                sendOtpClick={e=>this.handleResendOtp(e)}
                skipOtpClick={e=>this.handleSkipOtp(e)}
                changed={(event,value) => this.inputChangedHandler(event, formElement.id,value)}
                sendOtpLoading={this.props.sendOtpLoading}
                isOtpMatch={formElement.config.isOtpMatch==true?true:false}
                successMsg={formElement.config.successMsg}
                />
        ))}
         {this.state.taskType.type_id.value!='' && optionArray.map((formElement,index) => {
             
            let option= (
            <Input 
                key={index}
                isradio={0}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                col={formElement.config.col}
                label={formElement.config.label}
                value={formElement.config.value}
                valueObj={formElement.config.valueObj}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                fullRow={formElement.config.fullRow}
                heading={formElement.config.heading}
                divider={formElement.config.divider}
                leftText={formElement.config.leftText}
                error={formElement.config.error}
                changed={(event,value) => this.optionChangedHandler(event, formElement.id,value)}
                deleteMethod={(event) => this.deleteOption(event, formElement.id)}
                />
        );
     
        return option;
    }
        )}
        {this.state.taskType.type_id.value!='' && NoptionArray.map((formElement,index) => {
             
             let option= (
             <Input 
                 key={index}
                 isradio={0}
                 elementType={formElement.config.elementType}
                 elementConfig={formElement.config.elementConfig}
                 col={formElement.config.col}
                 label={formElement.config.label}
                 value={formElement.config.value}
                 invalid={!formElement.config.valid}
                 shouldValidate={formElement.config.validation}
                 touched={formElement.config.touched}
                 fullRow={formElement.config.fullRow}
                 heading={formElement.config.heading}
                 divider={formElement.config.divider}
                 leftText={formElement.config.leftText}
                 error={formElement.config.error}
                 changed={(event,value) => this.nOptionChangedHandler(event, formElement.id,value)}
                 />
         );
      
         return option;
     }
         )}

          {this.state.taskType.type_id.value!='' && otherFieldArray.map((formElement,index) => (
            <Input 
                key={index}
                isradio={formElement.config.isradio==1?1:0}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                col={formElement.config.col}
                label={formElement.config.label}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                fullRow={formElement.config.fullRow}
                heading={formElement.config.heading}
                divider={formElement.config.divider}
                leftText={formElement.config.leftText}
                error={formElement.config.error}
                changed={(event,value) => this.otherChangedHandler(event, formElement.id,value)}
                />
        ))}
        
        </div>
        <div className="kt-form__actions">
        <div className="flex-grow-1">
        </div>
      <div ><span className={`btn  btn-outline-dark btn-elevate kt-login__btn-primary mr-2 `} onClick={()=>this.resetForm()} >Reset</span>
      <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={this.props.isSubmitting}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                      }
                    )}`}
                    style={this.state.loadingButtonStyle}
                  >
                      Create Task
                  </button>
                </div>
                </div>
    </form>
);

  return (
  <>

<h3 class="font-weight-bold text-dark">Create Task</h3>
        <span className="text-muted font-weight-bold d-block mb-10">Create a new task for the counsellor to perform during a call </span>
        {form}   
   
    </>
  );
}
}


const NestedOptionField= ()=>{

    return ({
        nested_task:{
            elementType: 'select',
            label:'',
            heading:'',
            error:'',
            elementConfig: {
                options: [
                    {value: '', displayValue: 'Choose/Search connected Task'},
                    {value: 'radio', displayValue: 'radio'},
                    {value: 'select', displayValue: 'select'},
                    {value: 'file', displayValue: 'file'},
                    {value: 'textarea', displayValue: 'textarea'},
                    {value: 'number', displayValue: 'number'},
                    {value: 'text', displayValue: 'text'},
                    {value: 'select2', displayValue: 'select2'},
                    {value: 'checkbox', displayValue: 'checkbox'},
                    {value: 'datepicker', displayValue: 'datepicker'},
                    {value: 'date', displayValue: 'date'},
                    {value: 'pdf', displayValue: 'pdf'},
                    {value: 'time', displayValue: 'time'},
                    {value: 'multiselect_checkbox', displayValue: 'multiselect_checkbox'},
    
                ]
            },
            value: '',
            col:8,
            fullRow:4,
            validation: {
              required:true
            },
            errorMessage:{
             required:'Nested Option is required'
            },
            valid: false,
            touched:false
          }    
        });
}

const mapStateToProps= state=>{
    return {
        taskType:state.taskReducer.taskType,
        isSubmitting:state.taskReducer.isSubmitting,
        isSubmitSuccess:state.taskReducer.isSubmitSuccess
    }
   }
   const mapDispatchToProps = dispatch => {
     return {
         getTaskType: data => dispatch(actionsMethods.getTaskTypeRequest()),
         showSnackBarNotification:data=>dispatch(actionsMethods.showSnackBarNotification(data)),
         createTask: data => dispatch(actionsMethods.createTaskRequest(data))
 
   }
   }
   export default connect(mapStateToProps, mapDispatchToProps)(CreateTask);
 