import React from 'react';
import './callschedule.css';
import CallList from './call-list';
import {completed,All,cancelled} from './completed-data';
import HeaderBreadcrumb from '../../../components/UI/HeaderBreadcrumb';
import { connect } from 'react-redux';
import * as actionsMethods from '../../../store/actions/index';
const breadcrumb=[];
const TAB_NAMES=[
    {id:1,name:'Scheduled',icon:'flaticon2-calendar-9'},
    {id:2,name:'Completed',icon:'flaticon2-calendar-9'},
    {id:3,name:'Cancelled',icon:'flaticon2-calendar-9'},
    {id:4,name:'All',icon:'flaticon2-calendar-9'},
];
const options=[
    {value:'Sort By'},
    {value:'Date'},
    {value:'Month'},
];
const filter=[
    {value:4,displayValue:'All calls'},
    {value:5,displayValue:'Missed calls'},
    {value:6,displayValue:'Rescheduled calls'},
    {value:7,displayValue:'Follow-up calls'},
    {value:8,displayValue:'Upcomming calls'},

];
class CallSchedule extends React.Component{
    constructor(props){
        super(props);
        this.state={
            tab:1,
            data:All,
            currentOpen:0,
            currentItem:0
        }
    }
    componentDidMount(){
        this.props.getPatientDetail(this.props.match.params.id);
        this.props.patientCallScheduleRequest(this.props.match.params.id);
    }

handleCollapse=(e,item,index,position)=>{
   // console.log(item);
   console.log(this.state.data);
    let MonthObj=[...this.state.data];
    item.show=!item.show;
   let currentOpen= {...MonthObj[this.state.currentOpen].items[this.state.currentItem]};
   currentOpen.show=false;
   MonthObj[this.state.currentOpen].items[this.state.currentItem]=currentOpen;
    MonthObj[index].items[position]=item;
    this.setState({currentOpen:index,currentItem:position});
 this.setState({data:MonthObj});
} 

 handleTabClick=(e,value)=>{
     this.setState({tab:value});
     switch(value){
         case (2):
             this.setState({data:completed});
             break;
        case (3):
            this.setState({data:cancelled}); 
            break;    
        default:
            this.setState({data:All});     
     }
     this.setState({currentOpen:0,currentItem:0});
    }   
    render(){
  breadcrumb[0]={
    title: "Profile",
    page: `/patient-profile/${this.props.match.params.id}`
  };
  breadcrumb[1]={
    title: "Call Schedule",
    page: `/call-schedule/${this.props.match.params.id}`
  };
    let tabs=TAB_NAMES.map((item,index)=>(
                <li className={`nav-item `}  key={index}>
					<span className={`nav-link ${this.state.tab==item.id?'active':''}`} data-toggle="tab" href="#kt_tab_pane_5_2" onClick={(e)=>this.handleTabClick(e,item.id)}>
    					<span className="nav-icon"><i className={item.icon}></i></span>
    					<span className="nav-text">{item.name}</span>
					</span>
				</li>
    ));

    return (
        <>
         <HeaderBreadcrumb breadcrumb={breadcrumb} title="Call Schedule" />
       <div className="row">
           <div className="col-md-12">
           <div className="card card-custom">
	<div className="card-header">
		<div className="card-toolbar">
            <h3>{this.props.patientProfileDetail.patientInfo.name} - Call Schedule</h3>
		</div>
		<div className="card-toolbar">  
        <input type="date" className="form-control" placeholder="Search" />       
		</div>
	</div>
    <div className="card-header">
		<div className="card-toolbar">
			<ul className="nav nav-light-danger nav-bold nav-pills">

				{tabs}
			</ul>
		</div>
		<div className="card-toolbar">
        <select
                    className={`form-control filter`}
                    >
                    {options.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.value}
                        </option>
                    ))}
                </select>
                <select
                    className={`form-control filter`}
                    >
                    {filter.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.displayValue}
                        </option>
                    ))}
                </select>
                
		</div>
	</div>
	
	<div className="card-body1">
		<div className="tab-content">
			<div className="tab-pane fade show active" id="kt_tab_pane_5_1" role="tabpanel" aria-labelledby="kt_tab_pane_5_1">
<CallList items={this.props.patientCallSchedule.call_list} clicked={this.handleCollapse} patient_id={this.props.match.params.id} />
			</div>
		</div>
	</div>
</div>
           </div>
       </div>
       </>
    );
    }
}



const mapStateToProps= state=>{
     return {
        patientCallSchedule:state.patientCallSchedule,
        patientProfileDetail:state.patientProfile

     }
   }
   const mapDispatchToProps = dispatch => {
     return {
        patientCallScheduleRequest: id => dispatch(actionsMethods.patientCallScheduleRequest(id)),
        getPatientDetail: id => dispatch(actionsMethods.getPatientProfileDetailRequest(id))

 
   }
   }
   export default connect(mapStateToProps, mapDispatchToProps)(CallSchedule);
