import * as actionTypes from './actionTypes';


export const careTakerRelationRequest=()=>{
    return {
      type: actionTypes.CARE_TAKER_RELATION_REQUEST,
    }
  };

  export const careTakerRelationSuccess=data=>{
    return {
      type: actionTypes.CARE_TAKER_RELATION_SUCCESS,
      data:data
    }
  };

  export const careTakerRelationFailed=data=>{
    return {
      type: actionTypes.CARE_TAKER_RELATION_FAILED,
      data:data
    }
  };