import * as actionTypes from './actionTypes';

export const getProgramCode =  ()  => {
  return {
    type: actionTypes.GET_PROGRAM_CODES,
  };
};
export const getProgramCodeSuccess =  (data)  => {
  return {
    type: actionTypes.GET_PROGRAM_CODES_SUCCESS,
    data:data
  };
};

export const getProgramCodeDetail =  (id)  => {
  return {
    type: actionTypes.GET_PROGRAM_CODE_DETAIL,
    data:{id:id}
  };
};
export const getProgramCodeDetailSuccess =  (data)  => {
  return {
    type: actionTypes.GET_PROGRAM_CODE_DETAIL_SUCCESS,
    data:data
  };
};


export const createPatientSuccess =  ( data )  => {
  return {
    type: actionTypes.CREATE_PAITENT_SUCCESS,
    data: data
  };
};

export const initCreatePatient =  ( data )  => {
  return {
    type: actionTypes.INIT_CREATE_PATIENT,
    data: data
  };
};

export const createPatientFailed=  ( data )  => {
  return {
    type: actionTypes.CREATE_PAITENT_FAILED,
    data: data
  };
};

export const getPatients =  ( data )  => {
    return {
      type: actionTypes.GET_PAITENTS,
      data: data
    };
  };

  export const initGetPatients= (data) => {
    return {
      type: actionTypes.INIT_GET_PATIENTS,
	  data: data
    }
  };

  
export const patientSendOtpRequest =  (mobile)  => {
  return {
    type: actionTypes.PATIENT_SEND_OTP_REQUEST,
    data:{mobile:mobile}
  };
};

export const patientSendOtpSuccess= (data) => {
  return {
    type: actionTypes.PATIENT_SEND_OTP_SUCCESS,
    data: data
  }
};

export const patientSendOtpFailed=(data)=>{
  return {
    type:actionTypes.PATIENT_SEND_OTP_FAILED,
    data:data
  };
};
export const patientOtpVerifyRequest =  (mobile,otp)  => {
  return {
    type: actionTypes.PATIENT_OTP_VERIFY_REQUEST,
    data:{mobile:mobile,otp:otp}
  };
};


export const patientOtpVerifySuccess =  (data)  => {
  return {
    type: actionTypes.PATIENT_OTP_VERIFY_SUCCESS,
    data:data
  };
};

export const patientOtpVerifyFailed =  (data)  => {
  return {
    type: actionTypes.PATIENT_OTP_VERIFY_FAILED,
    data:data
  };
};


export const getUserModeRequest =(id)=>{
  return {
    type:actionTypes.GET_USER_MODE_REQUEST,
    id:id
  };
};

export const getUserModeSuccess= (data)=>{
  return {
    type:actionTypes.GET_USER_MODE_SUCCESS,
    data:data
  };
};

export const getUserModeFailed=(data)=>{
  return {
    type:actionTypes.GET_USER_MODE_FAILED,
    data:data
  }
}

/**
 * Profile complete step
 */

 
export const profileCompleteStepRequest =(data)=>{
  return {
    type:actionTypes.PATIENT_PROFILE_COMPLETE_STEP_REQUEST,
    data:data
  };
};

export const profileCompleteStepSuccess= (data)=>{
  return {
    type:actionTypes.PATIENT_PROFILE_COMPLETE_STEP_SUCCESS,
    data:data
  };
};

export const profileCompleteStepFailed=(data)=>{
  return {
    type:actionTypes.PATIENT_PROFILE_COMPLETE_STEP_FAILED,
    data:data
  }
}