import { put, call,delay  } from 'redux-saga/effects';
import axios from 'axios';
import * as actions from '../actions';
import * as Constants from '../../shared/constants';
import Qs from 'qs';


  export function* initEditPatientProfileSaga(action) {
    try {
      axios.defaults.withCredentials = true;
      const response = yield axios.get( process.env.REACT_APP_API_URL+'specialist/patient/'+action.cust_app_id+'/profile');
      yield put(actions.editPatientProfileSuccess(response.data));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
      }
      yield put(actions.editPatientProfileFailed(error));
    }
  }