import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import './broadcast.css';
import HeaderBreadcrumb from '../../../components/UI/HeaderBreadcrumb';
const breadcrumb=[
  {
    title: "Dashboard",
    page: "dashboard"
  },
  {
    title: "broadcast",
    page: "broadcast"
  }];

  const TAB_NAMES=[
    {id:1,name:'SMS',icon:'fa fa-envelope'},
    {id:2,name:'Email',icon:'fa fa-envelope'},
    {id:3,name:'Notification',icon:'fa fa-bell'},
];

export default function Broadcast() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [tab, setTab] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  let tabs=TAB_NAMES.map((item,index)=>(
    <li className={`nav-item `}  key={index}>
<span className={`nav-link ${tab==item.id?'active':''}`} data-toggle="tab" href="#kt_tab_pane_5_2" onClick={(e)=>setTab(item.id)}>
  <span className="nav-icon"><i className={item.icon}></i></span>
  <span className="nav-text1">{item.name}</span>
</span>
</li>
));
  return (
  <>
  <HeaderBreadcrumb breadcrumb={breadcrumb} title="Broadcast" />
    <div className="row ">
       <div className="col-md-12">
       <div className="card card-custom">

       <div className="card-header">
		<div className="card-toolbar">
			<ul className="nav nav-light-danger nav-bold nav-pills">

				{tabs}
			</ul>
		</div>
		<div className="card-toolbar">
        
                
		</div>
	</div> 

  <div className="card-body">
		<div className="tab-content">
			<div className="tab-pane fade show active" id="kt_tab_pane_5_1" role="tabpanel" aria-labelledby="kt_tab_pane_5_1">
      <textarea placeholder="Message" className="form-control textarea-height"></textarea>
<button className="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u  pull-right mt-4 mb-4"  type="submit" >Send</button>
			</div>
		</div>
	</div>
     
    </div>
    </div>
    </div>
    </>
  );
}