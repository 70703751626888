import axios from "axios";

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}login`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const SEND_OTP_URL = `${process.env.REACT_APP_API_URL}send-otp`;

export const LOGOUT_URL = `${process.env.REACT_APP_API_BASE_URL}logout`;
export const SANCTAM_COOKIEE_URL=`${process.env.REACT_APP_API_BASE_URL}sanctum/csrf-cookie`;
axios.defaults.withCredentials = true;

export const ME_URL = "api/me";

export function login(email, password) {
   return axios.post(LOGIN_URL, { email, password });
}

//export function login(mobile,otp) {
  // alert(otp);
  //return axios.post(LOGIN_URL, {mobile,otp});
//}


export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
export function sendOtp(mobile) {
 // alert(phone);
 axios.defaults.withCredentials = true;
  return axios.post(SEND_OTP_URL, {mobile });
}

export function sanctum_cookie() {
  return axios.get(SANCTAM_COOKIEE_URL);
}

export function logout() {
  return axios.post(LOGOUT_URL,{
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },{});
}

