import React from "react";
import { CircularProgress, Typography } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import HeaderBreadcrumb from '../../../../components/UI/HeaderBreadcrumb';
import { connect } from 'react-redux';
import * as actionsMethods from '../../../../store/actions/index';
import { removeKtdatatableCache} from '../../../../shared/utility';

import './question.css';
import $ from 'jquery';
import ReactDOM from "react-dom";

const breadcrumb=[
    {
      title: "Questions",
      page: "/admin/question-list"
    }, {
      title: "List",
      page: "/admin/question-list"
    }];
	
	const Actions=[
      {
          divider:0,
          icon:'flaticon2-user',
          name:'Create',
          url:'/admin/telecall/create-question',
          notification:'Question',
          ncolor:'label-light-primary'
      }
  ];
class QuestionListing extends React.Component {
  constructor(props){
      super(props);
      removeKtdatatableCache();
  this.state = {
  };
}
  columns=()=>{
    var _this = this;
    return [{
      field: 'id',
      title: 'ID'
  },{
      field: 'question',
      title: 'Question Name'
  },{
      field: 'type_name',
      title: 'type'
  }, {
      field: 'is_connected',
      title: 'Connections',
      template: function(row) {
        if (row.is_connected == 1){
            let html='<span class="label label-lg label-light-success label-inline">Yes</span>';
			return html;
        }else{
            let html = '<span class="label label-lg label-light-warning label-inline">No</span>';
			return html;
        }
    },
  },
   {
      field: 'action',
      title: 'Actions',
      sortable: false,
      width: 125,
      overflow: 'visible',
      autoHide: false,
      template: function(row) {
        var div = document.createElement('div');
            ReactDOM.render(_this.htmlf(row),div);
              return div.innerHTML;
      },
  }];
  }

htmlf=row=>{
   return ( <>
			<span className="btn btn-sm btn-clean btn-icon mr-2 ktBtnActionQue" data-id={`${row.id}`} data-action="1" title="Edit details">
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <path d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)"></path>
                <path d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                </g>
                </svg>
              </span>
			</span>

              <a href="#" class="btn btn-icon btn-light btn-hover-primary btn-sm">
			<span class="svg-icon svg-icon-md svg-icon-primary">
			<svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
			<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<rect x="0" y="0" width="24" height="24"></rect>
			<path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fill-rule="nonzero"></path>
			<path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"></path>
			</g>
			</svg>
			</span>
		</a>
   </>);
  }

  componentDidMount() {
    //this.props.getProgramCode();
    const column=this.columns();
    var _this=this;
    $(document).ready(function() {
      window.KTDatatableRemoteAjaxDemo.init(process.env.REACT_APP_API_URL+'getall-question',column,this.wrapperRef);
      $(document).on('click',".ktBtnActionQue",function(e){
       let Id=$(this).data('id');
       let actiontype=$(this).data('action');
       switch(actiontype){
           case 1:
           case "1":
            _this.props.history.push(`/admin/edit-telecall/edit-question/${Id}`);
            break;  

       }
	  });
	});
  }
  componentDidUpdate(prevProps,prevState){
	  
  }

  render() {

    return (
      <>
		<HeaderBreadcrumb breadcrumb={breadcrumb} actions={Actions} title="Questions" />
			<div className="card card-custom">
				<div className="card-header flex-wrap border-0 pt-6 pb-0">
					<div className="card-title">
						<h3 className="card-label">Question Listing
						<span className="d-block text-muted pt-2 font-size-sm">Sorting &amp; pagination Question datasource</span></h3>
					</div>
					<div className="card-toolbar">
					</div>
				</div>
				<div className="card-body">
					<div className="mb-7">
						<div className="row align-items-center">
							<div className="col-lg-9 col-xl-8">
								<div className="row align-items-center">
									<div className="col-md-4 my-2 my-md-0">
										<div className="input-icon">
											<input type="text" className="form-control" placeholder="Search..." id="kt_datatable_search_query" />
											<span>
												<i className="flaticon2-search-1 text-muted"></i>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				
					<div className="datatable datatable-bordered datatable-head-custom" id="kt_datatable"></div>
				</div>
			</div>					
      </>
    );

  }
}

const mapStateToProps= state=>{
    return {
        questionList:state.questionList
    }
   }
   const mapDispatchToProps = dispatch => {
     return {
        getAllQuestionRequest: data => dispatch(actionsMethods.getAllQuestionRequest(data))
 
   }
   }
   export default connect(mapStateToProps, mapDispatchToProps)(QuestionListing);
