import { put, call,delay  } from 'redux-saga/effects';
import axios from 'axios';
import * as actions from '../actions';
import * as Constants from '../../shared/constants';
import Qs from 'qs';


export function* initGetPatientProfileDetailSaga(action) {
    try {
      axios.defaults.withCredentials = true;
      const response = yield axios.get( process.env.REACT_APP_API_URL+'patient-profile/'+action.id);
      yield put(actions.getPatientProfileDetailSuccess(response.data));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
      }
      yield put(actions.getPatientProfileDetailFailed(error));
    }
  }
  
  export function* initGetPatientModulesSaga(action) {
    try {
      axios.defaults.withCredentials = true;
      const response = yield axios.get( process.env.REACT_APP_API_URL+'patient-modules/'+action.data.id);
      yield put(actions.getPatientModulesSuccess(response.data));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
      }
      yield put(actions.getPatientModulesFailed(error));
    }
  }
  
  
  export function* initGetAllKeyParametersSaga(action) {
    try {
      axios.defaults.withCredentials = true;
    const response = yield axios.get( 
      `${process.env.REACT_APP_API_URL}patient-profile/${action.data.id}/${action.data.module_id}`,
      {params:{...action.data},paramsSerializer: params => {
      return Qs.stringify(params)
    },headers:
	{
		'Content-type': 'application/json'
	}});
      yield put(actions.getKeyParametersSuccess(response.data));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
      }else{
        yield put(actions.getKeyParametersFailed(error));
      }
    }
  }