import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
const initialState = {
  message:'Welcome to HCAH',
  type:'error',
  open:false
};


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SNACKBAR_NOTIFICATION_SHOW:
            return  updateObject( state, {
               ...action.data
            });
        case actionTypes.SNACKBAR_NOTIFICATION_HIDE:
            return  updateObject( state, {
                ...action.data
             });
        default: 
            return state;
    }
};

export default reducer;