export const GET_PROGRAM_CODES='GET_PROGRAM_CODES';
export const GET_PROGRAM_CODES_SUCCESS='GET_PROGRAM_CODES_SUCCESS';

export const GET_PROGRAM_CODE_DETAIL='GET_PROGRAM_CODE_DETAIL';
export const GET_PROGRAM_CODE_DETAIL_SUCCESS='GET_PROGRAM_CODE_DETAIL_SUCCESS';

export const INIT_CREATE_PATIENT='INIT_CREATE_PATIENT';
export const CREATE_PAITENT_SUCCESS='CREATE_PATIENT_SUCCESS';
export const CREATE_PAITENT_FAILED='CREATE_PATIENT_FAILED';

export const GET_PAITENTS='GET_PAITENTS';
export const INIT_GET_PATIENTS='INIT_GET_PATIENTS';

export const LOGOUT= "[Logout] Action";

export const DOCTORS_LIST_REQUEST='DOCTORS_LIST_REQUEST';
export const DOCTORS_LIST_SUCCESS='DOCTORS_LIST_SUCCESS';

export const PATIENT_SEND_OTP_REQUEST='PATIENT_SEND_OTP_REQUEST';
export const PATIENT_SEND_OTP_SUCCESS='PATIENT_SEND_OTP_SUCCESS';
export const PATIENT_SEND_OTP_FAILED='PATIENT_SEND_OTP_FAILED';

export const PATIENT_OTP_VERIFY_REQUEST='PATIENT_OTP_VERIFY_REQUEST';
export const PATIENT_OTP_VERIFY_SUCCESS='PATIENT_OTP_VERIFY_SUCCESS';
export const PATIENT_OTP_VERIFY_FAILED='PATIENT_OTP_VERIFY_FAILED';

export const SNACKBAR_NOTIFICATION_SHOW='SNACKBAR_NOTIFICATION_SHOW';
export const SNACKBAR_NOTIFICATION_HIDE='SNACKBAR_NOTIFICATION_HIDE';


export const GET_QUESTION_TYPE_REQUEST='GET_QUESTION_TYPE_REQUEST';
export const GET_QUESTION_TYPE_SUCCESS='GET_QUESTION_TYPE_SUCCESS';
export const GET_QUESTION_TYPE_FAILED='GET_QUESTION_TYPE_FAILED';

export const CREATE_QUESTION_REQUEST='CREATE_QUESTION_REQUEST';
export const CREATE_QUESTION_SUCCESS='CREATE_QUESTION_SUCCESS';
export const CREATE_QUESTION_FAILED='CREATE_QUESTION_FAILED';

export const GET_ALL_QUESTION_REQUEST='GET_ALL_QUESTION_REQUEST';
export const GET_ALL_QUESTION_SUCCESS='GET_ALL_QUESTION_SUCCESS';
export const GET_ALL_QUESTION_FAILED='GET_ALL_QUESTION_FAILED';

export const GET_CONNECTED_QUESTION_REQUEST='GET_CONNECTED_QUESTION_REQUEST';
export const GET_CONNECTED_QUESTION_SUCCESS='GET_CONNECTED_QUESTION_SUCCESS';
export const GET_CONNECTED_QUESTION_FAILED='GET_CONNECTED_QUESTION_FAILED';

export const CLEAR_FORM='CLEAR_FORM';

export const EDIT_QUESTION_REQUEST='EDIT_QUESTION_REQUEST';
export const EDIT_QUESTION_SUCCESS='EDIT_QUESTION_SUCCESS';
export const EDIT_QUESTION_FAILED='EDIT_QUESTION_FAILED';

/*******Task Action const *******************/

export const GET_TASK_TYPE_REQUEST='GET_TASK_TYPE_REQUEST';
export const GET_TASK_TYPE_SUCCESS='GET_TASK_TYPE_SUCCESS';
export const GET_TASK_TYPE_FAILED='GET_TASK_TYPE_FAILED';

export const CREATE_TASK_REQUEST='CREATE_TASK_REQUEST';
export const CREATE_TASK_SUCCESS='CREATE_TASK_SUCCESS';
export const CREATE_TASK_FAILED='CREATE_TASK_FAILED';

export const EDIT_TASK_REQUEST='EDIT_TASK_REQUEST';
export const EDIT_TASK_SUCCESS='EDIT_TASK_SUCCESS';
export const EDIT_TASK_FAILED='EDIT_TASK_FAILED';

/**
 * Module Action const
 */

export const GET_QUESTION_TASK_REQUEST='GET_QUESTION_TASK_REQUEST';
export const GET_QUESTION_TASK_SUCCESS='GET_QUESTION_TASK_SUCCESS';
export const GET_QUESTION_TASK_FAILED='GET_QUESTION_TASK_FAILED';

export const CREATE_MODULE_REQUEST='CREATE_MODULE_REQUEST';
export const CREATE_MODULE_SUCCESS='CREATE_MODULE_SUCCESS';
export const CREATE_MODULE_FAILED='CRATE_MODULE_FAILTED';

export const GET_SEARCH_MODULE_REQUEST='GET_SEARCH_MODULE_REQUEST';
export const GET_SEARCH_MODULE_SUCCESS='GET_SEARCH_MODULE_SUCCESS';


/**
 * Create Call Schedule const
 */
export const CREATE_CALL_SCHEDULE_REQUEST='CREATE_CALL_SCHEDULE_REQUEST';
export const CREATE_CALL_SCHEDULE_SUCCESS='CREATE_CALL_SCHEDULE_SUCCESS';
export const CREATE_CALL_SCHEDULE_FAILED='CREATE_CALL_SCHEDULE_FAILED';

export const GET_ALL_TASKS_REQUEST='GET_ALL_TASKS_REQUEST';
export const GET_ALL_TASKS_SUCCESS='GET_ALL_TASKS_SUCCESS';
export const GET_ALL_TASKS_FAILED='GET_ALL_TASKS_FAILED';


export const GET_QUESTION_PROPERTIES_REQUEST='GET_QUESTION_PROPERTIES_REQUEST';
export const GET_QUESTION_PROPERTIES_SUCCESS='GET_QUESTION_PROPERTIES_SUCCESS';


export const GET_ALL_MODULES='GET_ALL_MODULES';

export const GET_ALL_MODULES_REQUEST='GET_ALL_MODULES_REQUEST';
export const GET_ALL_MODULES_SUCCESS='GET_ALL_MODULES_SUCCESS';
export const GET_ALL_MODULES_FAILED='GET_ALL_MODULES_FAILED';

export const GET_ALL_CALLS_REQUEST='GET_ALL_CALLS_REQUEST';
export const GET_ALL_CALLS_SUCCESS='GET_ALL_CALLS_SUCCESS';
export const GET_ALL_CALLS_FAILED='GET_ALL_CALLS_FAILED';

export const EDIT_CALL_SCHEDULE_REQUEST='EDIT_CALL_SCHEDULE_REQUEST';
export const EDIT_CALL_SCHEDULE_SUCCESS='EDIT_CALL_SCHEDULE_SUCCESS';
export const EDIT_CALL_SCHEDULE_FAILED='EDIT_CALL_SCHEDULE_FAILED';

export const EDIT_MODULE_REQUEST='EDIT_MODULE_REQUEST';
export const EDIT_MODULE_SUCCESS='EDIT_MODULE_SUCCESS';
export const EDIT_MODULE_FAILED='EDIT_MODULE_FAILED';
/**
 * Patient Profile Module Action Types
 */
export const GET_PATIENT_PROFILE_DETAIL_REQUEST='GET_PATIENT_PROFILE_DETAIL_REQUEST';
export const GET_PATIENT_PROFILE_DETAIL_SUCCESS='GET_PATIENT_PROFILE_DETAIL_SUCCESS';
export const GET_PATIENT_PROFILE_DETAIL_FAILED='GET_PATIENT_PROFILE_DETAIL_FAILED';

export const COUNSELLOR_DASHBOARD_DETAIL_REQUEST='COUNSELLOR_DASHBOARD_DETAIL_REQUEST';
export const COUNSELLOR_DASHBOARD_DETAIL_SUCCESS='COUNSELLOR_DASHBOARD_DETAIL_SUCCESS';
export const COUNSELLOR_DASHBOARD_DETAIL_FAILED='COUNSELLOR_DASHBOARD_DETAIL_FAILED';


export const GET_USER_MODE_REQUEST='GET_USER_MODE_REQUEST';
export const GET_USER_MODE_SUCCESS='GET_USER_MODE_SUCCESS';
export const GET_USER_MODE_FAILED='GET_USER_MODE_FAILED';


export const GET_KEY_PARAMETERS_REQUEST='GET_KEY_PARAMETERS_REQUEST';
export const GET_KEY_PARAMETERS_SUCCESS='GET_KEY_PARAMETERS_SUCCESS';
export const GET_KEY_PARAMETERS_FAILED='GET_KEY_PARAMETERS_FAILED';

export const ASSIGN_DIET_CHART_REQUEST='ASSIGN_DIET_CHART_REQUEST';
export const ASSIGN_DIET_CHART_SUCCESS='ASSIGN_DIET_CHART_SUCCESS';
export const ASSIGN_DIET_CHART_FAILED='ASSIGN_DIET_CHART_FAILED';

export const SEND_DOC_SMS_REQUEST='SEND_DOC_SMS_REQUEST';
export const SEND_DOC_SMS_SUCCESS='SEND_DOC_SMS_SUCCESS';
export const SEND_DOC_SMS_FAILED='SEND_DOC_SMS_FAILED';

export const CARE_TAKER_RELATION_REQUEST='CARE_TAKER_RELATION_REQUEST';
export const CARE_TAKER_RELATION_SUCCESS='CARE_TAKER_RELATION_SUCCESS';
export const CARE_TAKER_RELATION_FAILED='CARE_TAKER_RELATION_FAILED';

export const PATIENT_PROFILE_COMPLETE_STEP_REQUEST='PATIENT_PROFILE_COMPLETE_STEP_REQUEST';
export const PATIENT_PROFILE_COMPLETE_STEP_SUCCESS='PATIENT_PROFILE_COMPLETE_STEP_SUCCESS';
export const PATIENT_PROFILE_COMPLETE_STEP_FAILED='PATIENT_PROFILE_COMPLETE_STEP_FAILED';

export const GET_STATE_REQUEST='GET_STATE_REQUEST';
export const GET_STATE_SUCCESS='GET_STATE_SUCCESS';

export const GET_CITY_REQUEST='GET_CITY_REQUEST';
export const GET_CITY_SUCCESS='GET_CITY_SUCCESS';

export const CHECK_CALL_SCHEDULE_REQUEST='CHECK_CALL_SCHEDULE_REQUEST';
export const CHECK_CALL_SCHEDULE_SUCCESS='CHECK_CALL_SCHEDULE_SUCCESS';
export const CHECK_CALL_SCHEDULE_FAILED='CHECK_CALL_SCHEDULE_FAILED';

export const CHECK_PROGRAM_MODULES_REQUEST='CHECK_PROGRAM_MODULES_REQUEST';
export const CHECK_PROGRAM_MODULES_SUCCESS='CHECK_PROGRAM_MODULES_SUCCESS';
export const CHECK_PROGRAM_MODULES_FAILED='CHECK_PROGRAM_MODULES_FAILED';

export const EDIT_PATIENT_PROFILE_REQUEST='EDIT_PATIENT_PROFILE_REQUEST';
export const EDIT_PATIENT_PROFILE_SUCCESS='EDIT_PATIENT_PROFILE_SUCCESS';
export const EDIT_PATIENT_PROFILE_FAILED='EDIT_PATIENT_PROFILE_FAILED';

export const NAV_USER_MENU_REQUEST='NAV_USER_MENU_REQUEST';
export const NAV_USER_MENU_SUCCESS='NAV_USER_MENU_SUCCESS';

export const PATIENT_CALL_SCHEDULE_REQUEST='PATIENT_CALL_SCHEDULE_REQUEST';
export const PATIENT_CALL_SCHEDULE_SUCCESS='PATIENT_CALL_SCHEDULE_SUCCESS';
export const PATIENT_CALL_SCHEDULE_FAILED='PATIENT_CALL_SCHEDULE_FAILED';

export const PATIENT_CALL_TASK_QUESTION_REQUEST='PATIENT_CALL_TASK_QUESTION_REQUEST';
export const PATIENT_CALL_TASK_QUESTION_SUCCESS='PATIENT_CALL_TASK_QUESTION_SUCCESS';
export const PATIENT_CALL_TASK_QUESTION_FAILED='PATIENT_CALL_TASK_QUESTION_FAILED';

export const PATIENT_SAVE_CALL_TASK_QUESTION_REQUEST='PATIENT_SAVE_CALL_TASK_QUESTION_REQUEST';
export const PATIENT_SAVE_CALL_TASK_QUESTION_SUCCESS='PATIENT_SAVE_CALL_TASK_QUESTION_SUCCESS';
export const PATIENT_SAVE_CALL_TASK_QUESTION_FAILD='PATIENT_SAVE_CALL_TASK_QUESTION_FAILED';










