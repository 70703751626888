import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

export default class PieChart1 extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
      
        series: [50, 40, 60, 25],
        options: {
          chart: {
            type: 'donut',//'pie'
          },
          labels: ['Follow Up calls', 'Patient Onboarding', 'Tasks', 'Doctor Reports'],
          colors:['#267ed7', '#d13547', '#5bc980','#9C27B0'],
          background: 'transparent',
          dataLabels: {
            enabled: false
        },
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                show: false
                }
            }
          }],
          legend: {
              show:false,
           
        }
        },
      
      
      };
    }

  

    render() {
      return (
        

  <div id="chart">
<ReactApexChart options={this.state.options} series={this.state.series} /*type="pie" */ type="donut" style={{backgroundColor:'transparent'}} height="200px" />

</div>
      );
    }
}