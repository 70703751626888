import * as actionTypes from './actionTypes';


/**
 * Edit Patient Profile Actions
 */
export const editPatientProfileRequest=id=>{
  return {
    type:actionTypes.EDIT_PATIENT_PROFILE_REQUEST,
    cust_app_id:id
  }
}

export const editPatientProfileSuccess=data=>{
  return {
    type:actionTypes.EDIT_PATIENT_PROFILE_SUCCESS,
    data:data
  }
}

export const editPatientProfileFailed= data =>{
  return {
    type:actionTypes.EDIT_PATIENT_PROFILE_FAILED,
    data:data
  }
}