


import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    calls: [],
    days_between_call: '',
    id: '',
    program_id: ''
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.EDIT_CALL_SCHEDULE_REQUEST:
            return updateObject(state,{
                calls: [],
                days_between_call: '',
                id: '',
                program_id: ''
            });
        case actionTypes.EDIT_CALL_SCHEDULE_SUCCESS:
      return updateObject( state, {
        ...action.data
        });
        default: 
            return state;
    }
};

export default reducer;
