import React,{ Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";
import TeleCallCreate from './counsellors/TeleCallCreate';
// import Question from './counsellors/list-question/Question';
import QuestionListing from './counsellors/question/Listing';
import CreateCallSchedule from './counsellors/call-schedule/Create';
import TasksList from './counsellors/task/Listing';
import ModuleListing from './counsellors/module/Listing';
import EditTeleCall from './counsellors/EditTeleCall';
import CallsListing from './counsellors/call-schedule/Listing';
import EditCallSchedule from './counsellors/call-schedule/Edit';

const ProgramCodeListing= lazy(()=>
import('./program-code/Listing')
);
const ProgramCodeAction= lazy(()=>
import('./program-code/CreateAction')
);
export default function AdminPage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
    <Switch>
      <Redirect
        exact={true}
        from="/"
        to="/admin/telecall"
      />

      {/* Layout */}
      <Route 
        path="/admin/telecall" 
        component={TeleCallCreate} 
      />
      <Route 
        path="/admin/question-list" 
        component={QuestionListing} 
      />
       <Route 
        path="/admin/tasks-list" 
        component={TasksList} 
      />
	  <Route 
        path="/admin/modules-list" 
        component={ModuleListing} 
      />
        <Route 
        path="/admin/create-call-schedule" 
        component={CreateCallSchedule} 
      />
       <Route 
        path="/admin/edit-telecall" 
        component={EditTeleCall} 
      />
      
	  <Route 
        path="/admin/call-schedule-list" 
        component={CallsListing} 
      />
        <Route 
        path="/admin/edit-call-schedule/:id" 
        component={EditCallSchedule} 
      />

      <Route 
      path="/admin/program-codes"
      component={ProgramCodeListing}
      />
      <Route path="/admin/program-code-action" 
      component={ProgramCodeAction} />
       <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
