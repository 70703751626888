import React,{Component} from 'react';

import './profile.css';

import ApexChart from './AreaChart';
import { toAbsoluteUrl } from "../../../../_metronic";
import PatientNotes from './patientNotes';
import Notifications from './Notifications';
import PersonalInfo from './PersonalInfo';
import PersonalInfo1 from './PersonalInfo1';

import ProgramDetail from './ProgramDetail';
import ProgramModule from './ProgramModule';
import {Link} from 'react-router-dom';
import HeaderBreadcrumb from '../../../components/UI/HeaderBreadcrumb';
import { connect } from 'react-redux';
import * as actionsMethods from '../../../store/actions/index';
import CModal from '../../../components/UI/Modal';
import AssignDietChart from './AssignDietChart';
import SendDocumentSms from './SendDocumentSms';
import { FormattedMessage } from "react-intl";
import Card1 from './card1';
const breadcrumb=[
    {
        title: "Patient",
        page: "/patient"
      },
  {
    title: "List",
    page: "/patient"
  }];
const Notes=[
    {
      note:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      date:'12/04/2019'  
    },
    {
        note:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        date:'21/04/2019'  
      },
      {
        note:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
        date:'25/04/2019'  
      }
];

const activity=[
    {
      note:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
      date:'12/04/2019'  
    }
     
];

const trackingScheduling=[
    {
        title:<FormattedMessage id="PATIENT_PROFILE.ASSIGN_DIET_CHART" />,
        link:null,
        modal:1,
        icon:'/media/icons/custom-8.svg',
        height:80,
        position:`top`
    },
    {
        title:<FormattedMessage id="PATIENT_PROFILE.SEND_DOC_SMS" />,
        link:null,
        modal:2,
        icon:'/media/icons/custom-8-1.svg',
        height:80,
        position:`top`
    },
    // {
    //     title:<FormattedMessage id="PATIENT_PROFILE.PATIENT_TRACKING_DETAIL" />,
    //     link:'/patient-tracking-detail/',
    // },
      {
        title:<FormattedMessage id="PATIENT_PROFILE.CALL_SCHEDULE" />,
        link:null,
        modal:3,
        icon:'/media/icons/call_schedule.svg',
        height:80,
        position:`top`
    }
];

const notification=[
    {
        title:'Side affects reported-some detail',
        time:'2 hours ago',
        type:1,
        color:'#ffb822',
        icon:'far fa-clock'
    },
    {
        title:'Missed follow-up call',
        time:'10:00AM, yesterday',
        color:'#0abb87',
        type:2,
        icon:'far fa-edit'
    },
    {
        title:'Patient Request for call',
        time:'10:00AM, yesterday',
        color:'#fd397a',
        type:3,
        icon:'far fa-chart-bar'
    },
    {
        title:'Patient Information needs to be updated',
        time:'26/04/2020',
        color:'#fd397a',
        type:4,
        icon:'fas fa-bahai'
    },
    {
        title:'Patient Started tracking vitals',
        time:'26/04/2020',
        type:1,
        color:'green'
    }
];
class PatientProfile extends Component{
    constructor(props){
        super(props);
        this.myRef = React.createRef();
        this.state={
                tab:1,
                openModal:false,
                componentOpenType:1
        };
    }
    componentDidMount(){
        this.props.getPatientDetail(this.props.match.params.id);
    }
   
    getNotes=()=>{
        return Notes.map((item,index)=>(
            <div className="notes_wrapper" key={index}>
            <p className="text-dark-50">{item.note}</p>
        <b className="text-dark font-weight-bolder font-size-lg text-hover-primary mb-1">{item.date}</b>
            </div>
        ));
    }
   

    getActivities=()=>{
        return activity.map((item,index)=>(
            <div className="notes_wrapper" key={index}>
            <p className="text-dark-50">{item.note}</p>
        <b className="text-dark font-weight-bolder font-size-lg text-hover-primary mb-1">{item.date}</b>
            </div>
        ));
    }
    
    openModel=type=>{
        if(type==3){
            this.props.history.push(`/call-schedule/${this.props.match.params.id}`);
        }else{
        this.setState({openModal:true,componentOpenType:type});
        }
    }
    handleModalOpen = () => {
        this.setState({openModal:true});
      };
    
       handleModalClose = () => {
    this.setState({openModal:false});
    };
    getTimeSchedule =()=>{
        let ClonedOne=[...trackingScheduling];
        if(this.props.patientProfileDetail.patientInfo.diets.length<1){
            ClonedOne=ClonedOne.filter(it=>it.modal !=1);
        }
        if(this.props.patientProfileDetail.patientInfo.programDoc.length<1){
            ClonedOne=ClonedOne.filter(it=>it.modal !=2);
        }
        return ClonedOne.map((item,index)=>(
        <div className="card card-custom gutter-b mb-4" style={item.link !=null?{height:'100px'}:{}} key={index} >
		{item.link !=null?
        <div className="card-body d-flex align-items-center justify-content-between flex-wrap">
            <div className="mr-2">
                <h3 className="font-weight-bolder">{item.title}</h3>
            </div>
            <Link to={item.link} key={index} className="btn btn-primary font-weight-bold py-3 px-6"><i className="flaticon2-next"></i></Link>
        </div>
        :( <Card1 detail={item}  clickAction={e=>this.openModel(item.modal)}/>
    //     <div className="card-body d-flex align-items-center justify-content-between flex-wrap">
    //     <div className="mr-2">
    //         <h3 className="font-weight-bolder">{item.title}</h3>
    //     </div>
    //     <span onClick={e=>this.openModel(item.modal)} className="btn btn-primary font-weight-bold py-3 px-6"><i className="flaticon2-next"></i></span>
    // </div>
    )}
      
        
    </div>
));
    }

    
    render(){
        breadcrumb[2]={
            title: "Profile",
            page: `/patient-profile/${this.props.match.params.id}`
          };
       return(
           <>
            <HeaderBreadcrumb breadcrumb={breadcrumb} title="Patient Profile" />

        <div className="row">
           
            <div className="col-md-8">
                <div className="row">
                <div className="col-md-12">
                <div className="row">
                <div className="col-md-6">
                        {/* <PersonalInfo PatientInfo={this.props.patientProfileDetail.patientInfo} /> */}
                        <PersonalInfo1 PatientInfo={this.props.patientProfileDetail.patientInfo}  />
                        {/* <PatientNotes Notes={Notes} /> */}

                </div>
                    <div className="col-md-6">
                        {/* <ProgramDetail PatientInfo={this.props.patientProfileDetail.patientInfo} />  */}
						
<div className="col-md-12 p-0">
         
                                              
								
		{this.props.patientProfileDetail.patientInfo.programModules!=undefined &&this.props.patientProfileDetail.patientInfo.programModules != null?
		<ProgramModule position="top" height="80" title="Key Program Parameters" icon='/media/icons/patient-tracking.svg' PatientInfo={this.props.patientProfileDetail.patientInfo} /> 
		: null}
         {this.getTimeSchedule()}

         {/* <div className="row">
         <div className="col-md-12">  
         <div className="card card-custom card-stretch gutter-b h-100">
         <div className="card-header border-0">
                                             <h3 className="card-title font-weight-bolder text-dark">Recent History</h3>
                                             <div className="card-toolbar">
       <ul className="nav nav-tabs card-header-tabs" id="myTab" role="tablist">
         <li className={`nav-item ${this.state.tab==1?'active':''}`} onClick={()=>this.setState({tab:1})}>
           Call
         </li>
         <li className={`nav-item ${this.state.tab==2?'active':''}`} onClick={()=>this.setState({tab:2})}>
         Activity
         </li>
         <li className={`nav-item ${this.state.tab==3?'active':''}`} onClick={()=>this.setState({tab:3})}>
            Tracking
         </li>
       </ul>
     </div>
     </div>
     <div className="card-body pt-0">
       <div className= {`tab-pane fade   ${this.state.tab==1?'active show':''}`} >
       {this.getNotes()}
             <div className="readMore">
                 <span> More <i className="fa fa-arrow-right"></i> </span>
                 </div>
       </div>
       <div className={`tab-pane fade  ${this.state.tab==2?' active show':''}`} >
       {this.getActivities()}
             <div className="readMore">
                 <span> More <i className="fa fa-arrow-right"></i> </span>
                 </div>

          
       </div>
       <div className={`tab-pane fade   ${this.state.tab==3?' active show':''}`}>
       {this.getNotes()}
             <div className="readMore">
                 <span> More <i className="fa fa-arrow-right"></i> </span>
                 </div>

                
       </div>

     </div>
     </div>
             </div>
         
          </div> */}
         </div>
         </div>
                    </div>
                    </div>
                    </div>
                    </div>

            <div className="col-md-4">
            <div className="row">
            {/* <Notifications notification={notification}/> */}
            {/* <div className="col-md-12">  
            <div className="card card-custom card-stretch gutter-b ">
											<div className="card-header border-0">
												<h3 className="card-title font-weight-bolder text-dark">Activity Graph</h3>
												
											</div>
                                            <div className="card-body pt-0">
<ApexChart />
</div>
        
          </div>
             </div> */}
             </div>
            </div>
         </div>
         <CModal open={this.state.openModal} handleModalClose={e=>this.handleModalClose()} handleModalOpen={e=>this.handleModalOpen()}>
             <>
         {this.state.componentOpenType==1 &&(<AssignDietChart ref={this.myRef}  closeModal={e=>this.handleModalClose(e)} patientInfo={this.props.patientProfileDetail.patientInfo} />)}

              {this.state.componentOpenType==2 &&(<SendDocumentSms ref={this.myRef}  closeModal={e=>this.handleModalClose(e)} patientInfo={this.props.patientProfileDetail.patientInfo} />)} 
             </>
             </CModal>
         </>
       );
    }
}



const mapStateToProps= state=>{
    return {
    patientProfileDetail:state.patientProfile
    }
   }
   const mapDispatchToProps = dispatch => {
     return {
        getPatientDetail: id => dispatch(actionsMethods.getPatientProfileDetailRequest(id))
 
   }
   }
   export default connect(mapStateToProps, mapDispatchToProps)(PatientProfile);
