import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    id:'',
    name:'',
    mandatory:0,
    type_id:'',
    redirect: false
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.EDIT_TASK_REQUEST:
            return updateObject( state, {
                id:'',
                name:'',
                mandatory:0,
                type_id:'',
                redirect: false
            });
        case actionTypes.EDIT_TASK_SUCCESS:
      return updateObject( state, {
        ...action.data,
        redirect:false
        });
        case actionTypes.EDIT_TASK_FAILED:
            return updateObject( state, {
                id:'',
                name:'',
                mandatory:0,
                type_id:'',
                redirect: true
            });
        default: 
            return state;
    }
};

export default reducer;
