import React from 'react';
import List from './list';
const CallList=props=>{
   let list= props.items.map((it,i)=>(<div className="card card-custom gutter-b" key={i}>
<div className="card-header">
    <div className="card-title">
        <h3 className="card-label">{it.month}</h3>
    </div>
</div>
<div className="card-body" style={{paddingTop:0,paddingBottom:0}}>
        <div className="accordion accordion-light accordion-svg-toggle" id="accordionExample5">
<List calls={it.items}  clicked={props.clicked} listIndex={i} patient_id={props.patient_id} />
</div>
</div>
</div>)); 
     return (
         <>
{list}
</>
     );
};

export default CallList;