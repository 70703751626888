import * as actionTypes from './actionTypes';


export const patientCallScheduleRequest=(id)=>{
    return {
      type: actionTypes.PATIENT_CALL_SCHEDULE_REQUEST,
      id:id
    }
  };

  export const patientCallScheduleSuccess=data=>{
    return {
      type: actionTypes.PATIENT_CALL_SCHEDULE_SUCCESS,
      data:data
    }
  };

  export const patientCallScheduleFailed=data=>{
      return {
          type:actionTypes.PATIENT_CALL_SCHEDULE_FAILED,
          data:data
      }
  };

  

  export const patientCallTaskQuestionRequest=id=>{
    return {
      type:actionTypes.PATIENT_CALL_TASK_QUESTION_REQUEST,
      id:id
    }
  };
  
  export const patientCallTaskQuestionSuccess=data=>{
    return {
      type:actionTypes.PATIENT_CALL_TASK_QUESTION_SUCCESS,
      data:data
    }
  };

  export const patientCallTaskQuestionFailed=data=>{
    return {
      type:actionTypes.PATIENT_CALL_TASK_QUESTION_FAILED,
      data:data
    }
  };


  
  export const patientSaveCallTaskQuestionRequest=data=>{
    return {
      type:actionTypes.PATIENT_SAVE_CALL_TASK_QUESTION_REQUEST,
      data:data
    }
  };
  
  export const patientSaveCallTaskQuestionSuccess=data=>{
    return {
      type:actionTypes.PATIENT_SAVE_CALL_TASK_QUESTION_SUCCESS,
      data:data
    }
  };

  export const patientSaveCallTaskQuestionFailed=data=>{
    return {
      type:actionTypes.PATIENT_SAVE_CALL_TASK_QUESTION_FAILD,
      data:data
    }
  };

