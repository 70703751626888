import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  isSubmitting:false
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SEND_DOC_SMS_REQUEST:
            return updateObject( state, {
                isSubmitting:true
            });
        case actionTypes.SEND_DOC_SMS_FAILED:
        case actionTypes.SEND_DOC_SMS_SUCCESS:
             return updateObject( state, {
                isSubmitting:false
            });
        default: 
            return state;
    }
};

export default reducer;
