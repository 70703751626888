import React, { Component } from "react";
import {Link,BrowserRouter} from 'react-router-dom';
import ReactDOM from "react-dom";


import './Patients.css';

import HeaderBreadcrumb from '../../../components/UI/HeaderBreadcrumb';
import { connect } from 'react-redux';
import * as actionsMethods from '../../../store/actions/index';
import { removeKtdatatableCache} from '../../../shared/utility';
import $ from 'jquery';

const breadcrumb=[
  {
    title: "Patient",
    page: "patient"
  },{
      title:'List',
      page:'patient'
  }];
  
  const Actions=[
      {
          divider:0,
          icon:'flaticon2-user',
          name:'Create',
          url:'/patient-add',
          notification:'Patient',
          ncolor:'label-light-primary'
      }
  ];

 

class ListPatient extends React.Component {
  constructor(props){
      super(props);
      removeKtdatatableCache();
  this.state = {
  }
  this.wrapperRef = React.createRef();
}
  columns=()=>{
    var _this = this;
    return [{
      field: 'name',
      title: 'PATIENT NAME'
  },{
      field: 'phone',
      title: 'MOBLIE NUMBER'
  }, {
      field: 'code',
      title: 'PROGRAM CODE',
      template: function(row) {
        const status=(sta)=> {
            switch(sta){
                case "DEFAULT":
                    return {
                        'title': sta,
                        'class': ' label-light-success'
                    };
                case "ZYDUS":
                        return {
                            'title': sta,
                            'class': ' label-light-info'
                        };
                case "GUTFIT":
                    return {
                        'title': sta,
                           'class': 'label-light-primary'
                    };
                    case "ROCHE":
                        return {
                            'title': sta,
                               'class': 'label-light-info'
                        };     
                default: 
                return {
                    'title': sta,
                    'class': ' label-light-danger'
                };
            }
          
        };
        if (row.code.length>0){
            let html='';
            for(let i=0;i<row.code.length;i++){
                html +='<span className="label font-weight-bold label-lg ' + status(row.status).class + ' label-inline">' + status(row.code[i].code).title+ '</span>';
            }
        return html;
        }else{
            return '';
        }
    },
  },
  {
      field: 'status',
      title: 'Status',
      template: function(row) {
          const status=(sta)=> {
              switch(sta){
                  case "FREE":
                      return {
                          'title': sta,
                          'class': ' label-light-success'
                      };
                  default: 
                  return {
                      'title': sta,
                      'class': ' label-light-danger'
                  };
              }
            
          };
          return '<span className="label font-weight-bold label-lg ' + status(row.status).class + ' label-inline">' + status(row.status).title+ '</span>';
      },
  },
  {
      field: 'follow_up',
      title: 'FOLLOW-UP DUE',
  },
 
  {
      field: 'email',
      title: 'Email',
  },
  {
    field: 'hcp_name',
    title: 'HCP'
},
{
    field: 'cust_app_id',
    title: 'ID',
    
},
{
    field: 'user_mode',
    title: 'MODE',
    template: function(row) {
        const user_mode =(type)=>{
          switch(type){                        
              case 1: 
              case "1":
                  return {
                'title': 'Online',
                'class': ' label-light-success'
            };
            default:
            return  {
                'title': 'Offline',
                'class': ' label-light-danger'
            };
            }
        };
        return '<span className="label font-weight-bold label-lg ' + user_mode(row.user_mode).class + ' label-inline">' + user_mode(row.user_mode).title + '</span>';
    },
}, 
{
    field: 'last_active',
    title: 'LAST ACTIVE IN APP',
},
   {
      field: 'action',
      title: 'Actions',
      sortable: false,
      width: 125,
      overflow: 'visible',
      autoHide: false,
      template: function(row) {
        var div = document.createElement('div');
            ReactDOM.render(_this.htmlf(row),div);
              return div.innerHTML;
      },
  }];
  }
  htmlf=row=>{
   return ( <>
    {/* <div className="dropdown dropdown-inline">
        <span className="btn btn-sm btn-clean btn-icon mr-2" data-toggle="dropdown">
            <span className="svg-icon svg-icon-md">
                <svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <path d="M5,8.6862915 L5,5 L8.6862915,5 L11.5857864,2.10050506 L14.4852814,5 L19,5 L19,9.51471863 L21.4852814,12 L19,14.4852814 L19,19 L14.4852814,19 L11.5857864,21.8994949 L8.6862915,19 L5,19 L5,15.3137085 L1.6862915,12 L5,8.6862915 Z M12,15 C13.6568542,15 15,13.6568542 15,12 C15,10.3431458 13.6568542,9 12,9 C10.3431458,9 9,10.3431458 9,12 C9,13.6568542 10.3431458,15 12,15 Z" fill="#000000"/>
                    </g>
                </svg>
            </span>
        </span>
        <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
            <ul className="navi flex-column navi-hover py-2">
                <li className="navi-header font-weight-bolder text-uppercase font-size-xs text-primary pb-2">
                    Choose an action:
                </li>
                <li className="navi-item">
                    <a href="#" className="navi-link">
                        <span className="navi-icon"><i className="la la-print"></i></span>
                        <span className="navi-text">Print</span>
                    </a>
                </li>
                <li className="navi-item">
                    <a href="#" className="navi-link">
                        <span className="navi-icon"><i className="la la-copy"></i></span>
                        <span className="navi-text">Copy</span>
                    </a>
                </li>
                <li className="navi-item">
                    <a href="#" className="navi-link">
                        <span className="navi-icon"><i className="la la-file-excel-o"></i></span>
                        <span className="navi-text">Excel</span>
                    </a>
                </li>
                <li className="navi-item">
                    <a href="#" className="navi-link">
                        <span className="navi-icon"><i className="la la-file-text-o"></i></span>
                        <span className="navi-text">CSV</span>
                    </a>
                </li>
                <li className="navi-item">
                    <a href="#" className="navi-link">
                        <span className="navi-icon"><i className="la la-file-pdf-o"></i></span>
                        <span className="navi-text">PDF</span>
                    </a>
                </li>
            </ul>
        </div>
    </div> */}
    <span className="btn btn-sm btn-clean btn-icon mr-2 ktBtnAction" data-id={`${row.cust_app_id}`} data-action="1" title="Edit details">
<span className="svg-icon svg-icon-primary svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"/>
        <path d="M3,12 C3,12 5.45454545,6 12,6 C16.9090909,6 21,12 21,12 C21,12 16.9090909,18 12,18 C5.45454545,18 3,12 3,12 Z" fill="#000000" fillRule="nonzero" opacity="0.3"/>
        <path d="M12,15 C10.3431458,15 9,13.6568542 9,12 C9,10.3431458 10.3431458,9 12,9 C13.6568542,9 15,10.3431458 15,12 C15,13.6568542 13.6568542,15 12,15 Z" fill="#000000" opacity="0.3"/>
    </g>
</svg>
</span>
    </span>
    <a href={`tel:${row.phone}`} className="btn btn-sm btn-clean btn-icon mr-2 " data-id={`${row.cust_app_id}`} data-action="1" title="Edit details">
    <span className="svg-icon svg-icon-primary svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg"width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"/>
        <path d="M13.0799676,14.7839934 L15.2839934,12.5799676 C15.8927139,11.9712471 16.0436229,11.0413042 15.6586342,10.2713269 L15.5337539,10.0215663 C15.1487653,9.25158901 15.2996742,8.3216461 15.9083948,7.71292558 L18.6411989,4.98012149 C18.836461,4.78485934 19.1530435,4.78485934 19.3483056,4.98012149 C19.3863063,5.01812215 19.4179321,5.06200062 19.4419658,5.11006808 L20.5459415,7.31801948 C21.3904962,9.0071287 21.0594452,11.0471565 19.7240871,12.3825146 L13.7252616,18.3813401 C12.2717221,19.8348796 10.1217008,20.3424308 8.17157288,19.6923882 L5.75709327,18.8875616 C5.49512161,18.8002377 5.35354162,18.5170777 5.4408655,18.2551061 C5.46541191,18.1814669 5.50676633,18.114554 5.56165376,18.0596666 L8.21292558,15.4083948 C8.8216461,14.7996742 9.75158901,14.6487653 10.5215663,15.0337539 L10.7713269,15.1586342 C11.5413042,15.5436229 12.4712471,15.3927139 13.0799676,14.7839934 Z" fill="#000000"/>
        <path d="M14.1480759,6.00715131 L13.9566988,7.99797396 C12.4781389,7.8558405 11.0097207,8.36895892 9.93933983,9.43933983 C8.8724631,10.5062166 8.35911588,11.9685602 8.49664195,13.4426352 L6.50528978,13.6284215 C6.31304559,11.5678496 7.03283934,9.51741319 8.52512627,8.02512627 C10.0223249,6.52792766 12.0812426,5.80846733 14.1480759,6.00715131 Z M14.4980938,2.02230302 L14.313049,4.01372424 C11.6618299,3.76737046 9.03000738,4.69181803 7.1109127,6.6109127 C5.19447112,8.52735429 4.26985715,11.1545872 4.51274152,13.802405 L2.52110319,13.985098 C2.22450978,10.7517681 3.35562581,7.53777247 5.69669914,5.19669914 C8.04101739,2.85238089 11.2606138,1.72147333 14.4980938,2.02230302 Z" fill="#000000" fillRule="nonzero" opacity="0.3"/>
    </g>
</svg>
</span>
</a>
    {/* <span className="btn btn-sm btn-clean btn-icon mr-2 ktBtnAction" data-id={`${row.cust_app_id}`} data-action="1" title="Edit details">
        <span className="svg-icon svg-icon-md">
            <svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "/>
                    <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"/>
                </g>
            </svg>
        </span>
    </span> */}
    {/* <span   className="btn btn-sm btn-clean btn-icon ktBtnAction" data-id={`${row.cust_app_id}`} data-action="2" title="Delete">
        <span className="svg-icon svg-icon-md">
            <svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fillRule="nonzero"/>
                    <path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>
                </g>
            </svg>
        </span>
    </span> */}
   </>);
  }

	componentDidMount() {
        this.props.getProgramCode();
    const column=this.columns();
    var _this=this;
    $(document).ready(function() {
      window.KTDatatableRemoteAjaxDemo.init(process.env.REACT_APP_API_URL+'patient-list',column,this.wrapperRef);
      $(document).on('click',".ktBtnAction",function(e){
       let Id=$(this).data('id');
       let actiontype=$(this).data('action');
       switch(actiontype){
           case 1:
           case "1":
            _this.props.history.push(`/patient-profile/${Id}`);
            break;  

       }
       


      });
    });
  //  this.wrapperRef.current.onClick=()=>this.click_handler()
    //alert(this.state.rowsPerPage);
		// this.props.initGetPatients({start:this.state.start,length:this.state.rowsPerPage,columns:this.state.serverSortingCol,order:this.state.order});
  }
 
	componentDidUpdate(prevProps,prevState){
	
  }
  
  render() {

    return (
      <>
		<HeaderBreadcrumb breadcrumb={breadcrumb} actions={Actions} title="Patient List" />
								<div className="card card-custom">
									<div className="card-header flex-wrap border-0 pt-6 pb-0">
										<div className="card-title">
											<h3 className="card-label">Patient List
											<span className="d-block text-muted pt-2 font-size-sm">All patients connected to you are listed here</span></h3>
										</div>
										<div className="card-toolbar">
											{/* <div className="dropdown dropdown-inline mr-2">
												<button type="button" className="btn btn-light-primary font-weight-bolder dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												<span className="svg-icon svg-icon-md">
												
													<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
															<rect x="0" y="0" width="24" height="24" />
															<path d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z" fill="#000000" opacity="0.3" />
															<path d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z" fill="#000000" />
														</g>
													</svg>
												</span>Export</button>
												<div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
													<ul className="navi flex-column navi-hover py-2">
														<li className="navi-header font-weight-bolder text-uppercase font-size-sm text-primary pb-2">Choose an option:</li>
														<li className="navi-item">
															<a href="#" className="navi-link">
																<span className="navi-icon">
																	<i className="la la-print"></i>
																</span>
																<span className="navi-text">Print</span>
															</a>
														</li>
														<li className="navi-item">
															<a href="#" className="navi-link">
																<span className="navi-icon">
																	<i className="la la-copy"></i>
																</span>
																<span className="navi-text">Copy</span>
															</a>
														</li>
														<li className="navi-item">
															<a href="#" className="navi-link">
																<span className="navi-icon">
																	<i className="la la-file-excel-o"></i>
																</span>
																<span className="navi-text">Excel</span>
															</a>
														</li>
														<li className="navi-item">
															<a href="#" className="navi-link">
																<span className="navi-icon">
																	<i className="la la-file-text-o"></i>
																</span>
																<span className="navi-text">CSV</span>
															</a>
														</li>
														<li className="navi-item">
															<a href="#" className="navi-link">
																<span className="navi-icon">
																	<i className="la la-file-pdf-o"></i>
																</span>
																<span className="navi-text">PDF</span>
															</a>
														</li>
													</ul>
												</div>
											</div> */}
											{/* <a href="#" className="btn btn-primary font-weight-bolder">
											<span className="svg-icon svg-icon-md">
											
												<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
													<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
														<rect x="0" y="0" width="24" height="24" />
														<circle fill="#000000" cx="9" cy="15" r="6" />
														<path d="M8.8012943,7.00241953 C9.83837775,5.20768121 11.7781543,4 14,4 C17.3137085,4 20,6.6862915 20,10 C20,12.2218457 18.7923188,14.1616223 16.9975805,15.1987057 C16.9991904,15.1326658 17,15.0664274 17,15 C17,10.581722 13.418278,7 9,7 C8.93357256,7 8.86733422,7.00080962 8.8012943,7.00241953 Z" fill="#000000" opacity="0.3" />
													</g>
												</svg>
											
											</span>New Record</a> */}
										</div>
									</div>
									<div className="card-body">
										<div className="mb-7">
											<div className="row align-items-center">
												<div className="col-lg-9 col-xl-8">
													<div className="row align-items-center">
														<div className="col-md-4 my-2 my-md-0">
															<div className="input-icon">
																<input type="text" className="form-control" placeholder="Search..." id="kt_datatable_search_query" />
																<span>
																	<i className="flaticon2-search-1 text-muted"></i>
																</span>
															</div>
														</div>
														{/* <div className="col-md-4 my-2 my-md-0">
															<div className="d-flex align-items-center">
																<label className="mr-3 mb-0 d-none d-md-block">Status:</label>
																<select className="form-control" id="kt_datatable_search_status">
																	<option value="">All</option>
																	<option value="1">Pending</option>
																	<option value="2">Delivered</option>
																	<option value="3">Canceled</option>
																	<option value="4">Success</option>
																	<option value="5">Info</option>
																	<option value="6">Danger</option>
																</select>
															</div>
														</div> */}
                                                        <div className="col-md-4 my-2 my-md-0">
															<div className="d-flex align-items-center">
																<label className="mr-3 mb-0 d-none d-md-block">Program:</label>
																<select className="form-control" id="kt_datatable_program_code">
                                                                    {
                                                                        this.props.codes.options.map((it,i)=>(<option value={it.value}>{it.displayValue}</option>))
                                                                    }
																</select>
															</div>
														</div>
														<div className="col-md-4 my-2 my-md-0">
															<div className="d-flex align-items-center">
																<label className="mr-3 mb-0 d-none d-md-block">Type:</label>
																<select className="form-control" id="kt_datatable_search_type">
																	<option value="">All</option>
																	<option value="1">Online</option>
																	<option value="0">Offline</option>
																</select>
															</div>
														</div>
													</div>
												</div>
												{/* <div className="col-lg-3 col-xl-4 mt-5 mt-lg-0">
													<a href="#" className="btn btn-light-primary px-6 font-weight-bold">Search</a>
												</div> */}
											</div>
										</div>
									
										<div className="datatable datatable-bordered datatable-head-custom" id="kt_datatable"></div>
									</div>
								</div>
    
      </>
    );

  }

}



const mapStateToProps= state=>{
    return {
       patientList:state.patientListing,
       codes:state.programCodes.codes
    }
   }
   const mapDispatchToProps = dispatch => {
     return {
        initGetPatients: data => dispatch(actionsMethods.initGetPatients(data)),
        getProgramCode: ()=>dispatch(actionsMethods.getProgramCode()),
 
   }
   }
   export default connect(mapStateToProps, mapDispatchToProps)(ListPatient);