import * as actionTypes from './actionTypes';
/**
 * Get Task Type Actions
 */
export const getTaskTypeRequest =  ()  => {
  return {
    type: actionTypes.GET_TASK_TYPE_REQUEST,
  };
};
export const getTaskTypeSuccess =  (data)  => {
    return {
      type: actionTypes.GET_TASK_TYPE_SUCCESS,
      data:data
    };
  };

  export const getTaskTypeFailed =  (data)  => {
    return {
      type: actionTypes.GET_TASK_TYPE_FAILED,
      data:data
    };
  };

  /**
   * Create Task Actions 
   */
  export const createTaskRequest =  (data)  => {
    return {
      type: actionTypes.CREATE_TASK_REQUEST,
      data:data
    };
  };
  export const createTaskSuccess =  (data)  => {
      return {
        type: actionTypes.CREATE_TASK_SUCCESS,
        data:data
      };
    };
  
    export const createTaskFailed =  (data)  => {
      return {
        type: actionTypes.CREATE_TASK_FAILED,
        data:data
      };
    };


     /**
 * Get All Question api call
*/

export const getAllTasksRequest =  (data)  => {
  return {
    type: actionTypes.GET_ALL_TASKS_REQUEST,
    data:data
  };
};

export const getAllTasksSuccess =  (data)  => {
  return {
    type: actionTypes.GET_ALL_TASKS_SUCCESS,
    data:data
  };
};

export const getAllTasksFailed =  (data)  => {
  return {
    type: actionTypes.GET_ALL_TASKS_FAILED,
    data:data
  };
};

/**
   * Edit Task Actions 
   */
  export const editTaskRequest =  (data)  => {
    return {
      type: actionTypes.EDIT_TASK_REQUEST,
      data:data
    };
  };
  export const editTaskSuccess =  (data)  => {
      return {
        type: actionTypes.EDIT_TASK_SUCCESS,
        data:data
      };
    };
  
    export const editTaskFailed =  (data)  => {
      return {
        type: actionTypes.EDIT_TASK_FAILED,
        data:data
      };
    };


