export const module={
    name:{
        elementType: 'input',
        label:'Module Name',
        col:6,
        fullRow:6,
        error:'',
        divider:0,
        heading:'',
        elementConfig: {
            type: 'text',
            placeholder: 'Enter name of the module',
        },
        value: '',
        validation: {
            required: true,
        },
        errorMessage:{
          required: 'Module field is required',
        },
        valid: false,
        touched: false
    },
    // programCode: {
    //     elementType: 'select',
    //     label:'Program(If program specific)',
    //     heading:'',
    //     error:'',
    //     elementConfig: {
    //         options: [
    //             {value: '', displayValue: 'All Program'}
    //         ]
    //     },
    //     value: '',
    //     col:6,
    //     fullRow:6,
    //     validation: {
    //      // required:true
    //     },
    //     errorMessage:{
    //     // required:'Question Type is required'
    //     },
    //     valid: true,
    //     touched:false
    //   }
};




export function section(questionTaskArray,item=''){
  let actualValue=[];
  let selectedQuestion=[];
  if(item !=''){
    let QuestinArray=Object.values(item.question);
    selectedQuestion=questionTaskArray.filter(it=>QuestinArray.indexOf(it.id)!=-1 && it.type=="Q");
    let TaskArray=Object.values(item.task);
    let selectedTask=questionTaskArray.filter(it=>TaskArray.indexOf(it.id)!=-1 && it.type=="T");
    selectedQuestion= selectedQuestion.concat(selectedTask);
    for (let i in item.question){
      actualValue.push({id:item.question[i],value:'Q'});
    }
    for (let i in item.task){
      actualValue.push({id:item.task[i],value:'T'});
    }
  }

return ( 
       { task_name:{
          elementType: 'input',
          label:'Section',
          col:8,
          fullRow:4,
          error:'',
          divider:0,
          heading:'',
          elementConfig: {
              type: 'text',
              placeholder: 'Point of Awareness to be disccussed',
          },
          value: item==''?'':item.name,
          validation: {
              required: true,
          },
          errorMessage:{
            required: 'Section Name Field is required',
          },
          valid: true,
          touched: false
      },
    question_task:{
          elementType: 'autocomplete',
          label:'Question/Task',
          col:8,
          fullRow:4,
          error:'',
          divider:0,
          heading:'',
          elementConfig: {
            options:questionTaskArray
        },
        value: actualValue,
        valueObj:selectedQuestion,
          validation: {
              required: true,
          },
          errorMessage:{
            required: 'Question task Field is required',
          },
          valid: true,
          touched: false
      },
    }  
  );
};