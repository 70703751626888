import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import './Input.css';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Chip from '@material-ui/core/Chip';


const input = ( props ) => {
    let inputElement = null;
    const inputClasses = ['InputElement'];
if(props.sendOtp==true){
    inputClasses.push('input-inline-block');
}
    if (props.invalid && props.shouldValidate && props.touched) {
        inputClasses.push('is-invalid');
    }else if(!props.invalid && (props.error=='' || props.error==undefined)  && props.touched){
        inputClasses.push('is-valid');
    }

    switch ( props.elementType ) {
        case ( 'input' ):
            inputElement = (<><input
                className={`form-control form-control-solid form-control-md ${inputClasses.join(' ')}`}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed}
                 />
                 {props.sendOtp==true && (
                         <Button variant="outlined" color="secondary"
                         className='ml-1 button-padding'   onClick={props.sendOtpClick}> Send Otp   {props.sendOtpLoading &&(<span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>)}
                         </Button>
                 )}
                   {/* {props.skipOtp==true && (
                    <Button variant="outlined" className='ml-1 button-padding' color="primary" onClick={props.skipOtpClick}> Skip Otp</Button>
                 )} */}
                 </>);
            break;
        case ( 'textarea' ):
            inputElement = <textarea
                className={`form-control form-control-solid form-control-md ${inputClasses.join(' ')}`}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed} 
                />;
            break;
        case ( 'select' ):
            if(props.isradio==1){
                inputElement = (  <RadioGroup row aria-label="position" name="position"
                 defaultValue=''
                 >
                {props.elementConfig.options.map((option,index) => (
            <FormControlLabel
            value="end"
            key={index}
            control={<Radio value={option.value}  onChange={props.changed} color="primary" checked={option.value==props.value?true:false} />}
            label={option.displayValue}
            labelPlacement="end"
          />
                ))}
                </RadioGroup>
                );
            }else{
            inputElement = (
                <select
                    className={`form-control form-control-solid form-control-md ${inputClasses.join(' ')}`}
                    value={props.value}
                    onChange={props.changed}
                    >
                    {props.elementConfig.options.map((option,index) => (
                        <option key={index} value={option.value}>
                            {option.displayValue}
                        </option>
                    ))}
                </select>
            );
                    }
            break;
        case ('switch'):
            inputElement = (
                <>
                <br />
                <span className="leftText">{props.leftText}</span>
                <span className="kt-switch kt-switch--icon">           
                <label>
              
                    <input  
                className={`form-control form-control-solid form-control-md ${inputClasses.join(' ')}`}
                {...props.elementConfig}
                value={Boolean(props.value)}
                onChange={props.changed}
                checked={props.value==1?'checked':''}
                />
                    <span></span>
                </label>
            </span>  
              </>
            );
             break;

        case ('autocomplete') :  
        inputElement = (
            <>
            <Autocomplete
      id="combo-box-demo"
      options={props.elementConfig.options}
      value={props.valueObj==undefined?'':props.valueObj}
      getOptionLabel={(option) => option.displayValue}
      
      onChange={props.changed}
      renderInput={(params) => <TextField {...params}      placeholder={props.label} variant="outlined"   margin="dense"
       />}
    />
          </>
        );
         break;
         case ('multiple_autocomplete') :  
         inputElement = (
             <>
             <Autocomplete
       id="combo-box-demo"
       multiple
       value={props.valueObj}
       options={props.elementConfig.options}
       getOptionLabel={(option) => option.displayValue}
       renderTags={(tagValue, getTagProps) =>
         tagValue.map((option, index) => (
           <Chip
             label={option.displayValue}
             {...getTagProps({ index })}
           />
         ))
       }
       onChange={props.changed}
       renderInput={(params) => <TextField {...params}      placeholder={props.label} variant="outlined"   margin="dense"
        />}
     />
           </>
         );
          break; 
     case ('mcheckbox'):
         
        inputElement =  <FormControlLabel
        value="end"
        control={<Checkbox value={props.value}  onChange={props.changed} color="primary" checked={props.value} />}
        label={props.label}
        labelPlacement="end"
      />;
         break;
      case ('multi_checkbox'):
        inputElement = (
            <>
            {props.elementConfig.options.map((option,index) => (
            <FormControlLabel
            className="d-block"
            value="end"
            key={index}
            control={<Checkbox value={option.value}  onChange={props.changed} color="primary" />}
            label={option.displayValue}
            labelPlacement="end"
          />
                ))}
               </> );
             break; 
        case ('image'):
            inputElement = <input
                    className={inputClasses.join(' ')}
                    {...props.elementConfig}
                   // value={props.value}
                    onChange={props.changed}
                    className="form-control"
                    />;        
                break;
        default:
            inputElement = <input
                className={inputClasses.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed}
                className="form-control"
                />;
    }
   
    return (
        <>
          {props.divider==1?(
         <div className="col-12">
             <div className="border-line"></div>
         </div>
        ):''}
        {props.heading!=''?(
              <div className="col-12">
         <div className="kt-heading kt-heading--md">{props.heading}</div>
       
        </div>):''}
        {props.elementType=='mcheckbox'?(
             <div className={`form-group col-md-${props.col}`}>
            
             {inputElement} 
             {props.error!=''&& props.error!=undefined && (<p className="error">{props.error}</p>)}
         </div>
        ):(
        <div className={`form-group col-md-${props.col}`}>
            {props.label!='' && <label className={`${props.sendOtp==true?'d-block':'d-block'}`}>{props.label}{props.shouldValidate.required==true &&(<span className="requiredField">*</span>)}</label>}
            {inputElement}
            {props.error!=''&& props.error!=undefined && (<p className="error">{props.error} </p>)}
            {props.successMsg!=''&& props.successMsg!=undefined && (<p className="success">{props.successMsg} <i className={`fa fa-check ${props.sendOtp==true?'align-custom-class-otp':'align-custom-class'}`}></i></p>)}
        </div>)}
        {props.fullRow>0?(
            <div className={`form-group col-md-${props.fullRow}`}>
                {props.isCrossBtn==1 && 
                (
                   <i onClick={props.deleteMethod} className={`fa fa-window-close close-btn-f`}></i>
                )
                }
            </div>
        ):''}
    </>
    );

};

export default input;
