import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
const initialState = {
    doctors:{
        options:[{
        value:'',
        displayValue:'Select Doctors'
    }]
   
    
},
};



const doctorListSuccess = (state, action) => {
    let doctor=[{
        value:'',
        displayValue:'Select Doctors'
    }];
    return updateObject( state, {
   
        doctors:{options:[...doctor,...action.data]}
    });
};


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.DOCTORS_LIST_SUCCESS:
            return doctorListSuccess(state,action);
        default: 
            return state;
    }
};

export default reducer;