import React,{useState,useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { is } from 'date-fns/locale';
import * as actionsMethods from '../../../store/actions/index';
import clsx from "clsx";
import { FormattedMessage } from "react-intl";

const SendDocumentSms= React.forwardRef((props, ref)=>{
	const [sendDocValue,changeDocValue]=useState({
		value:'',
		error:'',
		valid:false,
		touch:false,
		currentViewFile:''
});
let sendDocSmsReducer=useSelector(state=>state.sendDocSmsReducer);

const [loading, setLoading] = useState(false);
const [loadingButtonStyle, setLoadingButtonStyle] = useState({
  paddingRight: "1rem"
});
useEffect(() => {
	if(sendDocSmsReducer.isSubmitting==false){
		disableLoading()
	}
  },[sendDocSmsReducer]);
    const changeDocHandler=async (e)=>{
		const value=e.target.value;
		const valid=e.target.value==''?false:true;
		const error=e.target.value==''?"Please select any document to send":'';
		let currentViewFile='';
		const findDoc=props.patientInfo.programDoc.filter(item=>item.doc_id==value);
		if(findDoc.length>0){
			currentViewFile=findDoc[0].link;
		}
		await changeDocValue(prevState => ({
			...prevState,
			error:error,
			valid:valid,
		   value:value,
		   touch:true,
		   currentViewFile:currentViewFile
        }));
	}
	const dispatch = useDispatch()
	const handleSubmit=event=>{
		event.preventDefault();
		dispatch(actionsMethods.sendDocSmsRequest({id:sendDocValue.value,cust_app_id:props.patientInfo.cust_app_id}));
		enableLoading();
	}
	const getValidClass=()=>{
		if(sendDocValue.touch && sendDocValue.valid){
			return 'is-valid';
		}else if(sendDocValue.touch && !sendDocValue.valid){
			return 'is-invalid';
		}
		return '';
	}

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "1rem" });
  };

    return (<>
	<div className="modal-dialog" role="document">
		<div className="modal-content">
			<div className="modal-header">
				<h5 className="modal-title" id="exampleModalLabel">
				Send Document SMS
				</h5>
				<button type="button" className="close" onClick={props.closeModal}>
				<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<form className="form" id="kt_form_1" onSubmit={e=>handleSubmit(e)}>
			<div className="modal-body">
			<div className="form-group mb-6">
																		<select name="diet_id" className={`form-control border-0 form-control-solid text-muted font-size-lg font-weight-bolder pl-2 min-h-50px ${getValidClass()}`} id="exampleSelects" defaultValue={sendDocValue.value} onChange={e=>changeDocHandler(e)}>
																			<option value="">Please select</option>
																		{props.patientInfo.programDoc.map((it,i)=>(<option key={i} value={it.doc_id} >{it.name}</option>))}
																		</select>
																		{sendDocValue.error !='' && (<div className="fv-plugins-message-container"><div data-field="address1" data-validator="notEmpty" className="fv-help-block">{sendDocValue.error}</div></div>)}
																	</div>
																	
																	<FormattedMessage id="PATIENT_PROFILE.SEND_DOC_SMS.NOTE" />

																	
																	<div className="row justify-content-center py-8 px-8 py-md-10 px-md-0">
											<div className="col-md-12">
												<div className="d-flex justify-content-between">		
				
												</div>
											</div>
										</div>
																
																
			</div>
			<div className="modal-footer">
												
			{sendDocValue.currentViewFile !='' &&(<a href={`${sendDocValue.currentViewFile}`} target="_blank" className="btn btn-light-primary font-weight-bold" >
				  <FormattedMessage id="PATIENT_PROFILE.BTN.VIEW_FILE" />
				  </a>)}
			<button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={sendDocSmsReducer.isSubmitting || !sendDocValue.valid}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      }
                    )}`}
                    style={loadingButtonStyle}
                  >
				  <FormattedMessage id="PATIENT_PROFILE.BTN.SEND_SMS" />
                  </button>
			</div>
			</form>
		</div>
	</div>
	
                                                </>);
});

export default SendDocumentSms;