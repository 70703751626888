import { put} from 'redux-saga/effects';
import axios from 'axios';
import * as actions from '../actions';
import * as Constants from '../../shared/constants';
import Qs from 'qs'

  export function* initCreateCallScheduleSaga(action) {
    try {
      axios.defaults.withCredentials = true;
      const response = yield axios.post( process.env.REACT_APP_API_URL+'create-call',action.data);
      yield put(actions.showSnackBarNotification({
        message:action.data.id==undefined?'Call Schedule Created successfully': 'Call schedule updated successfully',
        type:Constants.notificationType.SUCCESS,
        open:true
       }));
      yield put(actions.createCallScheduleSuccess(response.data));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
      }
      yield put(actions.showSnackBarNotification({
        message:action.data.id==undefined?'There is error in Creating Call Schedule':'There is error in updaing Call Schedule',
        type:Constants.notificationType.ERROR,
        open:true
  }));
      yield put(actions.createCallScheduleFailed(error));
    }
   
  }
  
  export function* initGetAllCallsSaga(action) {
    try {
      axios.defaults.withCredentials = true;
    const response = yield axios.get( 
      `${process.env.REACT_APP_API_URL}getall-calls`,
      {params:{...action.data},paramsSerializer: params => {
      return Qs.stringify(params)
    },headers:
	{
		'Content-type': 'application/json'
	}});
	console.log(response);
      yield put(actions.getAllCallsSuccess(response.data));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
      }else{
        yield put(actions.getAllCallsFailed(error));
      }
    }
  }


  export function* initEditCallScheduleSaga(action) {
    try {
      axios.defaults.withCredentials = true;
      const response = yield axios.get(`${process.env.REACT_APP_API_URL}edit-call/${action.id}`);
      yield put(actions.editCallScheduleSuccess(response.data));
    } catch (error) {
  
      if(error.response.status==401){
        yield put(actions.logout());
      }
      yield put(actions.editCallScheduleFailed(error));
    }
  }

  export function* checkCallScheduleSaga(action) {
    try {
      axios.defaults.withCredentials = true;
      const response = yield axios.get(`${process.env.REACT_APP_API_URL}check-callschedule0/${action.program_id}`);
      yield put(actions.checkCallScheduleSuccess(response.data));
    } catch (error) {
  
      if(error.response.status==401){
        yield put(actions.logout());
      }
      yield put(actions.checkCallScheduleFailed(error));
    }
  }