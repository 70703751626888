import * as actionTypes from './actionTypes';
/**
 * Get Question Task Actions
 */
export const getQuestionTaskRequest =  ()  => {
  return {
    type: actionTypes.GET_QUESTION_TASK_REQUEST,
  };
};
export const getQuestionTaskSuccess =  (data)  => {
    return {
      type: actionTypes.GET_QUESTION_TASK_SUCCESS,
      data:data
    };
  };

  export const getQuestionTaskFailed =  (data)  => {
    return {
      type: actionTypes.GET_ALL_QUESTION_FAILED,
      data:data
    };
  };

  /**
   * Create Module Actions
   */
  export const createModuleRequest = (data)=>{
        return {
          type:actionTypes.CREATE_MODULE_REQUEST,
          data:data
        };
  }
  export const createModuleSuccess=(data) =>{
    return {
      type:actionTypes.CREATE_MODULE_SUCCESS,
      data:data
    };
  }
export const createModuleFailed = (data)=>{
  return {
    type:actionTypes.CREATE_MODULE_FAILED,
    data:data
  };
}

/**
 * GET_SEARCH_MODULE_REQUEST
 */

 export const getSearchModuleRequest = () =>{
   return {
     type:actionTypes.GET_SEARCH_MODULE_REQUEST
   }
 };

 export const getSearchModuleSuccess = data => {
   return {
     type:actionTypes.GET_SEARCH_MODULE_SUCCESS,
     data:data
   }
 }


export const getAllModulesRequest =  (data)  => {
  return {
    type: actionTypes.GET_ALL_MODULES_REQUEST,
    data:data
  };
};

export const getAllModulesSuccess =  (data)  => {
  return {
    type: actionTypes.GET_ALL_MODULES_SUCCESS,
    data:data
  };
};

export const getAllModulesFailed =  (data)  => {
  return {
    type: actionTypes.GET_ALL_MODULES_FAILED,
    data:data
  };
};


/**
   * Edit Module Actions
   */
  export const editModuleRequest = (id)=>{
    return {
      type:actionTypes.EDIT_MODULE_REQUEST,
      id:id
    };
}
export const editModuleSuccess=(data) =>{
return {
  type:actionTypes.EDIT_MODULE_SUCCESS,
  data:data
};
}
export const editModuleFailed = (data)=>{
return {
type:actionTypes.EDIT_MODULE_FAILED,
data:data
};
}
