import * as actionTypes from './actionTypes';

export const counsellorDashboardDetailRequest =  ()  => {
  return {
    type: actionTypes.COUNSELLOR_DASHBOARD_DETAIL_REQUEST,
  };
};
export const counsellorDashboardDetailSuccess =  (data)  => {
  return {
    type: actionTypes.COUNSELLOR_DASHBOARD_DETAIL_SUCCESS,
    data:data
  };
};

export const counsellorDashboardDetailFailed =  (data)  => {
    return {
      type: actionTypes.COUNSELLOR_DASHBOARD_DETAIL_FAILED,
      data:data
    };
  };