
function FieldCreator(title,Fields){
    console.log("field");
   let f={};
      Fields.forEach((item,index)=>{
            let heading=index==0?title:'';
            let type=item.type;
            let elementType='input';
            let isRadio=0;
            let col=4;
            let isImage=0;
           let elementConfig={
                type: type,
            };
            switch(item.type){
                case 'checkbox':
                    elementType='mcheckbox';
                    col=12;
                    break;
                case 'textarea':
                    elementType='textarea';
                    col=12;
                    break;
                case 'radio':
                    elementType='select';
                    elementConfig= {
                        options:[...item.options]
                    }
                    isRadio=1;
                    col=12;
                    break;
                case 'multiselect_checkbox':
                    elementType='multi_checkbox';
                    elementConfig= {
                        options:[...item.options]
                    }
                    col=12;
                    break;
                case 'image':
                    elementType='image';
                    elementConfig= {
                        type: 'file',
                        accept:"image/*"
                    }
                    col=12;
                    isImage=1;
                    break;
                    case 'pdf':
                        elementType='image';
                        elementConfig= {
                            type: 'file',
                            accept:"application/pdf"
                        }
                        col=12;
                        isImage=1;
                        break;
                default:
                    elementType="input"    
            }
          
            let validation=item.required==true?{required:true}:{};
            f= {
               ...f,
               [item.name]: {
                            elementType: elementType,
                            isradio:isRadio,
                            label:item.label,
                            col:col,
                            isImage:isImage,
                            fullRow:0,
                            heading:heading,
                            elementConfig:elementConfig,
                            value: '',
                            validation: validation,
                            valid: item.required,
                            touched: false
                          }    
            }
        });
        return f;
    };

export const formBuilder = (fields) => {
    console.log('Builder',);
    console.log(fields);
   let finalFields=fields.map((item)=>(FieldCreator(item.title,item.Fields)));
   console.log(finalFields);
return {...finalFields[0],...finalFields[1]}
    
};

// [
//     {
//       idproof: {
//         elementType: 'input',
//         label:'Upload ID Proof (Required)',
//         col:4,
//         fullRow:0,
//         heading:'Program Code Related Questions',
//         elementConfig: {
//             type: 'file',
//         },
//         value: '',
//         validation: {
//             required: true
//         },
//         valid: false,
//         touched: false
//       },
//       prescription: {
//         elementType: 'input',
//         label:'Prescription (Optional)',
//         col:4,
//         fullRow:0,
//         heading:'',
//         elementConfig: {
//             type: 'file',
//         },
//         value: '',
//         validation: {
//         },
//         valid: true,
//       },
//       name: {
//         elementType: 'input',
//         label:'Name (Optional)',
//         col:4,
//         fullRow:0,
//         heading:'',
//         elementConfig: {
//             type: 'text',
//         },
//         value: '',
//         validation: {
//         },
//         valid: true,
//       },
//     },
  
  
  
  
//     {
//       labTestDate: {
//         elementType: 'input',
//         label:'Date of last Lab Test (Optional)',
//         col:4,
//         fullRow:0,
//         heading:'Program Code Related Questions',
//         elementConfig: {
//             type: 'date',
//         },
//         value: '',
//         validation: {
//         },
//         valid: true,
//       },
//       testDateQuestion: {
//         elementType: 'input',
//         label:'test date question (Required)',
//         col:4,
//         fullRow:0,
//         heading:'',
//         elementConfig: {
//             type: 'date',
//         },
//         value: '',
//         validation: {
//           required:true
//         },
//         valid: false,
//         touched:false
//       },
//       whatCrona: {
//         elementType: 'input',
//         label:'what is corona virus (Optional)',
//         col:4,
//         fullRow:0,
//         heading:'',
//         elementConfig: {
//             type: 'text',
//         },
//         value: '',
//         validation: {
//         },
//         valid: true,
//       }
     
//     }
//   ];