import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    totalPatients:{
        totalPatients:{
        follow_up: 0,
        graph_categories: [],
        graph_data: [],
        onboarding: 0,
        total_users: 0
        }
    }
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.COUNSELLOR_DASHBOARD_DETAIL_REQUEST:
            return updateObject( state, {
                totalPatients:{
                follow_up: 0,
                graph_categories: [],
                graph_data: [],
                onboarding: 0,
                total_users: 0
                }
            });
        case actionTypes.COUNSELLOR_DASHBOARD_DETAIL_SUCCESS:
            return updateObject(state,{
                totalPatients:action.data
            });
        case actionTypes.COUNSELLOR_DASHBOARD_DETAIL_FAILED:
            return updateObject( state, {
                totalPatients:{
                follow_up: 0,
                graph_categories: [],
                graph_data: [],
                onboarding: 0,
                total_users: 0
                }
            });
        default: 
            return state;
    }
};

export default reducer;
