import React from 'react';
import HeaderBreadcrumb from '../../../../components/UI/HeaderBreadcrumb';
import Input from '../../../../components/UI/Input';
import CallSection from '../../../../components/UI/CallSection';
import CModal from '../../../../components/UI/Modal';
import { updateObject,checkValidity,checkValidityType } from '../../../../shared/utility';
import './CallSchedule.css';
import {ProgramFields,callSection,callSequenceObj,subCallSection} from './EditFields.js';
import { connect } from 'react-redux';
import * as actionsMethods from '../../../../store/actions/index';
import * as Constants from '../../../../shared/constants';
import clsx from "clsx";
import { ReactSortable } from "react-sortablejs";

let breadcrumb=[
    {
      title: "Call Schedules",
      page: "/admin/call-schedule-list"
    }];

    class EditCallSchedule extends React.Component{
    constructor(props){
      super(props);
      
      this.state={
            isSubmitting:false,
            loadingButtonStyle:{
              paddingRight: "1rem"
            },
            loading:false,
            programFields:ProgramFields,
            callSection:[],
            callSchedule0:[],
            openModal:false,
            programFieldsFormIsValid:false,
            callSectionFormIsValid:true
      };

    }//constructor end 
    resetForm=async()=>{
      await  this.setState({
        isSubmitting:false,
        loadingButtonStyle:{
          paddingRight: "1rem"
        },
        loading:false,
        programFields:ProgramFields,
        callSection:[],
        openModal:false
      });
      this.props.getProgramCode();
      this.props.getSearchModule();

   }  

    componentDidMount(){
      this.props.getProgramCode();
      this.props.getSearchModule();
      this.props.editCallSchedule(this.props.match.params.id);
    }
    componentDidUpdate(prevProps, prevState) {
      const {programCode,isSubmitting,editCallScheduleData,callScheduleReducer} = this.props;
      // if(callScheduleReducer.call_id !=prevProps.callScheduleReducer.call_id){
      //   if(callScheduleReducer.call_id !=null){
      //     this.setState({callSchedule0 : []});
      //   }
      // }
      if(programCode !=prevProps.programCode){
    
          let updatedFormElement = updateObject(this.state.programFields['program_id'], {
              elementConfig:programCode
          });
          const updatedOtherFieldForm = updateObject(this.state.programFields, {
            ['program_id']: updatedFormElement
        });
        this.setState({programFields : updatedOtherFieldForm});
      }
      if(isSubmitting !=prevProps.isSubmitting && isSubmitting==false){
        this.setState({
          loading:false,
          loadingButtonStyle: {paddingRight: "1rem"},
          isSubmitting:false
        });
       
    }

    if(editCallScheduleData.id !=prevProps.editCallScheduleData.id && editCallScheduleData.id >0){
      this.updateInitData(editCallScheduleData);
    }
    
    }

    updateInitData=async editCallScheduleData =>{
      this.props.checkCAllSchedule(editCallScheduleData.program_id);

      let selectedProgram=this.props.programCode.options.filter(item=>item.value==editCallScheduleData.program_id);
      let updateProgramId = updateObject(this.state.programFields['program_id'], {
        value:editCallScheduleData.program_id,
        valueObj:selectedProgram.length>0?selectedProgram[0]:'',
        valid:true
    });
    let daysBetweenCall=updateObject(this.state.programFields['days_between_call'], {
      value:editCallScheduleData.days_between_call,
      valid:true
  })
    const updatedProgramFields = updateObject(this.state.programFields, {
      ['program_id']: updateProgramId,
      ['days_between_call']:daysBetweenCall
  });
  let parentModule=editCallScheduleData.calls.filter(item=>item.type=='parent');
 
  for(let i=0;i<parentModule.length;i++){
  let  submodule=editCallScheduleData.calls.filter(item=>item.parent_call_id==parentModule[i].id)
  parentModule[i].submodule=submodule;
   
  await  this.iniAddSection(parentModule[i]);
  }
  
  this.setState({programFields:updatedProgramFields,programFieldsFormIsValid:true});
    }

    iniAddSection=(item)=>{
      let subCalls=[];
      for(let i=0;i<item.submodule.length;i++){
        subCalls.push(subCallSection(this.props.searchModule,i+2,item.submodule[i],));
      }
    console.log(item.call_0,subCalls);
      if(item.call_0==null){
        let index=this.state.callSection.length+1;
        let usection=[...this.state.callSection];
      usection.push({id:index,databaseId:item.id,data:callSection(this.props.searchModule,index,item),sequence:[],
        subCalls:subCalls
      });
      this.setState({callSection:usection});
    }else{
      let usection=[];
      let abc=callSection(this.props.searchModule,0,item);
      delete abc.isRepeatCall;
      delete abc.numbertimes;
      usection.push({id:1,databaseId:item.id,data:abc,sequence:[],
        subCalls:[]
      });
      this.setState({callSchedule0:usection});
    }
  }

    
  arrayConvertion=(data)=>{
      let dataArray=[];
   for (let key in data) {
       dataArray.push({
           id: key,
           config: data[key]
       });
   }
      return dataArray;
  } //array convertion field end here
  inputChangedHandler = (event, inputIdentifier,value) => {
    if(inputIdentifier=='program_id'){
      this.props.checkCAllSchedule(event.target.value);
    }
    this.commonChangedHandler(event, inputIdentifier,value,'programFields');
  } // inputChangeHandler end here

  commonChangedHandler = (event, inputIdentifier,value,stateName) => {
    let   actualValue;
     
actualValue=value==undefined?event.target.value:value.value;
   actualValue= actualValue==undefined?event.target.checked:actualValue;

   const updatedFormElement = updateObject(this.state[stateName][inputIdentifier], {
       value: actualValue,
           valid: checkValidity(actualValue, this.state[stateName][inputIdentifier].validation),
           touched: true,
           error:checkValidityType(actualValue, this.state[stateName][inputIdentifier].validation)==''?'':this.state[stateName][inputIdentifier].errorMessage[checkValidityType(actualValue, this.state[stateName][inputIdentifier].validation)],
     });
     const updatedOrderForm = updateObject(this.state[stateName], {
         [inputIdentifier]: updatedFormElement
     });
     let formisValid=true;
     for (let inputIdentifier in updatedOrderForm) {
         formisValid= updatedOrderForm[inputIdentifier].valid &&formisValid ;
     }
     let Vname=`${stateName}FormIsValid`;
    // this.setState({[stateName]: updatedOrderForm});
     this.setState({[stateName]: updatedOrderForm, [Vname]: formisValid});
}// common handler end here

selectChangedHandler = (event, inputIdentifier,value,arrayIndex) => {
      
  this.commonChangedHandler1(event, inputIdentifier,value,'callSection',arrayIndex);

}

commonChangedHandler1 = (event, inputIdentifier,value,stateName,arrayIndex) => {
  let   actualValue;
  let valueObj=[];
  if(this.state[stateName][arrayIndex]['data'][inputIdentifier].elementType=='multiple_autocomplete'){
    valueObj=value;
    
    actualValue=[];
        value.forEach(item=>{
            actualValue.push(item.value);
        });
  }else{
actualValue=value==undefined?event.target.value:value.value;
 actualValue= actualValue==undefined?event.target.checked:actualValue;
  }
   let enableDisableField={};

   if(inputIdentifier=='isRepeatCall'){
    let numberOfTimes=updateObject(this.state[stateName][arrayIndex]['data']['numbertimes'], {
      elementConfig:{...this.state[stateName][arrayIndex]['data']['numbertimes']['elementConfig'],disabled:!value} 
    });
    // let callSequence=updateObject(this.state[stateName][arrayIndex]['data']['isCallSequence'], {
    //   elementConfig:{...this.state[stateName][arrayIndex]['data']['isCallSequence']['elementConfig'],disabled:!value} 
    // });
    enableDisableField={ ['numbertimes']:numberOfTimes,
    // ['isCallSequence']:callSequence
  };
  }
  let callSequenceArray=this.state[stateName][arrayIndex]['sequence'];
  let SubCalls=this.state.callSection[arrayIndex]['subCalls'];
if(inputIdentifier=='numbertimes'){
  let actualValue1=parseInt(actualValue)-1;
  let lenth=this.state.callSection[arrayIndex]['subCalls'].length;
  if(actualValue1<lenth){
    SubCalls.splice(actualValue1, lenth-actualValue1)
  }

  let CSL=callSequenceArray.length;
  if(CSL<actualValue){
    for(let i=0;i<actualValue-CSL;i++){
      callSequenceArray.push(callSequenceObj());
    }
  }else if(CSL>actualValue){
      callSequenceArray.splice(actualValue, CSL-actualValue);
    }
    
  
}
       const updatedFormElement = updateObject(this.state[stateName][arrayIndex]['data'][inputIdentifier], {
           value: actualValue,
           valueObj:valueObj,
               valid: checkValidity(actualValue+'', this.state[stateName][arrayIndex]['data'][inputIdentifier].validation),
               touched: true,
               error:checkValidityType(actualValue+'', this.state[stateName][arrayIndex]['data'][inputIdentifier].validation)==''?'':this.state[stateName][arrayIndex]['data'][inputIdentifier].errorMessage[checkValidityType(actualValue+'', this.state[stateName][arrayIndex]['data'][inputIdentifier].validation)],
         });
         const updatedForm1 = updateObject(this.state[stateName][arrayIndex]['data'], {
       [inputIdentifier]: updatedFormElement,
      ...enableDisableField
         });
         const updatedForm2 = updateObject(this.state[stateName][arrayIndex], {
          ['data']: updatedForm1,
          ['sequence']:callSequenceArray,
          ['subCalls']:SubCalls
});
const updatedForm3= updateObject(this.state[stateName], {
  [arrayIndex]: updatedForm2 
});
let formisValid=true;
    //   for(let objecttt in updatedForm3[arrayIndex]['data']){
    //  formisValid= updatedForm3[arrayIndex]['data'][objecttt].valid &&formisValid ;
    //  }
// console.log(formisValid);
let Vname=`${stateName}FormIsValid`;
    this.setState({[stateName]: Object.values(updatedForm3),[Vname]: formisValid});
   }// commonChangedHandler1 end here

   
callSchedulerChangeHandler = (event, inputIdentifier,value,arrayIndex,sIndex) => {
this.commonChangedHandler2(event, inputIdentifier,value,'callSection',arrayIndex,sIndex,'sequence');

}

repeatCallChangeHandler = (event, inputIdentifier,value,arrayIndex,sIndex) => {
this.commonChangedHandler2(event, inputIdentifier,value,'callSection',arrayIndex,sIndex,'subCalls');

}
commonChangedHandler2 = (event, inputIdentifier,value,stateName,arrayIndex,sIndex,submodule) => {
let   actualValue;
let valueObj=[];
if(this.state[stateName][arrayIndex][submodule][sIndex][inputIdentifier].elementType=='multiple_autocomplete'){
  valueObj=value;
  
  actualValue=[];
      value.forEach(item=>{
          actualValue.push(item.value);
      });
}else{
actualValue=value==undefined?event.target.value:''+value.value;
actualValue= actualValue==undefined?event.target.checked:actualValue;
}



   const updatedFormElement = updateObject(this.state[stateName][arrayIndex][submodule][sIndex][inputIdentifier], {
       value: actualValue,
       valueObj:valueObj,
           valid: checkValidity(actualValue, this.state[stateName][arrayIndex][submodule][sIndex][inputIdentifier].validation),
           touched: true,
           error:checkValidityType(actualValue, this.state[stateName][arrayIndex][submodule][sIndex][inputIdentifier].validation)==''?'':this.state[stateName][arrayIndex][submodule][sIndex][inputIdentifier].errorMessage[checkValidityType(actualValue, this.state[stateName][arrayIndex][submodule][sIndex][inputIdentifier].validation)],
     });
     const updatedForm1 = updateObject(this.state[stateName][arrayIndex][submodule][sIndex], {
   [inputIdentifier]: updatedFormElement,
     });
     const updatedForm11 = updateObject(this.state[stateName][arrayIndex][submodule], {
      [sIndex]: updatedForm1,
        });
     const updatedForm2 = updateObject(this.state[stateName][arrayIndex], {
      [submodule]: Object.values(updatedForm11),
});
const updatedForm3= updateObject(this.state[stateName], {
[arrayIndex]: updatedForm2 
});
let formisValid=true;
//   for (let inputIdentifier in updatedForm3) {
//       for(let objecttt in updatedForm3[inputIdentifier]){
//       formisValid= updatedForm2[inputIdentifier][][objecttt].valid &&formisValid ;
//       }
//   }
let Vname=`${stateName}FormIsValid`;
this.setState({[stateName]: Object.values(updatedForm3),[Vname]: formisValid});
}// commonChangedHandler2 end here

  handleSubmit= (e)=>{
    e.preventDefault();
    if(!this.isFormValid()){
      this.props.showSnackBarNotification({
          message:'Please Fill required fields',
          type:Constants.notificationType.ERROR,
          open:true
        });
        return;
  }
    const formData = {};
    for (let formElementIdentifier in this.state.programFields) {
        formData[formElementIdentifier] = this.state.programFields[formElementIdentifier].value;
    }
    
    let sections=[]
    let clildArray=[];
    let priority=0;
    for (let formElementIdentifier in this.state.callSchedule0) {
      let mainpriority=priority=priority+1;
      let module=[];
      for(let i=0;i<this.state.callSchedule0[formElementIdentifier]['data'].module_id.value.length;i++){
        module.push({
          
          id:this.state.callSchedule0[formElementIdentifier]['databaseId'],
          module_id:this.state.callSchedule0[formElementIdentifier]['data'].module_id.value[i],
          priority:i+1
        })
      }
      sections.push({
        agenda:this.state.callSchedule0[formElementIdentifier]['data'].agenda.value,
        modules:module,
        priority:mainpriority,
        schedule_0:1,
       
      });
    }
    for (let formElementIdentifier in this.state.callSection) {
let mainpriority=priority=priority+1;
        let module=[];
        for(let i=0;i<this.state.callSection[formElementIdentifier]['data'].module_id.value.length;i++){
          module.push({
            
            id:this.state.callSection[formElementIdentifier]['databaseId'],
            module_id:this.state.callSection[formElementIdentifier]['data'].module_id.value[i],
            priority:i+1
          })
        }
        let ParentIndex=parseInt(formElementIdentifier)+parseInt(this.state.callSchedule0.length);

        for(let i=0;i<this.state.callSection[formElementIdentifier]['subCalls'].length;i++){
          priority=priority+1;
          let submodule=[];
          for(let j=0;j<this.state.callSection[formElementIdentifier]['subCalls'][i].module_id.value.length;j++){
            submodule.push({
              
              id:'',
              module_id:this.state.callSection[formElementIdentifier]['subCalls'][i].module_id.value[j],
              priority:j+1
            })
          }
          clildArray.push({
            agenda:this.state.callSection[formElementIdentifier]['subCalls'][i].agenda.value,
            modules:submodule,
            parent_call_index:ParentIndex,
            id:this.state.callSection[formElementIdentifier]['subCalls'][i].agenda.databaseId,
            priority:priority
          })
        }
        
        sections.push({
          agenda:this.state.callSection[formElementIdentifier]['data'].agenda.value,
          modules:module,
          priority:mainpriority,
          repeat_call:this.state.callSection[formElementIdentifier]['data'].isRepeatCall.value
        });
    }

    let updateObject={...formData,
      calls:sections,
      child_calls:clildArray,
      id:this.props.match.params.id
    };
    this.setState({loadingButtonStyle:{ paddingRight: "3.5rem" },loading:true,isSubmitting:true});
    this.props.createCallScheduleRequest(updateObject);

  } // handleSubmit end here
  isFormValid=()=>{
    let formisValid=true;
    for(let arrayIndex in this.state.callSection){
      for(let objecttt in this.state.callSection[arrayIndex]['data']){
       formisValid= this.state.callSection[arrayIndex]['data'][objecttt].valid &&formisValid ;
       }
      }
    if(this.state.programFieldsFormIsValid && formisValid){
         return true;
     }
    return false;
    }

  addSection=()=>{
    let index=this.state.callSection.length+1;
    let usection=[...this.state.callSection];
    usection.push({id:index,databaseId:'',data:callSection(this.props.searchModule,index),sequence:[],
      subCalls:[]
    });
    this.setState({callSection:usection});
}
addSection1=()=>{
  let index=this.state.callSchedule0.length+1;
  let usection=[...this.state.callSchedule0];
 let callll= callSection(this.props.searchModule,0);
 delete callll.isRepeatCall;
 delete callll.numbertimes;
  usection.push({id:index,data:callll,sequence:[],
    subCalls:[]
  });
  this.setState({callSchedule0:usection});
}


  removeSection=(event,i)=>{
    let section=[...this.state['callSection']];
    section.splice(i, 1);
    if(section.length==0){
        this.setState({sectionFormIsValid:false});
    }
        this.setState({callSection: section});
    
  } // removeSection end here

  removeSection1=(event,i)=>{
    let section=[...this.state['callSchedule0']];
    section.splice(i, 1);
    if(section.length==0){
     //   this.setState({sectionFormIsValid:false});
    }
        this.setState({callSchedule0: section});
    
  } // removeSection end here


  refreshSection=(event,i)=>{
    let index=i+1;
    let section=[...this.state['callSection']];
    section[i]={id:index,data:callSection(this.props.searchModule,index),sequence:[], subCalls:[]
    };
    this.setState({callSection: section,sectionFormIsValid:false});
    
  } // refreshSection end here

  refreshSection1=(event,i)=>{
    let index=i+1;
    let section=[...this.state['callSchedule0']];
    let callll= callSection(this.props.searchModule,0);
    delete callll.isRepeatCall;
    delete callll.numbertimes;
    section[i]={id:index,data:callll,sequence:[],
      subCalls:[]
    };
    this.setState({callSchedule0: section});
    
  } // refreshSection end here
  
    
  handleModalOpen = () => {
    this.setState({openModal:true});
  };

   handleModalClose = () => {
this.setState({openModal:false});
};
repeatCall = (event,i)=>{
  let callNumber=this.state.callSection[i]['subCalls'].length+2;
 let cloneField={...this.state.callSection[i]['data']};
 delete cloneField.isRepeatCall;
 delete cloneField.numbertimes
  cloneField = updateObject(cloneField, {
  ['agenda']:{
    ...cloneField['agenda'],
    heading:"Call "+callNumber
  }
});
 let repeatCallArray=[...this.state.callSection[i]['subCalls']];
 repeatCallArray.push(cloneField);

 const updatedForm2 = updateObject(this.state.callSection[i], {
  ['subCalls']:repeatCallArray
});
const updatedForm3 = updateObject(this.state.callSection, {
  [i]:updatedForm2
});
let valueArray=Object.values(updatedForm3);
this.setState({callSection:valueArray});

}
checkrepeatCall = async(value,i)=>{
  let actualvalue=parseInt(value)-1;
  let lenth=this.state.callSection[i]['subCalls'].length;
  if(actualvalue>lenth){
    return ;
  }

 let repeatCallArray=[...this.state.callSection[i]['subCalls']];
 repeatCallArray.splice(actualvalue-1, lenth-actualvalue);

 const updatedForm2 = updateObject(this.state.callSection[i], {
  ['subCalls']:repeatCallArray
});

const updatedForm3 = updateObject(this.state.callSection, {
  [i]:updatedForm2
});
let valueArray=Object.values(updatedForm3);
await this.setState({callSection:valueArray});

}

  render(){
    breadcrumb[1]= {
      title: "Edit",
      page: this.props.location.pathname
    }
    const programFieldsElementsArray =this.arrayConvertion(this.state.programFields);
    
let form = (
  <form className="kt-form" id="kt_form" onSubmit={e=>this.handleSubmit(e)}>
      <div className="kt-wizard-v1__form row">
      {programFieldsElementsArray.map((formElement,index) => (
          <Input 
              key={index}
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.isProgram==1?this.props.codes:formElement.config.elementConfig}
              col={formElement.config.col}
              label={formElement.config.label}
              value={formElement.config.value}
              valueObj={formElement.config.valueObj}
              invalid={!formElement.config.valid}
              shouldValidate={formElement.config.validation}
              touched={formElement.config.touched}
              fullRow={formElement.config.fullRow}
              heading={formElement.config.heading}
              divider={formElement.config.divider}
              leftText={formElement.config.leftText}
              error={formElement.config.error}
              changed={(event,value) => this.inputChangedHandler(event, formElement.id,value)}
              />
      ))}
     
     {this.state.callSchedule0.map((item,i)=>{
           const sectionArray =this.arrayConvertion(item.data);
        return (<div className="col-md-12 sort-div" key={item.id}>
           <div className="row innersecton">
               <div className="col-md-12">
                   <div className="right-side-section">
                   <i className="icon-2x text-dark-50 flaticon2-refresh-button" onClick={e=>this.refreshSection1(e,i)}></i>
               <i className="fa fa-trash" aria-hidden="true" onClick={e=>this.removeSection1(e,i)}></i>
               </div>
               </div>

        {sectionArray.map((formElement,index) => {
        
           return (
            
           <CallSection 
                key={index}
                isradio={formElement.config.isradio==1?1:0}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.isProgram==1?this.props.codes:formElement.config.elementConfig}
                col={formElement.config.col}
                label={formElement.config.label}
                label1={formElement.config.label1 !=undefined?formElement.config.label1:''}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                fullRow={formElement.config.fullRow}
                heading={formElement.config.heading}
                divider={formElement.config.divider}
                leftText={formElement.config.leftText}
                error={formElement.config.error}
                changed={(event,value) => this.selectChangedHandler1(event, formElement.id,value,i)}
                valueObj={formElement.config.valueObj}
                removeItemClicked={this.removeQuestionTask}
                optionIndex={i}
                />
               
);
    })
}
</div>
  </div>

  )
})
} 
        {this.state.callSection.map((item,i)=>{
           const sectionArray =this.arrayConvertion(item.data);
        return (<div className="col-md-12 sort-div" key={item.id}>
           <div className="row innersecton">
               <div className="col-md-12">
                   <div className="right-side-section">
                   <i className="icon-2x text-dark-50 flaticon2-refresh-button" onClick={e=>this.refreshSection(e,i)}></i>
               <i className="fa fa-trash" aria-hidden="true" onClick={e=>this.removeSection(e,i)}></i>
               </div>
               </div>

        {sectionArray.map((formElement,index) => {
        
           return (
            
           <CallSection 
                key={index}
                isradio={formElement.config.isradio==1?1:0}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.isProgram==1?this.props.codes:formElement.config.elementConfig}
                col={formElement.config.col}
                label={formElement.config.label}
                label1={formElement.config.label1 !=undefined?formElement.config.label1:''}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                fullRow={formElement.config.fullRow}
                heading={formElement.config.heading}
                divider={formElement.config.divider}
                leftText={formElement.config.leftText}
                error={formElement.config.error}
                changed={(event,value) => this.selectChangedHandler(event, formElement.id,value,i)}
                valueObj={formElement.config.valueObj}
                removeItemClicked={this.removeQuestionTask}
                optionIndex={i}
                />
               
);
    })
}
 {/* {item.data.isCallSequence.value==true && (<div className="col-12">
  <div className="kt-heading kt-heading--md">Call Number & Number of days after previous call in the sequence
  </div>
</div>)
}
{item.data.isCallSequence.value==true && item.sequence.map((formElement1,idx) => {

  const sequenceArray =this.arrayConvertion(formElement1);

  let renderfields=sequenceArray.map((formElement,index) => {

           return (
           <CallSection 
                key={index}
                isradio={formElement.config.isradio==1?1:0}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.isProgram==1?this.props.codes:formElement.config.elementConfig}
                col={formElement.config.col}
                label={formElement.config.label}
                label1={formElement.config.label1 !=undefined?formElement.config.label1:''}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                fullRow={formElement.config.fullRow}
                heading={formElement.config.heading}
                divider={formElement.config.divider}
                leftText={formElement.config.leftText}
                error={formElement.config.error}
                changed={(event,value) => this.callSchedulerChangeHandler(event, formElement.id,value,i,idx)}
                valueObj={formElement.config.valueObj}
                removeItemClicked={this.removeQuestionTask}
                optionIndex={i}
                />
                );
    });
return renderfields;
})
}  */}
  {this.state.callSection[i]['data']['numbertimes'].value>1 &&((this.state.callSection[i]['subCalls'].length+1)<this.state.callSection[i]['data']['numbertimes'].value) && (<div className="col-md-12"><div className="btn btn-primary btn-sm font-weight-bolder text-uppercase" onClick={e=>this.repeatCall(e,i)}>Add repeat Call</div></div>)}
</div>

{item.subCalls.map((item,indx)=>{
const itemArray =this.arrayConvertion(item);

  return (
    <div className="row innersecton" key={indx}>
  <div className="col-md-12">
  {itemArray.map((formElement,index) => {
        
        return (
         
        <CallSection 
             key={index}
             isradio={formElement.config.isradio==1?1:0}
             elementType={formElement.config.elementType}
             elementConfig={formElement.config.isProgram==1?this.props.codes:formElement.config.elementConfig}
             col={formElement.config.col}
             label={formElement.config.label}
             label1={formElement.config.label1 !=undefined?formElement.config.label1:''}
             value={formElement.config.value}
             invalid={!formElement.config.valid}
             shouldValidate={formElement.config.validation}
             touched={formElement.config.touched}
             fullRow={formElement.config.fullRow}
             heading={formElement.config.heading}
             divider={formElement.config.divider}
             leftText={formElement.config.leftText}
             error={formElement.config.error}
             changed={(event,value) =>this.repeatCallChangeHandler(event, formElement.id,value,i,indx)}
             valueObj={formElement.config.valueObj}
             removeItemClicked={this.removeQuestionTask}
             optionIndex={i}
             />
            
);
 })
}
  </div>
  </div>

  )
})
}
 
</div> 

);
})}   

       
      
      </div>
      <div className="form-group col-md-12">
          <div className="btn btn-primary btn-sm font-weight-bolder text-uppercase"  type="submit" onClick={e=>this.addSection()} >Add Call<i className="fa fa-plus-circle ml-1 p-0"></i></div>
          {(this.props.callScheduleReducer.call_id==null && this.state.callSchedule0.length<1) && ( <div className="btn btn-primary btn-sm font-weight-bolder text-uppercase ml-2"  type="submit" onClick={e=>this.addSection1()} >Add Call Schedule 0<i className="fa fa-plus-circle ml-1 p-0" style={{paddingRight:0}}></i></div>)}
         {this.state.callSection.length>1 && (<div className="btn btn-primary btn-sm font-weight-bolder text-uppercase float-right" onClick={e=>this.setState({openModal:true})}  >Rearrange<i className="flaticon2-sort float-right"></i></div>)}
          </div>
      <div className="kt-form__actions">
      <div className="flex-grow-1">
      </div>
    <div >
    <button
                  id="kt_login_signin_submit"
                  type="submit"
                  disabled={this.props.isSubmitting}
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                    {
                      "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                    }
                  )}`}
                  style={this.state.loadingButtonStyle}
                >
                    Update
                </button>
              </div>
              </div>
  </form>
);

        return (
            <>
              <HeaderBreadcrumb breadcrumb={breadcrumb} title="Call Schedules" />
              <div className="row telecall-tab">
       <div className="col-md-12">
       <div className="card card-custom">

  <div className="card-body">
  <h3 className="font-weight-bold text-dark">Edit Call Schedule</h3>
        <span className="text-muted font-weight-bold d-block mb-10">Edit call schedule for a specific program </span>
        {form}   

        <CModal open={this.state.openModal} handleModalClose={e=>this.handleModalClose()} handleModalOpen={e=>this.handleModalOpen()}>
        <div className="modal-dialog modal-md">
        <div className="modal-content ">
      <div className="modal-header">
        <h5 className="modal-title">Rearrange Call Sequence</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.handleModalClose()}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body1">

           {this.state.callSection.length>0 && (<ReactSortable
        list={this.state.callSection}
        setList={newState => this.setState({ callSection: newState })}
        className="sortlist-2"
        ghostClass= 'blue-background-class'
    
      >
         
          {this.state.callSection.map((item,i)=>{
  return (<div className="list-group-item" key={item.id}>Call {item.id}
  - {item.data.agenda.value!=''?item.data.agenda.value:'Call Agenda'}&nbsp;&nbsp;&nbsp;{item.data.module_id.valueObj.map((it,i)=>{
    return it.displayValue+"   ";})} <i className="text-dark-50 flaticon2-sort float-right"></i>
  </div>);
          })
        }

</ReactSortable>)
}

</div>
<div className="modal-footer">
          <button type="button" className="btn btn-default" data-dismiss="modal"onClick={e=>this.handleModalClose()}>Done</button>
        </div>
           </div>
           
           </div>
        </CModal>

	</div>
    </div>
    </div>
    </div>
            </>
        );
    
    }//render method end

}// class CreateCallSchedule end

const mapStateToProps= state=>{
  return {
   programCode:state.programCodes.codes,
   searchModule:state.moduleReducer.searchModule,
   callScheduleReducer:state.callScheduleReducer,
   isSubmitting:state.callScheduleReducer.isSubmitting,
   isSubmitSuccess:state.callScheduleReducer.isSubmitSuccess,
   editCallScheduleData:state.editCallScheduleReducer
  }
 }
 const mapDispatchToProps = dispatch => {
   return {
       getProgramCode: () => dispatch(actionsMethods.getProgramCode()),
       showSnackBarNotification:data=>dispatch(actionsMethods.showSnackBarNotification(data)),
       getSearchModule:() =>dispatch(actionsMethods.getSearchModuleRequest()),
       createCallScheduleRequest: data => dispatch(actionsMethods.createCallScheduleRequest(data)),
       editCallSchedule:id=>dispatch(actionsMethods.editCallScheduleRequest(id)),
       checkCAllSchedule: program_id => dispatch(actionsMethods.checkCallScheduleRequest(program_id))

 }
 }
 export default connect(mapStateToProps, mapDispatchToProps)(EditCallSchedule);