import { delay } from 'redux-saga';
import { put, call } from 'redux-saga/effects';
import axios from 'axios';
import * as actions from '../actions';
import * as Constants from '../../shared/constants';
import Qs from 'qs'

export function* initCreatePatientSaga(action) {
  try {
    axios.defaults.withCredentials = true;
    const response = yield axios.post( process.env.REACT_APP_API_URL+'specialist/patient/create',action.data);
    yield put(actions.showSnackBarNotification({
      message:'Patient created successfully',
      type:Constants.notificationType.SUCCESS,
      open:true
}));
    yield put(actions.createPatientSuccess(response.data));
  } catch (error) {
    //yield put(actions.fetchDashboardFailed());
    if(error.response.status==401){
    //  yield put(actions.logout());
      }
      yield put(actions.showSnackBarNotification({
        message:error.response.data.error.message !=undefined?error.response.data.error.message:'There is error in Sending message',
        type:Constants.notificationType.ERROR,
        open:true
  }));
      yield put(actions.createPatientFailed(error));

  }
}


export function* initGetPatientSaga(action) {
	
    try {
      axios.defaults.withCredentials = true;
    //  const response = yield axios.get( `${process.env.REACT_APP_API_URL}getall-question?per_page=${action.data.perPage}&page=${action.data.page}&orderColumn=${action.data.orderColumn}&orderBy=${action.data.orderBy}`);
    let columns=[{data:"name",name:"name",searchable:true,orderable:true,search:{value:"",regex:false}}];
    // {params:{draw: "1", start: "0",columns:columns, order: [{column: "1", dir: "asc"}],length: "10"},paramsSerializer: params => {
    //   return Qs.stringify(params)
    // }}
    const response = yield axios.get( 
      `${process.env.REACT_APP_API_URL}patient-list`,
      {params:{...action.data},paramsSerializer: params => {
      return Qs.stringify(params)
    }});
      yield put(actions.getPatients(response.data));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
      }else{
        yield put(actions.getPatients(error));
      }
    }
  }


  export function* getProgramCodeSaga(action) {
    try {
      // console.log(action);
      const response = yield axios.get( process.env.REACT_APP_API_URL+'get-program-codes');
      yield put(actions.getProgramCodeSuccess(response.data));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
        }
    }
  }

  export function* getProgramCodeDetailSaga(action) {
    try {
      // console.log(action);
      const response = yield axios.post( process.env.REACT_APP_API_URL+'program_code_detail',action.data);
      yield put(actions.getProgramCodeDetailSuccess(response.data));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
        }
    }
  }

  export function* patientSendOtpSaga(action) {
    try {
      const response = yield axios.post( process.env.REACT_APP_API_URL+'specialist/patient/send-otp ',action.data);
      yield put(actions.patientSendOtpSuccess(response.data));
      yield put(actions.showSnackBarNotification({
            message:'OTP send successfully',
            type:Constants.notificationType.SUCCESS,
            open:true
      }));
    } catch (error) {
      yield put(actions.showSnackBarNotification({
        message:error.response.data.error.message !=undefined?error.response.data.error.message:'There is error in Sending message',
        type:Constants.notificationType.ERROR,
        open:true
  }));
      if(error.response.status==401){
        yield put(actions.logout());
        }
        yield put(actions.patientSendOtpFailed(error));
    }
  }

  export function* patientOtpVerifySaga(action) {
    try {
      const response = yield axios.post( process.env.REACT_APP_API_URL+'patient/verify-otp',action.data);
      yield put(actions.patientOtpVerifySuccess(response.data));
    } catch (error) {
      if(error.response.status==401){
      //  yield put(actions.logout());
        }
        yield put(actions.patientOtpVerifyFailed(error))
    }
  }

  export function* getUserModeSaga(action){
    try {
      const response = yield axios.get(`${process.env.REACT_APP_API_URL}get-user-mode/${action.id}`);
      yield put(actions.getUserModeSuccess(response));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
        }
        yield put(actions.getUserModeFailed(error))
    }
  }


  export function* initProfileCompleteStepSaga(action) {
    try {
      axios.defaults.withCredentials = true;
      const response = yield axios.post( process.env.REACT_APP_API_URL+'specialist/patient/profile/complete',action.data);
      yield put(actions.showSnackBarNotification({
        message:'Patient Profile completed successfully',
        type:Constants.notificationType.SUCCESS,
        open:true
  }));
      yield put(actions.profileCompleteStepSuccess(response.data));
    } catch (error) {
      //yield put(actions.fetchDashboardFailed());
      if(error.response.status==401){
      //  yield put(actions.logout());
        }
        yield put(actions.showSnackBarNotification({
          message:error.response.data.error.message !=undefined?error.response.data.error.message:'There is error in Sending message',
          type:Constants.notificationType.ERROR,
          open:true
    }));
        yield put(actions.profileCompleteStepFailed(error));
  
    }
  }
  