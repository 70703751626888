import * as actionTypes from './actionTypes';


export const navUserMenuRequest=()=>{
    return {
      type: actionTypes.NAV_USER_MENU_REQUEST,
    }
  };

  export const navUserMenuSuccess=data=>{
    return {
      type: actionTypes.NAV_USER_MENU_SUCCESS,
      data:data
    }
  };
