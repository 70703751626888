import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

let initalParam={
    nok:{
       first_name:"",
       last_name:"",
       relation_id:0,
       relation:"",
       mobile:""
    },
    health:{
       height:'',
       weight:'',
       waist_size:'',
       daily_activity:1,
       dob:"1984-01-01"
    },
    contact:{
       alternate_number:"",
       state_id:'',
       state:"",
       city_id:'',
       email:"",
       city:"",
       address:"",
       locality:"",
       pincode:''
    }
 };
const initialState = {
    profile:{...initalParam}
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.EDIT_PATIENT_PROFILE_REQUEST:
        case actionTypes.EDIT_PATIENT_PROFILE_FAILED:
            return updateObject( state, {
                profile:{...initalParam}
              });   
        case actionTypes.EDIT_PATIENT_PROFILE_SUCCESS:    
      return updateObject( state, {
        profile:action.data
        });
        default: 
            return state;
    }
};

export default reducer;
