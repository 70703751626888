import * as actionTypes from './actionTypes';


/**
 * Get Patient Profile Detail
 */
export const getPatientProfileDetailRequest=(id)=>{
return {
  type:actionTypes.GET_PATIENT_PROFILE_DETAIL_REQUEST,
  id:id
};
};

export const getPatientProfileDetailSuccess=data=>{
  return {
    type:actionTypes.GET_PATIENT_PROFILE_DETAIL_SUCCESS,
    data:data
  };
};

export const getPatientProfileDetailFailed=data=>{
  return {
    type:actionTypes.GET_PATIENT_PROFILE_DETAIL_FAILED,
    data:data
  };
}

export const getKeyParametersRequest=(data)=>{
return {
  type:actionTypes.GET_KEY_PARAMETERS_REQUEST,
  data:data
};
};

export const getKeyParametersSuccess=data=>{
  return {
    type:actionTypes.GET_KEY_PARAMETERS_SUCCESS,
    data:data
  };
};

export const getKeyParametersFailed=data=>{
  return {
    type:actionTypes.GET_KEY_PARAMETERS_FAILED,
    data:data
  };
}

export const getPatientModulesRequest =(data)=>{
  return {
    type:actionTypes.CHECK_PROGRAM_MODULES_REQUEST,
    data:data
  };
};

export const getPatientModulesSuccess= (data)=>{
  return {
    type:actionTypes.CHECK_PROGRAM_MODULES_SUCCESS,
    data:data
  };
};

export const getPatientModulesFailed=(data)=>{
  return {
    type:actionTypes.CHECK_PROGRAM_MODULES_FAILED,
    data:data
  }
}