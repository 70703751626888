import React, { Component } from "react";
import {
  Portlet,
  PortletBody} from "../../../partials/content/Portlet";
import './Patients.css';
import { updateObject,checkValidity,checkValidityType,insertKey} from '../../../shared/utility';
import Input from '../../../components/UI/Input';
import {Link} from "react-router-dom";
import HeaderBreadcrumb from '../../../components/UI/HeaderBreadcrumb';
import { connect } from 'react-redux';
import * as actionsMethods from '../../../store/actions/index';
import {Step1,Step2,userModeField,associateCounsellor,programHCP,Inches}  from './createPatientField';
import * as Constants from '../../../shared/constants';
import clsx from "clsx";
import { FormattedMessage,injectIntl } from "react-intl";


const breadcrumb=[
  {
    title: "List",
    page: "patient"
  }, {
    title: "Create",
    page: "patient-add"
  }];

  const Actions=[
    {
        divider:0,
        icon:'flaticon2-user',
        name:'List',
        url:'/patient',
        notification:'Patient',
        ncolor:'label-light-primary'
    }
];

const onboardingQ=[];

const steps=[
        {
          name:<FormattedMessage id="PATIENT.CREATE_PATIENT" />,
          id:1,
          icon:'flaticon2-add fa-1x'
        },
        {
          name:<FormattedMessage id="PATIENT.PATIENT_PROFILE" />,
          id:2,
          icon:'flaticon2-calendar-3 fa-1x'
        },
        {
          name:<FormattedMessage id="PATIENT.PROGRAM_ENROLLMENT" />,
          id:3,
          icon:'flaticon2-hospital fa-1x'
        }
];
class AddPatient extends Component {
  constructor(props){
               
    super(props);
    this.state={
      isSubmitting:false,
      loadingButtonStyle:{
        paddingRight: "1rem"
      },
      loading:false,
      token:'',
         programDetail:Step1(props.intl),
  patientProfile:Step2,
onboardingQuestions:onboardingQ[Math.floor(Math.random() * onboardingQ.length)],

  formIsValid: false,
  secondFormIsValid: true,
  thirdFormIsValid: false,
  isSecondStepSkip:false,
  formStep:1,
  filledStep:1,
  errorShow:false   
    };
  }

  componentDidMount(){
    this.getPrePatientData();
  }

  getPrePatientData = async()=>{
  await  this.props.getProgramCode();
  // this.props.doctorsList();
 this.props.careTakerReleation();
 this.props.getStates();
  }
  componentDidUpdate(prevProps, prevState) {
    const { prgramsField,isOtpVerified,getUserModeReducer,isFormSubmit} = this.props;
    if(isFormSubmit !=prevProps.isFormSubmit && isFormSubmit==false){
    
      let stepup={};
      if(this.props.step1R.firstSuccess==true){
        stepup={formStep:2,filledStep:2,token:this.props.step1R.token};
      }
      this.setState({
        loading:false,
        loadingButtonStyle: {paddingRight: "1rem"},
        isSubmitting:false,
        ...stepup
      });
      window.scroll({top: 0, left: 0, behavior: 'smooth' });
  }
  if(this.props.step2R !=prevProps.step2R && this.props.step2R.loading==false ){
    this.setState({
      loading:false,
      loadingButtonStyle: {paddingRight: "1rem"},
      isSubmitting:false
    });
  }

    if(getUserModeReducer !=prevProps.getUserModeReducer){
      let ProgramFeilds={...this.state.programDetail};
      delete ProgramFeilds.mode;
      delete ProgramFeilds.associate;
      delete ProgramFeilds.hcp_id;
     
     
      if(getUserModeReducer.programHCP.length>0){
        let prhcp=[...getUserModeReducer.programHCP];
        prhcp.unshift({
          value:'',
          displayValue:'Choose HCP'
      });
        let phcp=programHCP(prhcp,getUserModeReducer.hcp_required);

        ProgramFeilds=insertKey('hcp_id',phcp,ProgramFeilds,1);
      }
      if(getUserModeReducer.counselor.length>0){
       
        let associateField=associateCounsellor(getUserModeReducer.counselor,getUserModeReducer.counselor[0].value);
        ProgramFeilds=insertKey('associate',associateField,ProgramFeilds,1);
      }
      if(parseInt(getUserModeReducer.user_mode)!=0){
        let userFiled=userModeField(parseInt(getUserModeReducer.user_mode_priority));
         ProgramFeilds=insertKey('mode',userFiled,ProgramFeilds,1);
       }
      this.setState({programDetail:ProgramFeilds});
    }
    if(isOtpVerified != prevProps.isOtpVerified){
  //     let updatedFormElement = updateObject(this.state.programDetail['otp'], {
  //       error: isOtpVerified==false?'OTP did not match':'',
  //       successMsg: isOtpVerified==true?'OTP matched':'',
  //       valid: isOtpVerified==true?true:false,
  //       isOtpMatch:isOtpVerified==true?true:false,
  //   });
  //   const updatedOrderForm = updateObject(this.state.programDetail, {
  //     ['otp']: updatedFormElement
  // });
  // this.setState({programDetail : updatedOrderForm});
    }
    if(prevProps.doctors !=this.props.doctors){
   
   let updatedFormElement = updateObject(this.state.patientProfile['doctor'], {
      elementConfig:{
        ...this.props.doctors
      }
  });
  const updatedOrderForm = updateObject(this.state.patientProfile, {
    ['doctor']: updatedFormElement
});
this.setState({patientProfile: updatedOrderForm});
   }

   if(prevProps.relations !=this.props.relations){
    let updatedFormElement = updateObject(this.state.patientProfile['relation'], {
      elementConfig:{
        ...this.props.relations
      }
  });
  const updatedOrderForm = updateObject(this.state.patientProfile, {
    ['relation']: updatedFormElement
});
this.setState({patientProfile: updatedOrderForm});
   }
   if(prevProps.states !=this.props.states){
    let updatedFormElement = updateObject(this.state.patientProfile['state'], {
      elementConfig:{
        ...this.props.states
      }
  });
  const updatedOrderForm = updateObject(this.state.patientProfile, {
    ['state']: updatedFormElement
});
this.setState({patientProfile: updatedOrderForm});
   }

   if(prevProps.cities !=this.props.cities){
    let updatedFormElement = updateObject(this.state.patientProfile['city'], {
      elementConfig:{
        ...this.props.cities
      },
      value:""
  });
  const updatedOrderForm = updateObject(this.state.patientProfile, {
    ['city']: updatedFormElement
});
this.setState({patientProfile: updatedOrderForm});
   }


    if (prevProps.prgramsField != prgramsField) {
      this.setState({onboardingQuestions:prgramsField});
    }
  }
  programHandler = ( event ) => {
    event.preventDefault();
   if(!this.state.formIsValid){
     this.setState({errorShow:true});
     window.scroll({top: 0, left: 0, behavior: 'smooth' })
     return 0;
   }
    
    
    let patientObj={
      first_name:this.state.programDetail.first_name.value,
      last_name : this.state.programDetail.last_name.value,
      gender : this.state.programDetail.gender.value==1?"Male":"Female",
      mobile:this.state.programDetail.mobileNumber.value,
      otp : this.state.programDetail.otp.value,
      program_id: this.state.programDetail.program_id.value,
      hcp_id:this.state.programDetail.hasOwnProperty('hcp_id')?this.state.programDetail.hcp_id.value:'',
      mode:this.state.programDetail.mode.value?1:0, 
      "associate":this.state.programDetail.hasOwnProperty('associate')?this.state.programDetail.associate.value:0 }
  this.setState({loadingButtonStyle:{ paddingRight: "3.5rem" },loading:true,isSubmitting:true});
  this.props.initCreatePatient(patientObj);

}
patientProfileHandler = ( event ) => {
  event.preventDefault();
  let height=this.state.patientProfile['height'].value;
  let weight=this.state.patientProfile['weight'].value;
  if(this.state.patientProfile['heightCm'].value==2 && this.state.patientProfile['height'].value !=''){
    let inchsss=this.state.patientProfile['inches'].value!=''?parseInt(this.state.patientProfile['inches'].value)* 2.54:0
    height=((parseInt(this.state.patientProfile['height'].value)* 12) * 2.54) +inchsss ;
  }
  if(this.state.patientProfile['weightType'].value==2 && this.state.patientProfile['weight'].value !=''){
    weight=this.state.patientProfile['weight'].value*0.45359237;
  }
  

  let requestBody={
    health: {
        height : height,
        weight : weight,
        dob: this.state.patientProfile['dob'].value,
        waist_size:this.state.patientProfile['waistSize'].value,
        daily_activity:this.state.patientProfile['activity'].value
    },
    contact: {
         email: this.state.patientProfile['patientEmail'].value,
        alternate_number : this.state.patientProfile['alternativeMobileNumber'].value,
        state_id:this.state.patientProfile['state'].value,
        city_id: this.state.patientProfile['city'].value,
        address: this.state.patientProfile['address'].value,
        pincode : this.state.patientProfile['pincode'].value,
        locality : this.state.patientProfile['locality'].value
    },
    nok: {
        first_name : this.state.patientProfile['memberFirstName'].value,
        last_name : this.state.patientProfile['memberLastName'].value,
        relation_id: this.state.patientProfile['relation'].value,
        mobile:this.state.patientProfile['familyMobileNumber'].value    
    },
  token:this.state.token
};
this.setState({loadingButtonStyle:{ paddingRight: "3.5rem" },loading:true,isSubmitting:true});

this.props.profileCompleteStep(requestBody);
// this.setState({onboardingQuestions:onboardingQ[Math.floor(Math.random() * onboardingQ.length)]});
// this.setState({formStep:3});
// this.setState({filledStep:2});

}
onboardingQuestionHandler = ( event ) => {
  event.preventDefault();
  const formData = {};
  for (let formElementIdentifier in this.state.onboardingQuestions) {
      formData[formElementIdentifier] = this.state.onboardingQuestions[formElementIdentifier].value;
  }
  this.setState({filledStep:3});
  for (let formElementIdentifier in this.state.programDetail) {
      formData[formElementIdentifier] = this.state.programDetail[formElementIdentifier].value;
  }
  for (let formElementIdentifier in this.state.patientProfile) {
    formData[formElementIdentifier] = this.state.patientProfile[formElementIdentifier].value;
}

  alert("Success");
  this.props.initCreatePatient(formData);
//this.setState({formStep:});

}

  inputChangedHandler =(event, inputIdentifier,value) => {
    this.setState({errorShow:false});
   if(inputIdentifier=='program_id'){
    let programId=value==undefined?event.target.value:value.value
      this.props.getProgramCodeDetail(programId);
      this.props.getUserMode(programId);
   }
   if(inputIdentifier=='otp'){
    // this.props.verifyOtp(this.state.programDetail.mobileNumber.value,event.target.value);
   }
        let updatedFormElement;
        if(inputIdentifier=='mode'){
           updatedFormElement = updateObject(this.state.programDetail[inputIdentifier], {
            value:event.target.checked,
            valid: checkValidity(event.target.value, this.state.programDetail[inputIdentifier].validation),
            touched: true,
            leftText:event.target.checked?'Online':'Offline',
            error:checkValidityType(event.target.value, this.state.programDetail[inputIdentifier].validation)==''?'':this.state.programDetail[inputIdentifier].errorMessage[checkValidityType(event.target.value, this.state.programDetail[inputIdentifier].validation)],
        });
        }else{
     updatedFormElement = updateObject(this.state.programDetail[inputIdentifier], {
        value: value==undefined?event.target.value:value.value,
        valid: checkValidity(event.target.value, this.state.programDetail[inputIdentifier].validation),
        touched: true,
        error:checkValidityType(event.target.value, this.state.programDetail[inputIdentifier].validation)==''?'':this.state.programDetail[inputIdentifier].errorMessage[checkValidityType(event.target.value, this.state.programDetail[inputIdentifier].validation)],
    });
  }
    const updatedOrderForm = updateObject(this.state.programDetail, {
        [inputIdentifier]: updatedFormElement
    });
    
    let formIsValid = true;
    for (let inputIdentifier in updatedOrderForm) {
        formIsValid = updatedOrderForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({programDetail: updatedOrderForm, formIsValid: formIsValid});
}
patientProfileChangedHandler = (event, inputIdentifier,value) => {
  let actualValue='';
  actualValue=value==undefined?event.target.value:value.value;
  actualValue= (actualValue==undefined) ?event.target.checked:actualValue;
  if(inputIdentifier=='activity' ){
      actualValue=event.target.value;
  }
  if(inputIdentifier=='state'){
    this.props.getCities(actualValue);
  }
  const updatedFormElement = updateObject(this.state.patientProfile[inputIdentifier], {
    value: actualValue,
        valid: checkValidity(event.target.value, this.state.patientProfile[inputIdentifier].validation),
        touched: true,
        error:checkValidityType(event.target.value, this.state.patientProfile[inputIdentifier].validation)==''?'':this.state.patientProfile[inputIdentifier].errorMessage[checkValidityType(event.target.value, this.state.patientProfile[inputIdentifier].validation)],
  });
  
  let updatedOrderForm = updateObject(this.state.patientProfile, {
      [inputIdentifier]: updatedFormElement,
  });
  if(inputIdentifier=='heightCm'){
    delete updatedOrderForm.height;
    if(actualValue=='2'){
      updatedOrderForm=insertKey('height',Inches(2,'Feet',"Enter Feet",1,7),updatedOrderForm,1);
      updatedOrderForm=insertKey('inches',Inches(2,'Inch',"Enter Inch",0,12),updatedOrderForm,2);


    }else{
delete updatedOrderForm.inches;
updatedOrderForm=insertKey('height',Inches(4,'Height',"Enter Height in CM",30,213),updatedOrderForm,1);

    }
  }

  let secondFormIsValid = true;
  for (let inputIdentifier in updatedOrderForm) {
    secondFormIsValid = updatedOrderForm[inputIdentifier].valid && secondFormIsValid;
  }
  this.setState({patientProfile: updatedOrderForm, secondFormIsValid: secondFormIsValid});
}

onboardingQuestionChangedHandler = (event, inputIdentifier) => {
  const updatedFormElement = updateObject(this.state.onboardingQuestions[inputIdentifier], {
      value: this.state.onboardingQuestions[inputIdentifier]['isImage']==1?event.target.files[0]:event.target.value,
      valid: checkValidity(event.target.value, this.state.onboardingQuestions[inputIdentifier].validation),
      touched: true
  });
  const updatedOrderForm = updateObject(this.state.onboardingQuestions, {
      [inputIdentifier]: updatedFormElement
  });
  
  let thirdFormIsValid = true;
  for (let inputIdentifier in updatedOrderForm) {
    thirdFormIsValid = updatedOrderForm[inputIdentifier].valid && thirdFormIsValid;
  }
  this.setState({onboardingQuestions: updatedOrderForm, thirdFormIsValid: thirdFormIsValid});
}

changeStepperHandler = (event,number) =>{
  if(number<=this.state.filledStep){
    this.setState({formStep:number});
  }
}

 handleResendOtp=e=>{
   if(this.state.programDetail.mobileNumber.value!='' && this.state.programDetail.mobileNumber.valid==true){
    this.props.sendOtp(this.state.programDetail.mobileNumber.value);
   }else{
    this.props.showSnackBarNotification({
      message:'Phone number field is required',
      type:Constants.notificationType.ERROR,
      open:true
    })
   }  
}
handleSkipOtp=e=>{
  // alert("abc");
  let updatedFormElement = updateObject(this.state.programDetail['otp'], {
    error: '',
    label:'OTP',
    successMsg:'',
    valid: true,
    isOtpMatch:false,
});
const updatedOrderForm = updateObject(this.state.programDetail, {
  ['otp']: updatedFormElement
});
this.setState({programDetail : updatedOrderForm});
this.props.showSnackBarNotification({
  message:'Otp varification skipped successfully',
  type:Constants.notificationType.SUCCESS,
  open:true
})
}

 render(){ 
  const formElementsArray = [];
  for (let key in this.state.programDetail) {
      formElementsArray.push({
          id: key,
          config: this.state.programDetail[key]
      });
  }
  let form = (
    <form className="kt-form" id="kt_form" onSubmit={this.programHandler}>
        <div className="kt-wizard-v1__form row">
        {formElementsArray.map(formElement => (
            <Input 
                key={`1${formElement.id}`}
                isradio={formElement.config.isradio==1?1:0}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.isProgram==1?this.props.codes:formElement.config.elementConfig}
                col={formElement.config.col}
                label={formElement.config.label}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                fullRow={formElement.config.fullRow}
                heading={formElement.config.heading}
                divider={formElement.config.divider}
                leftText={formElement.config.leftText}
                error={formElement.config.error}
                sendOtp={formElement.config.otp_resend==true?true:false}
                skipOtp={formElement.config.skip_otp==true?true:false}
                sendOtpClick={e=>this.handleResendOtp(e)}
                skipOtpClick={e=>this.handleSkipOtp(e)}
                changed={(event,value) => this.inputChangedHandler(event, formElement.id,value)}
                sendOtpLoading={this.props.sendOtpLoading}
                isOtpMatch={formElement.config.isOtpMatch==true?true:false}
                successMsg={formElement.config.successMsg}
                />
        ))}
        
        </div>
        <div className="kt-form__actions">
        <Link to="/patient"  className="btn btn-secondary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" >Cancel</Link>
                  
                  <button
                  id="kt_login_signin_submit"
                  type="submit"
                  disabled={this.props.isSubmitting}
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                    {
                      "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                    }
                  )}`}
                  style={this.state.loadingButtonStyle}
                >
                    Next
                </button>
                  </div>
    </form>
);
const formElementsArray1 = [];
for (let key in this.state.patientProfile) {
    formElementsArray1.push({
        id: key,
        config: this.state.patientProfile[key]
    });
}
let paitentDetail = (
  <form className="kt-form" id="kt_form2" onSubmit={this.patientProfileHandler}>
      <div className="kt-wizard-v1__form row">
      {formElementsArray1.map(formElement => (
          <Input 
             key={`2${formElement.id}`}
              isradio={formElement.config.isradio==1?1:0}
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.elementConfig}
              col={formElement.config.col}
              label={formElement.config.label}
              value={formElement.config.value}
              invalid={!formElement.config.valid}
              shouldValidate={formElement.config.validation}
              touched={formElement.config.touched}
              fullRow={formElement.config.fullRow}
              heading={formElement.config.heading}
              divider={formElement.config.divider}
              changed={(event,value) => this.patientProfileChangedHandler(event, formElement.id,value)}
              error={formElement.config.error}
               />
      ))}
      
      </div>
      <div className="kt-form__actions">
        <div className="flex-grow-1">
          {/* <span className="btn btn-secondary btn-elevate kt-login__btn-secondary" onClick={()=>this.setState({formStep:1})} >previous</span> */}
        </div>
      <div ><span className="mr-1 btn btn-secondary btn-elevate kt-login__btn-secondary" onClick={()=>this.setState({formStep:3,isSecondStepSkip:true})} >Skip</span>
                {/* <button className="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" disabled={!this.state.secondFormIsValid} type="submit" >Next</button> */}
                <button
                  id="kt_login_signin_submit"
                  type="submit"
                  disabled={!this.state.secondFormIsValid}
                  className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                    {
                      "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                    }
                  )}`}
                  style={this.state.loadingButtonStyle}
                >
                    Next
                </button>

                </div>
                </div>
  </form>
);


const formElementsArray2 = [];
for (let key in this.state.onboardingQuestions) {
    formElementsArray2.push({
        id: key,
        config: this.state.onboardingQuestions[key]
    });
}
let onboardingDetail = (
  <form className="kt-form" id="kt_form3" onSubmit={this.onboardingQuestionHandler}>
      <div className="kt-wizard-v1__form row">
      {formElementsArray2.map(formElement => (
          <Input 
          key={`3${formElement.id}`}
          isradio={formElement.config.isradio==1?1:0}
              elementType={formElement.config.elementType}
              elementConfig={formElement.config.elementConfig}
              col={formElement.config.col}
              label={formElement.config.label}
              value={formElement.config.value}
              invalid={!formElement.config.valid}
              shouldValidate={formElement.config.validation}
              touched={formElement.config.touched}
              fullRow={formElement.config.fullRow}
              heading={formElement.config.heading}
              divider={formElement.config.divider}
              changed={(event) => this.onboardingQuestionChangedHandler(event, formElement.id)} />
      ))}
      
      </div>
      <div className="kt-form__actions"><span className="btn btn-secondary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" onClick={()=>this.setState({formStep:2})} >previous</span>
                <button className="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" disabled={!this.state.thirdFormIsValid} type="submit" >Submit</button></div>
  </form>
);
let stepsHtml=steps.map(step=><Stepper handler={(event)=>this.changeStepperHandler(event,step.id)} formStep={this.state.formStep} {...step}  key={step.id} /> );

  return (
    <>
     <HeaderBreadcrumb breadcrumb={breadcrumb} actions={Actions} title="Patient Create" />
    <Portlet>
      <PortletBody>
      
    {/* <div className="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid"> */}

         <div className="kt-grid kt-wizard-v1 kt-wizard-v1--white" id="kt_wizard_v1" data-ktwizard-state="step-first">
            <div className="kt-grid__item">
               <div className="kt-wizard-v1__nav">
                  <div className="kt-wizard-v1__nav-items kt-wizard-v1__nav-items--clickable">
                   
                  {stepsHtml}
                    
                  </div>
               </div>
            </div>
            {this.state.errorShow &&
        (<><br/>
            <div className="alert alert-danger alert-dismissible " >
  <button type="button" className="close" data-dismiss="alert">&times;</button>
  <i className={`fa fa-exclamation main-validation-error`}></i> There is some error in your submission. Please correct them
</div></>)}
            <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v1__wrapper">
   
                  <div className="kt-wizard-v1__content full-wdith" data-ktwizard-type="step-content" data-ktwizard-state={`${this.state.formStep==1?'current':''}`}>
                     {form}

                  </div>
                  <div className="kt-wizard-v1__content full-wdith" data-ktwizard-type="step-content" data-ktwizard-state={`${this.state.formStep==2?'current':''}`}>
                  {paitentDetail}
                  </div>
                  <div className="kt-wizard-v1__content full-wdith" data-ktwizard-type="step-content" data-ktwizard-state={`${this.state.formStep==3?'current':''}`}>
                    {onboardingDetail}
                  </div>
                  
            </div>
         </div>
   
{/* </div> */}
</PortletBody>
</Portlet>
</>
    );
  }
}

const Stepper=props=>{
  return (
    <div className="kt-wizard-v1__nav-item" data-ktwizard-type="step" data-ktwizard-state={`${props.formStep==props.id?'current':''}`}>
    <div className="kt-wizard-v1__nav-body" onClick={props.handler} >
       <div className="kt-wizard-v1__nav-icon"><i className={props.icon}></i></div>
  <div className="kt-wizard-v1__nav-label">{props.id}.  {props.name}</div>
    </div>
 </div>
  );
}

const mapStateToProps= state=>{
  return {
    codes:state.programCodes.codes,
    prgramsField:state.programCodes.programFields,
    doctors:state.doctorsList.doctors,
    sendOtpLoading:state.patient.sendOtpLoading,
    isOtpVerified:state.patient.isOtpVerified,
    getUserModeReducer:state.getUserModeReducer,
    isFormSubmit:state.patient.loading,
    relations:state.careTakerRelation.relation,
    step1R:state.patient.step1,
    step2R:state.patient.step2,
    states:state.commonReducer.states,
    cities:state.commonReducer.cities

  }
}
const mapDispatchToProps = dispatch => {
  return {
    initCreatePatient: data => dispatch(actionsMethods.initCreatePatient(data)),
    getProgramCode: ()=>dispatch(actionsMethods.getProgramCode()),
    getProgramCodeDetail: id=>dispatch(actionsMethods.getProgramCodeDetail(id)),
    doctorsList: ()=>dispatch(actionsMethods.doctorsListRequest()),
    sendOtp:phone=>dispatch(actionsMethods.patientSendOtpRequest(phone)),
    verifyOtp:(phone,otp)=>dispatch(actionsMethods.patientOtpVerifyRequest(phone,otp)),
    showSnackBarNotification:data=>dispatch(actionsMethods.showSnackBarNotification(data)),
    getUserMode:id=>dispatch(actionsMethods.getUserModeRequest(id)),
    careTakerReleation:()=>dispatch(actionsMethods.careTakerRelationRequest()),
    profileCompleteStep: data => dispatch(actionsMethods.profileCompleteStepRequest(data)),
    getStates:()=>dispatch(actionsMethods.getStateRequest()),
    getCities:state_id=>dispatch(actionsMethods.getCityRequest(state_id))
}
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddPatient));



