import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    data:[],
    totalRows:0,
    page:1
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_ALL_MODULES_SUCCESS:
      return updateObject( state, {
          data:[...action.data.original.data],
          totalRows:action.data.original.recordsTotal,
          page:action.data.original.draw
        });
        default: 
            return state;
    }
};

export default reducer;
