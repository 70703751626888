import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    status:1,
    user_mode_priority:0,
    user_mode:0,
    counselor:[],
    hcp_required:0,
    programHCP: []
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_USER_MODE_REQUEST:
        case actionTypes.EDIT_TASK_FAILED:
            return updateObject( state, {
                status:1,
                user_mode_priority:0,
                user_mode:0,
                counselor:[],
                hcp_required:0,
                programHCP: []
            });
        case actionTypes.GET_USER_MODE_SUCCESS:
            let Counsellor=[];
            for(let i=0;i<action.data.data.counselor.length;i++){
                Counsellor.push({value:action.data.data.counselor[i].id,displayValue:action.data.data.counselor[i].name})
            }
            let hcpArray=[];
            for(let i=0;i<action.data.data.programHCP.length;i++){
                hcpArray.push({value:action.data.data.programHCP[i].id,displayValue:action.data.data.programHCP[i].name})
            }

            return updateObject( state,{
                ...action.data.data,
                counselor:Counsellor,
                programHCP:hcpArray
            });
        default: 
            return state;
    }
};

export default reducer;
