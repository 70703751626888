import * as actionTypes from './actionTypes';

export const createCallScheduleRequest =  (data)  => {
  return {
    type: actionTypes.CREATE_CALL_SCHEDULE_REQUEST,
    data:data
  };
};
export const createCallScheduleSuccess =  (data)  => {
  return {
    type: actionTypes.CREATE_CALL_SCHEDULE_SUCCESS,
    data:data
  };
};

export const createCallScheduleFailed =  (data)  => {
    return {
      type: actionTypes.CREATE_CALL_SCHEDULE_FAILED,
      data:data
    };
  };
  
  
  export const getAllCallsRequest =  (data)  => {
  return {
    type: actionTypes.GET_ALL_CALLS_REQUEST,
    data:data
  };
};

export const getAllCallsSuccess =  (data)  => {
  return {
    type: actionTypes.GET_ALL_CALLS_SUCCESS,
    data:data
  };
};

export const getAllCallsFailed =  (data)  => {
  return {
    type: actionTypes.GET_ALL_CALLS_FAILED,
    data:data
  };
};

/**
 * Edit call Schedule
 */

export const editCallScheduleRequest =  (id)  => {
  return {
    type: actionTypes.EDIT_CALL_SCHEDULE_REQUEST,
    id:id
  };
};
export const editCallScheduleSuccess =  (data)  => {
  return {
    type: actionTypes.EDIT_CALL_SCHEDULE_SUCCESS,
    data:data
  };
};

export const editCallScheduleFailed =  (data)  => {
    return {
      type: actionTypes.EDIT_CALL_SCHEDULE_FAILED,
      data:data
    };
  };


  
/**
 * check call Schedule
 */

export const checkCallScheduleRequest =  (program_id)  => {
  return {
    type: actionTypes.CHECK_CALL_SCHEDULE_REQUEST,
    program_id:program_id
  };
};
export const checkCallScheduleSuccess =  (data)  => {
  return {
    type: actionTypes.CHECK_CALL_SCHEDULE_SUCCESS,
    data:data
  };
};

export const checkCallScheduleFailed =  (data)  => {
    return {
      type: actionTypes.CHECK_CALL_SCHEDULE_FAILED,
      data:data
    };
  };
  
  