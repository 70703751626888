import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import { update } from 'lodash';

const initialState = {
     questionType:{
        options:[]
     },
     connectedQuestions:{
         options:[]
     },
     function_to_call:{
         options:[]
     },
     units:{
         options:[]
     },
     properties:[],
     isCreateQuestionRequest:false
};
const getQuestonTypeSuccess = (state, action) => {
    let data=[];
    if(action.data.question_type.length>0){
        for(let i=0;i<action.data.question_type.length;i++){
        data.push({value:action.data.question_type[i].id,displayValue:action.data.question_type[i].name,type:action.data.question_type[i].value,has_unit:action.data.question_type[i].has_unit});
        }
    }
    let dummyProgram=[{
        value:'',
        displayValue:'Select Question Type',
        type:''
    }];
    let data1=[];
    if(action.data.function_to_call.length>0){
        for(let i=0;i<action.data.function_to_call.length;i++){
        data1.push({value:action.data.function_to_call[i].id,displayValue:action.data.function_to_call[i].name,type:action.data.function_to_call[i].value});
        }
    }
    let data2=[];
    if(action.data.units.length>0){
        for(let i=0;i<action.data.units.length;i++){
        data2.push({value:action.data.units[i].id,displayValue:action.data.units[i].unit});
        }
    }
    // let dummyProgram2=[{
    //     value:'',
    //     displayValue:'Select Unit',
    //     type:''
    // }];
 let abc=updateObject( state, {
        questionType:{options:[...dummyProgram,...data]}
    });
    abc=updateObject(abc,{
        function_to_call:{options:[...data1]}
    });
    abc=updateObject(abc,{
        units:{options:[...data2]}
    })
    return abc;
};
const getConnectedQuestonSuccess = (state, action) => {
    let data=[];
    if(action.data.length>0){
        for(let i=0;i<action.data.length;i++){
        data.push({value:action.data[i].id,displayValue:action.data[i].question});
        }
    }
    let dummyProgram=[{
        value:'',
        displayValue:'Select connected Question',
    }];
 let abc=updateObject( state, {
    connectedQuestions:{options:[...dummyProgram,...data]}
    });
    return abc;
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_QUESTION_TYPE_SUCCESS: 
            return getQuestonTypeSuccess(state,action);
           break;  
        case actionTypes.GET_CONNECTED_QUESTION_SUCCESS:
            return getConnectedQuestonSuccess(state,action)
        case actionTypes.CREATE_QUESTION_REQUEST:   
           return updateObject( state, {isCreateQuestionRequest:true});
        case actionTypes.CREATE_PAITENT_SUCCESS:   
        return updateObject( state, {isCreateQuestionRequest:false});
        case actionTypes.CREATE_QUESTION_FAILED:
       return updateObject( state, {isCreateQuestionRequest:false});
        case actionTypes.CLEAR_FORM:
        return updateObject( state, {isCreateQuestionRequest:3});
        case actionTypes.GET_QUESTION_PROPERTIES_SUCCESS:
            return updateObject(state,{properties:[...action.data]});
        default: 
            return state;
    }
};

export default reducer;
