import React from 'react';
import ReactApexChart from "react-apexcharts";


class PieChart extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
      
        series: [props.percentage,100-props.percentage],
       
        options: {
                labels: [props.label, ''],
          chart: {
            width: 80,
            type: 'donut',
          },
          dataLabels: {
            enabled: false
          },
          fill: {
            type: 'gradient',
            colors: [props.colors.primary, '#f9f9fc' ]
          },
           
          responsive: [{
            breakpoint: 1680,
            options: {
              chart: {
                width: 100
              },
              legend: {
                position: 'bottom',
                show:false
              }
            }
          }]
        },
      
      
      };
    }

  

    render() {
      return (
        

  <div id="chart">
<ReactApexChart options={this.state.options} series={this.state.series} type="donut" width={380} />
</div>
      );
    }
}

export default PieChart;