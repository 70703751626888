import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

let initalParam={
    call_list: [],
    call_detail:{
        id:'',
        modules:[]
    }
 };
const initialState = {
    ...initalParam
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.PATIENT_CALL_SCHEDULE_REQUEST:
        case actionTypes.PATIENT_CALL_SCHEDULE_FAILED:
            return updateObject( state, {
                call_list: []
              });   
        case actionTypes.PATIENT_CALL_SCHEDULE_SUCCESS:    
      return updateObject( state, {
       ...action.data
        });
        case actionTypes.PATIENT_CALL_TASK_QUESTION_REQUEST:
        case actionTypes.PATIENT_CALL_TASK_QUESTION_FAILED:
            return updateObject(state,{
                call_detail:{
                    id:'',
                    modules:[]
                }});
        case actionTypes.PATIENT_CALL_TASK_QUESTION_SUCCESS:
            return updateObject(state,{
                call_detail:{...action.data}
            });
        default: 
            return state;
    }
};

export default reducer;
