import React from 'react';
import { toAbsoluteUrl } from "../../../../_metronic";
import { FormattedMessage } from "react-intl";

const Card1=props=>{
    const Background=toAbsoluteUrl(props.detail.icon);
    return (
    <div className="col-xl-12 p-0">
	<div className="card card-custom card-stretch gutter-b">
		<div className="card-body d-flex p-0">
			<div className="flex-grow-1 p-4 card-rounded bgi-no-repeat d-flex flex-column justify-content-center align-items-start" style={{backgroundColor: '#FFF4DE',backgroundPosition:`95% 50%`,backgroundSize: `auto ${props.detail.height}%`,backgroundImage: `url(${Background})`}}>
    <h4 className="text-danger font-weight-bolder m-0">{props.detail.title}</h4>
				<button onClick={props.clickAction} className="btn btn-danger font-weight-bold mt-3 py-2 px-6"><FormattedMessage id="PATIENT_PROFILE.BTN.OPEN" /></button>
			</div>
		</div>
	</div>
</div>
);
    }

export default Card1;