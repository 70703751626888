import { put,takeLatest} from 'redux-saga/effects';
import axios from 'axios';
import * as actions from '../actions';
import * as Constants from '../../shared/constants';
import Qs from 'qs'

export function* getStateSaga(action) {
    try {
      axios.defaults.withCredentials = true;
      const response = yield axios.get( process.env.REACT_APP_API_URL+'location/state');
       yield put(actions.getStateSuccess(response.data));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
      }
    }
  }

  export function* getCitySaga(action) {
    try {
      axios.defaults.withCredentials = true;
      const response = yield axios.get(`${process.env.REACT_APP_API_URL}location/${action.state_id}/city`);
       yield put(actions.getCitySuccess(response.data));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
      }
    }
  }

