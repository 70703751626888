export default {
    header: {
      self: {},
      items: [
        
        {
            title: "Counsellors",
            root: true,
            alignment: "left",
            toggle: "click",
            page: "admin/telecall/create-question",
            submenu: [
              {
                title: "Listing",
                icon: "flaticon-profile-1",
                page: "admin/question-list",
                submenu: [
                  {
                    title: "Question",
                    icon: "flaticon-profile-1",
                    page: "admin/question-list"
                  },
                  {
                    title: "Task",
                    icon: "flaticon-profile-1",
                    page: "admin/tasks-list"
                  },
                  {
                    title: "Module",
                    icon: "flaticon-profile-1",
                    page: "admin/modules-list"
                  },
                  {
                    title: "Call Schedules list",
                    icon: "flaticon-profile-1",
                    page: "admin/call-schedule-list"
                  }
                ]
              }, 
              {
                title: "Tele Call",
                icon: "flaticon-profile-1",
                page: "admin/telecall/create-question"
              },
             
              {
                title: "Create Call Schedule",
                icon: "fas fa-phone",
                page: "admin/create-call-schedule"
              },
              
            ]
        }
         
       
        
      ]
    },
    aside: {
      self: {},
      items: [
        {
          title: "Dashboard",
          root: true,
          icon: "flaticon2-architecture-and-city",
          page: "dashboard",
          translate: "MENU.DASHBOARD",
          bullet: "dot"
        }
      ]
    }
  };
  