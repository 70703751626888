import * as actionTypes from './actionTypes';

export const doctorsListRequest =  ()  => {
  return {
    type: actionTypes.DOCTORS_LIST_REQUEST,
  };
};
export const doctorsListSuccess =  (data)  => {
  return {
    type: actionTypes.DOCTORS_LIST_SUCCESS,
    data:data
  };
};
