import { delay } from 'redux-saga';
import { put, call } from 'redux-saga/effects';
import axios from 'axios';
import * as actions from '../actions';
import * as Constants from '../../shared/constants';
import Qs from 'qs'


  export function* getPatientCallScheduleSaga(action) {
    try {
      // console.log(action);
      const response = yield axios.get( process.env.REACT_APP_API_URL+'patient-call-schedule/'+action.id);
      yield put(actions.patientCallScheduleSuccess(response.data));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
        }
        yield put(actions.patientCallScheduleFailed(error));
    }
  }


  export function* getPatientCallTaskQuestionSaga(action) {
    try {
      // console.log(action);
      const response = yield axios.get( process.env.REACT_APP_API_URL+'patient-call-task-question/'+action.id);
      yield put(actions.patientCallTaskQuestionSuccess(response.data));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
        }
        yield put(actions.patientCallTaskQuestionFailed(error));
    }
  }


  export function* savePatientCallTaskQuestionSaga(action) {
    try {
      // console.log(action);
     
      axios.defaults.withCredentials = true;
      axios.defaults.headers.common['Content-Type'] = 'multipart/form-data; boundary=someArbitraryUniqueString';
      const response = yield axios.post( process.env.REACT_APP_API_URL+'save-call-task-question',action.data);
      yield put(actions.showSnackBarNotification({
        message:'Call Detail information save successfully',
        type:Constants.notificationType.SUCCESS,
        open:true
  }));
      yield put(actions.patientSaveCallTaskQuestionSuccess(response.data));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
        }
        yield put(actions.showSnackBarNotification({
          message:error.response.data.error.message !=undefined?error.response.data.error.message:'There is error in Sending message',
          type:Constants.notificationType.ERROR,
          open:true
    }));
        yield put(actions.patientSaveCallTaskQuestionFailed(error));
    }
  }
