import React from 'react';
import { toAbsoluteUrl } from "../../../../_metronic";
import { FormattedMessage } from "react-intl";
import {Link} from 'react-router-dom';
import { getDateOnly} from '../../../shared/utility';


const PersonalInfo1 = props=>{
let lastActive = getDateOnly(props.PatientInfo.last_active);  
    let array1=props.PatientInfo.code.filter(item=>item.selected==1);

    let picture=toAbsoluteUrl("/media/icons/007-boy-2.svg");
    if(props.PatientInfo.picture !=''){
        picture=props.PatientInfo.picture;
    }else if(props.PatientInfo.gender != 'Male'){
         picture=toAbsoluteUrl("/media/icons/014-girl-7.svg");
    }
    return (
    <div class="col-xl-12 p-0">
    <div class="card card-custom card-stretch gutter-b">
       <div class="card-body">
          <div class="d-flex justify-content-between flex-column pt-4 h-100">
             <div class="pb-5">
                <div class="d-flex flex-column flex-center">
                   <div class="symbol symbol-120 symbol-circle symbol-success overflow-hidden">
                      <span class="symbol-label">
                      {/* <img src={toAbsoluteUrl("/media/icons/007-boy-2.svg")} class="h-75 align-self-end" alt="" /> */}
                      <img src={picture} class="h-75 align-self-end" alt="" />
                     
                      </span>
                   </div>
                   <a href="#" class="card-title font-weight-bolder text-dark-75 text-hover-primary font-size-h4 m-0 pt-7 pb-1">
                   {props.PatientInfo.name}
                   </a>
                   <div class="font-weight-bold text-dark-50 font-size-sm pb-6">
                   {props.PatientInfo.phone}
                   </div>
                </div>
                <div class="pt-1">
                   {/* <p class="text-dark-75 font-weight-nirmal font-size-lg m-0 pb-7">
                      Outlines keep you honest. If poorly thought-out metaphors driving or create keep structure
                   </p> */}
                   <div class="d-flex align-items-center pb-9">
                      <div class="symbol symbol-45 symbol-light mr-4">
                         <span class="symbol-label">
                            <span class="svg-icon svg-icon-2x svg-icon-dark-50">
                               <svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                     <rect x="0" y="0" width="24" height="24"></rect>
                                     <rect fill="#000000" opacity="0.3" x="13" y="4" width="3" height="16" rx="1.5"></rect>
                                     <rect fill="#000000" x="8" y="9" width="3" height="11" rx="1.5"></rect>
                                     <rect fill="#000000" x="18" y="11" width="3" height="9" rx="1.5"></rect>
                                     <rect fill="#000000" x="3" y="13" width="3" height="7" rx="1.5"></rect>
                                  </g>
                               </svg>
                            </span>
                         </span>
                      </div>
                      <div class="d-flex flex-column flex-grow-1">
                <a href="#" class="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder">{array1.length>0?array1[0].code:''}</a>
                         <span class="text-muted font-weight-bold">-, {props.PatientInfo.onboarding_date}</span>
                      </div>
                      <span className="svg-icon svg-icon-md font-weight-bolder label label-xl label-light-success label-inline px-3 py-5"  data-action="1" title="Edit details">
            <svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "/>
                    <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"/>
                </g>
            </svg>
    </span>
                   </div>
                   <div class="d-flex align-items-center pb-9">
                      <div class="symbol symbol-45 symbol-light mr-4">
                         <span class="symbol-label">
                            <span class="svg-icon svg-icon-2x svg-icon-dark-50">
                               <svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                     <rect x="0" y="0" width="24" height="24"></rect>
                                     <rect fill="#000000" x="4" y="4" width="7" height="7" rx="1.5"></rect>
                                     <path d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z" fill="#000000" opacity="0.3"></path>
                                  </g>
                               </svg>
                            </span>
                         </span>
                      </div>
                      <div class="d-flex flex-column flex-grow-1">
                         <a href="#" class="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder"> {props.PatientInfo.gender}, {props.PatientInfo.age} years, {props.PatientInfo.weight} Kg</a>
                         <span class="text-muted font-weight-bold">Profile completion at 70%</span>
                      </div>
                        <Link to={`/edit-patient-profile/${props.PatientInfo.cust_app_id}`}>
                       <span className="svg-icon svg-icon-md font-weight-bolder label label-xl label-light-danger label-inline px-3 py-5"  data-action="1" title="Edit details">
            <svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "/>
                    <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"/>
                </g>
            </svg>
    </span>
    </Link>
                   </div>
                   <div class="d-flex align-items-center pb-9">
                      <div class="symbol symbol-45 symbol-light mr-4">
                         <span class="symbol-label">
                            <span class="svg-icon svg-icon-2x svg-icon-dark-50">
                               <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                     <rect x="0" y="0" width="24" height="24"></rect>
                                     <path d="M13,18.9450712 L13,20 L14,20 C15.1045695,20 16,20.8954305 16,22 L8,22 C8,20.8954305 8.8954305,20 10,20 L11,20 L11,18.9448245 C9.02872877,18.7261967 7.20827378,17.866394 5.79372555,16.5182701 L4.73856106,17.6741866 C4.36621808,18.0820826 3.73370941,18.110904 3.32581341,17.7385611 C2.9179174,17.3662181 2.88909597,16.7337094 3.26143894,16.3258134 L5.04940685,14.367122 C5.46150313,13.9156769 6.17860937,13.9363085 6.56406875,14.4106998 C7.88623094,16.037907 9.86320756,17 12,17 C15.8659932,17 19,13.8659932 19,10 C19,7.73468744 17.9175842,5.65198725 16.1214335,4.34123851 C15.6753081,4.01567657 15.5775721,3.39010038 15.903134,2.94397499 C16.228696,2.49784959 16.8542722,2.4001136 17.3003976,2.72567554 C19.6071362,4.40902808 21,7.08906798 21,10 C21,14.6325537 17.4999505,18.4476269 13,18.9450712 Z" fill="#000000" fill-rule="nonzero"></path>
                                     <circle fill="#000000" opacity="0.3" cx="12" cy="10" r="6"></circle>
                                  </g>
                               </svg>
                            </span>
                         </span>
                      </div>
                      <div class="d-flex flex-column flex-grow-1">
                  <a href="#" class="text-dark-75 text-hover-primary mb-1 font-size-lg font-weight-bolder">{props.PatientInfo.status}</a>
                         <span class="text-muted font-weight-bold">{props.PatientInfo.mode}{(lastActive !='')&& `, Last active on ${lastActive}`}</span>
                      </div>
                      <span className="svg-icon svg-icon-md font-weight-bolder label label-xl label-light-info label-inline py-5 min-w-45px"  data-action="1" title="Edit details">
            <svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "/>
                    <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"/>
                </g>
            </svg>
    </span>
                   </div>
                </div>
             </div>
             <div className="separator separator-solid mb-5"></div>
             <div class="d-flex flex-center" id="kt_sticky_toolbar_chat_toggler" data-toggle="tooltip" title="" data-placement="right" data-original-title="Chat Example">
               
                <a href={`tel:${props.PatientInfo.phone}`} className="btn btn-md btn-icon btn-light-facebook btn-pill mx-2">
                    <i className="fas fa-phone"></i>
                </a>
             </div>
          </div>
       </div>
    </div>
 </div>


);
}

export default PersonalInfo1;