export const completed=[
    {
       month:'JUNE 2020',
        items:[{
            id:1,
            day:'02',
            mon_name:'JUNE',
            status:'COMPLETED',
            title:'Call Agenda',
            description:"dummy content dfkldsjfdklsfjdslkfjsd fkldjsfkdsjfk dsfjdksfjsd kfj",
            show:false
        },{
            id:2,
            day:'03',
            mon_name:'JUNE',
            status:'COMPLETED',
            title:'Call Agenda',
            description:"dummy content dfkldsjfdklsfjdslkfjsd fkldjsfkdsjfk dsfjdksfjsd kfj",
            show:false
        }
    ]
    }
];
export const cancelled=[
    {
       month:'JUNE 2020',
        items:[{
            id:1,
            day:'04',
            mon_name:'JUNE',
            status:'CANCELLED',
            title:'Call Agenda',
            description:"dummy content dfkldsjfdklsfjdslkfjsd fkldjsfkdsjfk dsfjdksfjsd kfj",
            show:false
        },{
            id:2,
            day:'05',
            mon_name:'JUNE',
            status:'CANCELLED',
            title:'Call Agenda',
            description:"dummy content dfkldsjfdklsfjdslkfjsd fkldjsfkdsjfk dsfjdksfjsd kfj",
            show:false
        }
    ]
    },
    {
        month:'JULY 2020',
         items:[{
             id:1,
             day:'04',
             mon_name:'JULY',
             status:'CANCELLED',
             title:'Call Agenda',
             description:"dummy content dfkldsjfdklsfjdslkfjsd fkldjsfkdsjfk dsfjdksfjsd kfj",
             show:false
         },{
             id:2,
             day:'05',
             mon_name:'JULY',
             status:'CANCELLED',
             title:'Call Agenda',
             description:"dummy content dfkldsjfdklsfjdslkfjsd fkldjsfkdsjfk dsfjdksfjsd kfj",
             show:false
         }
     ]
     }
];

export const All=[
    {
       month:'JUNE 2020',
        items:[{
            id:1,
            day:'02',
            mon_name:'JUNE',
            status:'MISSED',
            title:'Call Agenda',
            description:"dummy content dfkldsjfdklsfjdslkfjsd fkldjsfkdsjfk dsfjdksfjsd kfj",
            show:false
        },{
            id:2,
            day:'02',
            mon_name:'JUNE',
            status:'RESCHEDULED',
            title:'Call Agenda',
            description:"dummy content dfkldsjfdklsfjdslkfjsd fkldjsfkdsjfk dsfjdksfjsd kfj",
            show:false
        },{
            id:2,
            day:'02',
            mon_name:'JUNE',
            status:'FOLLOW-UP',
            title:'Call Agenda',
            description:"dummy content dfkldsjfdklsfjdslkfjsd fkldjsfkdsjfk dsfjdksfjsd kfj",
            show:false
        }]
    },{

        month:'JULY 2020',
        items:[{
            id:1,
            day:'02',
            mon_name:'JULY',
            status:'UPCOMMING',
            title:'Call Agenda',
            description:"dummy content dfkldsjfdklsfjdslkfjsd fkldjsfkdsjfk dsfjdksfjsd kfj",
            show:false
        },{
            id:2,
            day:'02',
            mon_name:'JULY',
            status:'UPCOMMING',
            title:'Call Agenda',
            description:"dummy content dfkldsjfdklsfjdslkfjsd fkldjsfkdsjfk dsfjdksfjsd kfj",
            show:false
        }]
    }
];