import * as actionTypes from './actionTypes';


export const assignDietChartRequest=data=>{
    return {
      type: actionTypes.ASSIGN_DIET_CHART_REQUEST,
      data:data
    }
  };

  export const assignDietChartSuccess=data=>{
    return {
      type: actionTypes.ASSIGN_DIET_CHART_SUCCESS,
      data:data
    }
  };

  export const assignDietChartFailed=data=>{
    return {
      type: actionTypes.ASSIGN_DIET_CHART_FAILED,
      data:data
    }
  };