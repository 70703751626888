import * as actionTypes from './actionTypes';

export const showSnackBarNotification =  (data)  => {
  return {
    type: actionTypes.SNACKBAR_NOTIFICATION_SHOW,
    data:data
  };
};
export const hideSnackBarNotification =  (data)  => {
    return {
      type: actionTypes.SNACKBAR_NOTIFICATION_SHOW,
      data:data
    };
  };