import { put} from 'redux-saga/effects';
import axios from 'axios';
import * as actions from '../actions';
import * as Constants from '../../shared/constants';
import Qs from 'qs'

export function* initGetQuestionTaskSaga(action) {
    try {
      axios.defaults.withCredentials = true;
      const response = yield axios.get( process.env.REACT_APP_API_URL+'get-question-task');
      yield put(actions.getQuestionTaskSuccess(response.data));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
      }
      yield put(actions.getQuestionFailed(error));
    }
   
  }

  export function* initCreateModuleSaga(action) {
    try {
      axios.defaults.withCredentials = true;
      const response = yield axios.post( process.env.REACT_APP_API_URL+'create-module',action.data);

      yield put(actions.showSnackBarNotification({
        message:action.data.id==undefined?'Module Created successfully':'Module Updated Successfully',
        type:Constants.notificationType.SUCCESS,
        open:true
       }));
      yield put(actions.createModuleSuccess(response.data));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
      }
      yield put(actions.showSnackBarNotification({
        message:action.data.id==undefined?'There is error in Creating Module':'There is error in updating module',
        type:Constants.notificationType.ERROR,
        open:true
  }));
      yield put(actions.createModuleFailed(error));
    }
   
  }

  export function* initGetSearchModuleSaga(action) {

    try {
      axios.defaults.withCredentials = true;
      const response = yield axios.get( process.env.REACT_APP_API_URL+'search-modules');
      yield put(actions.getSearchModuleSuccess(response.data));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
      }
    }
   

  }
  
  
  export function* initGetAllModulesSaga(action) {
    try {
      axios.defaults.withCredentials = true;
    const response = yield axios.get( 
      `${process.env.REACT_APP_API_URL}getall-module`,
      {params:{...action.data},paramsSerializer: params => {
      return Qs.stringify(params)
    }});
	console.log(response);
      yield put(actions.getAllModulesSuccess(response.data));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
      }else{
        yield put(actions.getAllModulesFailed(error));
      }
    }
  }


  export function* initEditModuleSaga(action) {
    try {
      axios.defaults.withCredentials = true;
      const response = yield axios.get(`${process.env.REACT_APP_API_URL}edit-module/${action.id}`);
      yield put(actions.editModuleSuccess(response.data));
    } catch (error) {
  
      if(error.response.status==401){
        yield put(actions.logout());
      }
      yield put(actions.editModuleFailed(error));
    }
  }