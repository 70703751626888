import React from 'react';
import { toAbsoluteUrl } from "../../../../_metronic";
import { FormattedMessage } from "react-intl";
import {Link} from 'react-router-dom';

const ProgramModule=props=>{
    const Background=toAbsoluteUrl(props.icon);
	let editurl=`/patient-profile/${props.PatientInfo.cust_app_id}/${props.PatientInfo.programModules.id}`;
    return (
	<div className="card card-custom gutter-b mb-4">
		<div className="card-body d-flex p-0">
			<div className="flex-grow-1 p-4 card-rounded bgi-no-repeat d-flex flex-column justify-content-center align-items-start" style={{backgroundColor: '#FFF4DE',backgroundPosition:`95% 50%`,backgroundSize: `auto ${props.height}%`,backgroundImage: `url(${Background})`}}>
			<h4 className="text-danger font-weight-bolder m-0">{props.title}</h4>
				<Link to={editurl} className="btn btn-danger font-weight-bold mt-3 py-2 px-6"><FormattedMessage id="PATIENT_PROFILE.BTN.OPEN" /></Link>
			</div>
		</div>
	</div>
);
}

export default ProgramModule;