import React from 'react';
import './callDetail.css';
import HeaderBreadcrumb from '../../../components/UI/HeaderBreadcrumb';
import { toAbsoluteUrl } from "../../../../_metronic";
import Radio from '@material-ui/core/Radio';
import Input from '../../../components/UI/Input';
import * as Constants from '../../../shared/constants';
import clsx from "clsx";
import { updateObject,checkValidity,checkValidityType } from '../../../shared/utility';
import { connect } from 'react-redux';
import * as actionsMethods from '../../../store/actions/index';
import {DYNAMIC_FIELDS,DYNAMIC_TASK_FIELD} from './callDetailFields';
import { injectIntl } from "react-intl";

const breadcrumb=[
   {
    title: "Call Schedule",
    page: "call-schedule"
  }, {
    title: "Call Detail",
    page: "call-detail"
  }];



class CallDetail extends React.Component{
    constructor(props){
        super(props);
        this.state={
          isSubmitting:false,
          loadingButtonStyle:{
              paddingRight: "1rem"
            },
            loading:false,
          callDetailSection:[],
          formisValid:false,
      };
    }

   componentDidMount(){
     this.props.patientCallTaskQuestionReq(this.props.match.params.id)
   }
   componentDidUpdate(prevProps,prevState){
      const {patientCallSchedule}=this.props;
      this.geneteForm(patientCallSchedule,prevProps)
   }
   geneteForm=(currentProps,prevProps)=>{
    let callDetailSec=[...this.state.callDetailSection]
      if(currentProps !=prevProps.patientCallSchedule){
         if(currentProps.call_detail.modules.length>0){
         
           for(let i=0;i<currentProps.call_detail.modules.length;i++){
            let sectionObj={};
             for(let j=0;j<currentProps.call_detail.modules[i].module_sections.length;j++){
             sectionObj={
                name:currentProps.call_detail.modules[i].module_sections[j].name,
                dbId:currentProps.call_detail.modules[i].module_sections[j].id,
                dbModuleId:currentProps.call_detail.modules[i].module_sections[j].module_id
             } // sectionObj 
             for(let k=0;k<currentProps.call_detail.modules[i].module_sections[j].questions.length;k++){
                  let field=DYNAMIC_FIELDS(currentProps.call_detail.modules[i].module_sections[j].questions[k]);

                  sectionObj={
                    ...sectionObj,
                    data:{
                      ...sectionObj.data,
                      ['Q_'+currentProps.call_detail.modules[i].module_sections[j].questions[k].id]:field
                    }
                  }//sectionObj
             }// k
             for(let k=0;k<currentProps.call_detail.modules[i].module_sections[j].tasks.length;k++){
              let field=DYNAMIC_TASK_FIELD(currentProps.call_detail.modules[i].module_sections[j].tasks[k]);

              sectionObj={
                ...sectionObj,
                data:{
                  ...sectionObj.data,
                  ['T_'+currentProps.call_detail.modules[i].module_sections[j].tasks[k].id]:field
                }
              }//sectionObj
         }// k
             
             callDetailSec.push(sectionObj);
            } // j
          
           } // i
           this.setState({callDetailSection:callDetailSec});
         }
      }
   }


   
  inputChangedHandler = (event, inputIdentifier,value,arrayIndex) => {
      
        this.commonChangedHandler1(event, inputIdentifier,value,'callDetailSection',arrayIndex);

      }
    
      commonChangedHandler1 = (event, inputIdentifier,value,stateName,arrayIndex) => {
        let changeObject={...this.state[stateName][arrayIndex]['data'][inputIdentifier]};
        let valueObj=[];
        let   actualValue;
        if(changeObject.elementType=='multiple_autocomplete'){
          valueObj=value;
          actualValue=[];
              value.forEach(item=>{
                  actualValue.push(item.value);
              });
        }else if(changeObject.fieldType=='file'){
          valueObj=event.target.files[0];
          actualValue=event.target.value;
        }else if(changeObject.elementType=="mcheckbox"){
            actualValue=event.target.checked?1:0;
        }else{
     
   actualValue=event.target.value;
        }
   let connectedQuestion=0;
   let data={};
if(changeObject.elementType=='select'){
  let selecteOption=changeObject.elementConfig.options.filter(a=>a.value==actualValue);
    if( selecteOption[0].linked_question.length>0){
    connectedQuestion=1;
    for(let c=0;c<selecteOption[0].linked_question.length;c++){
      let field=DYNAMIC_FIELDS(selecteOption[0].linked_question[c],6);
      data={
        ...data,
        [selecteOption[0].linked_question[c].id]:field
      }
    }
  }
}
             const updatedFormElement = updateObject(this.state[stateName][arrayIndex]['data'][inputIdentifier], {
                 value: actualValue,
                 valueObj:valueObj,
                     valid: checkValidity(actualValue, this.state[stateName][arrayIndex]['data'][inputIdentifier].validation),
                     touched: true,
                     error:checkValidityType(actualValue, this.state[stateName][arrayIndex]['data'][inputIdentifier].validation)==''?'':this.state[stateName][arrayIndex]['data'][inputIdentifier].errorMessage[checkValidityType(actualValue, this.state[stateName][arrayIndex]['data'][inputIdentifier].validation)],
                     data:data,
                     connectedQuestion:connectedQuestion
               });
               const updatedForm1 = updateObject(this.state[stateName][arrayIndex]['data'], {
             [inputIdentifier]: updatedFormElement 
               });
               const updatedForm2 = updateObject(this.state[stateName][arrayIndex], {
                ['data']: updatedForm1 
      });
      const updatedForm3= updateObject(this.state[stateName], {
        [arrayIndex]: updatedForm2 
});
      let formisValid=true;
   
      let Vname=`${stateName}FormIsValid`;
          this.setState({[stateName]: Object.values(updatedForm3),[Vname]: formisValid});
         }
         subInputChangedHandler = (event, inputIdentifier,value,arrayIndex,elementIndex) => {
          this.commonChangedHandler2(event, inputIdentifier,value,'callDetailSection',arrayIndex,elementIndex);
        }

        
        commonChangedHandler2 = (event, inputIdentifier,value,stateName,arrayIndex,elementIndex) => {
        let valueObj=[];
        let   actualValue;
     
        if(this.state[stateName][arrayIndex]['data'][inputIdentifier]['data'][elementIndex].elementType=='multiple_autocomplete'){
          valueObj=value;
          actualValue=[];
              value.forEach(item=>{
                  actualValue.push(item.value);
              });
        }else if(this.state[stateName][arrayIndex]['data'][inputIdentifier]['data'][elementIndex].fieldType=='file'){
          valueObj=event.target.files[0];
          actualValue=event.target.value;
          }else if(this.state[stateName][arrayIndex]['data'][inputIdentifier]['data'][elementIndex].elementType=="mcheckbox"){
            actualValue=event.target.checked?1:0;
        }else{
     
   actualValue=event.target.value;
        }

             const updatedFormElement = updateObject(this.state[stateName][arrayIndex]['data'][inputIdentifier]['data'][elementIndex], {
                 value: actualValue,
                 valueObj:valueObj,
                     valid: checkValidity(actualValue, this.state[stateName][arrayIndex]['data'][inputIdentifier]['data'][elementIndex].validation),
                     touched: true,
                     error:checkValidityType(actualValue, this.state[stateName][arrayIndex]['data'][inputIdentifier]['data'][elementIndex].validation)==''?'':this.state[stateName][arrayIndex]['data'][inputIdentifier]['data'][elementIndex].errorMessage[checkValidityType(actualValue, this.state[stateName][arrayIndex]['data'][inputIdentifier]['data'][elementIndex].validation)],
               });

               const updatedFormn1= updateObject(this.state[stateName][arrayIndex]['data'][inputIdentifier]['data'], {
                [elementIndex]: updatedFormElement 
                  });

                  const updatedFormn2= updateObject(this.state[stateName][arrayIndex]['data'][inputIdentifier], {
                    ['data']: updatedFormn1 
                      });

               const updatedForm1 = updateObject(this.state[stateName][arrayIndex]['data'], {
             [inputIdentifier]: updatedFormn2 
               });
               const updatedForm2 = updateObject(this.state[stateName][arrayIndex], {
                ['data']: updatedForm1 
      });
      const updatedForm3= updateObject(this.state[stateName], {
        [arrayIndex]: updatedForm2 
});
      let formisValid=true;
   
      let Vname=`${stateName}FormIsValid`;
          this.setState({[stateName]: Object.values(updatedForm3),[Vname]: formisValid});
         }

arrayConvertion=(data)=>{
   let dataArray=[];
for (let key in data) {
    dataArray.push({
        id: key,
        config: data[key]
    });
}
   return dataArray;
}
handleSubmit= (e)=>{
e.preventDefault();

if(!this.isFormValid()){
    this.props.showSnackBarNotification({
        message:'Please Fill required fields',
        type:Constants.notificationType.ERROR,
        open:true
      });
       return;
     
}
let section=[];
var formData = new FormData();
formData.append('call_id',this.props.match.params.id);
formData.append('cust_app_id',this.props.match.params.patient_id);
for (let property in this.state.callDetailSection) {
  console.log(property);
  let question=[];
  let z=0;
  let y=0;
  formData.append(`section[${property}][id]`,this.state.callDetailSection[property].dbId);
  for(let p in this.state.callDetailSection[property].data){
    let ka=p.split('_');
    if(ka[0]=='Q'){
    let proprty={};
    if(this.state.callDetailSection[property].data[p].properties.length>0){
      for(let q=0;q<this.state.callDetailSection[property].data[p].properties;q++){

        let key=this.state.callDetailSection[property].data[p].properties[q].master_values;
        formData.append(`section[${property}][questions][${z}][properties][${key}]`,this.state.callDetailSection[property].data[p].properties[q].value);
      }
    }
    formData.append(`section[${property}][questions][${z}][id]`,this.state.callDetailSection[property].data[p].dbId);
    formData.append(`section[${property}][questions][${z}][answer]`,this.state.callDetailSection[property].data[p].fieldType=='file'? this.state.callDetailSection[property].data[p].valueObj :this.state.callDetailSection[property].data[p].value);
    formData.append(`section[${property}][questions][${z}][type]`,this.state.callDetailSection[property].data[p].fieldType);
    formData.append(`section[${property}][questions][${z}][mandatory]`,this.state.callDetailSection[property].data[p].mandatory);
    z=z+1;
  }else{
    formData.append(`section[${property}][tasks][${y}][id]`,this.state.callDetailSection[property].data[p].dbId);
    formData.append(`section[${property}][tasks][${y}][answer]`,this.state.callDetailSection[property].data[p].value==1?1:0);
    formData.append(`section[${property}][tasks][${y}][mandatory]`,this.state.callDetailSection[property].data[p].mandatory);
    y=y+1;
  }
}
 
}
this.props.patientSaveCallTaskQuestionReq(formData);
}
isFormValid=()=>{
let valid=true;
for (let one in this.state.callDetailSection){
  for(let two in this.state.callDetailSection[one].data){
    if(this.state.callDetailSection[one].data[two].valid==false){
      valid=false;
      break;
    }
    if(this.state.callDetailSection[one].data[two].connectedQuestion==1){
      for(let three in this.state.callDetailSection[one].data[two].data){
        if(this.state.callDetailSection[one].data[two].data[three].valid==false){
          valid=false;
          break;
        }
      }
    }
  }
}
 return valid;
}


    render(){
      breadcrumb[0]={
        title: "Call Schedule",
        page: `/call-schedule/${this.props.match.params.patient_id}`
      };
      breadcrumb[1]={
        title: "Call Detail",
        page: `/call-detail/${this.props.match.params.patient_id}/${this.props.match.params.id}`
      };
      let letSection=this.state.callDetailSection
let form='';
if(letSection.length>0){
form = (
  <form className="kt-form" id="kt_form" onSubmit={e=>this.handleSubmit(e)}>

<div className="card card-custom card-stretch gutter-b mt-3">
     {letSection.map((item,i)=>{
           const sectionArray =this.arrayConvertion(item.data);
      return (<><div className="card-header header-brown">
		<div className="card-toolbar">
        <Radio />
            <h3>{item.name}</h3>
		</div>
		<div className="card-toolbar">  
        {/* <input type="date" className="form-control" placeholder="Search" />        */}
		</div>
    </div>
    <div className="card-body">
        <div className="kt-wizard-v1__form row">
        {sectionArray.map((formElement,index) => {
          let sectionArray1='';
          if(formElement.config.connectedQuestion==1){
             sectionArray1 =this.arrayConvertion(formElement.config.data);
          }
            return (<><Input 
                key={index}
                isradio={formElement.config.isradio==1?1:0}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.isProgram==1?this.props.codes:formElement.config.elementConfig}
                col={formElement.config.col}
                label={formElement.config.label}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                fullRow={formElement.config.fullRow}
                heading={formElement.config.heading}
                divider={formElement.config.divider}
                leftText={formElement.config.leftText}
                error={formElement.config.error}
                sendOtp={formElement.config.otp_resend==true?true:false}
                skipOtp={formElement.config.skip_otp==true?true:false}
                sendOtpClick={e=>this.handleResendOtp(e)}
                skipOtpClick={e=>this.handleSkipOtp(e)}
                changed={(event,value) => this.inputChangedHandler(event, formElement.id,value,i)}
                sendOtpLoading={this.props.sendOtpLoading}
                isOtpMatch={formElement.config.isOtpMatch==true?true:false}
                successMsg={formElement.config.successMsg}
                />
                {sectionArray1 !=''? sectionArray1.map((formElement1,index1) => {
                  return (<Input 
                    key={index1}
                    isradio={formElement1.config.isradio==1?1:0}
                    elementType={formElement1.config.elementType}
                    elementConfig={formElement1.config.isProgram==1?this.props.codes:formElement1.config.elementConfig}
                    col={formElement1.config.col}
                    label={formElement1.config.label}
                    value={formElement1.config.value}
                    invalid={!formElement1.config.valid}
                    shouldValidate={formElement1.config.validation}
                    touched={formElement1.config.touched}
                    fullRow={formElement1.config.fullRow}
                    heading={formElement1.config.heading}
                    divider={formElement1.config.divider}
                    leftText={formElement1.config.leftText}
                    error={formElement1.config.error}
                    changed={(event,value) => this.subInputChangedHandler(event, formElement.id,value,i,formElement1.id)}
                    sendOtpLoading={this.props.sendOtpLoading}
                    isOtpMatch={formElement1.config.isOtpMatch==true?true:false}
                    successMsg={formElement1.config.successMsg}
                    />);
                }):''}
        </>);
        })}
        </div>
        </div> 
        </>);
     })
    }
        <div className="kt-form__actions p-5">
        <div className="flex-grow-1">
        </div>
      <div >
      <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={this.props.isSubmitting}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                      }
                    )}`}
                    style={this.state.loadingButtonStyle}
                  >
                      FINISH
                  </button>
                </div>
                </div>
                </div>
                </form>
); }

    return (
        <>
         <HeaderBreadcrumb breadcrumb={breadcrumb} title="Call Detail" />
								<div className="row">
									<div className="col-md-4">
										<div className="card card-custom card-stretch gutter-b">
											<div className="card-body pb-0 mt-3">
												<div className="d-flex">
													<div className="d-flex flex-column flex-grow-1 align-self-end gutter-b">
														<a href="#" className="card-title font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-primary" >Keerthana M</a>
														<span className="font-weight-bold text-muted font-size-lg">24 Years, Male</span>
													</div>
													<img src={toAbsoluteUrl('/media/icons/004-boy-1.svg')} alt="" style={{width: "8rem"}}/>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-4">
										<div className="card card-custom card-stretch gutter-b">
											<div className="card-body pb-0 mt-3">
												<div className="d-flex">
													<div className="d-flex flex-column flex-grow-1 align-self-end gutter-b" >
														<a href="#"  className="card-title font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-primary">Program Name</a>
														<span className="font-weight-bold text-muted font-size-lg" style={{visibility:'hidden'}}>Rising Stars</span>
													</div>
                                                    <img src={toAbsoluteUrl('/media/icons/004-boy-1.svg')} alt="" style={{width: "8rem",visibility:'hidden'}}/>

												</div>
											</div>
										</div>
									</div>
									<div className="col-md-4">
										<div className="card card-custom card-stretch gutter-b">
											<div className="card-body pb-0 mt-3">
												<div className="d-flex">
													<div className="d-flex flex-column flex-grow-1 align-self-end gutter-b">
														<a href="#" className="card-title font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-primary">Height</a>
														<span className="font-weight-bold text-muted font-size-lg">162 cms</span>
                                                        
													</div>
                                                    <div className="d-flex flex-column flex-grow-1 align-self-end gutter-b">
														
                                                        <a href="#" className="card-title font-weight-bolder text-dark-75 font-size-h5 mb-2 text-hover-primary">Weight</a>
														<span className="font-weight-bold text-muted font-size-lg">70 Kgs</span>
													</div>
                                                  


												</div>
											</div>
										</div>
									</div>
<div className="col-md-12">



  {form}   
  
  


    </div>
    </div>
</>
    );
    }
}


const mapStateToProps= state=>{
  return {
    patientCallSchedule:state.patientCallSchedule,
     patientProfileDetail:state.patientProfile

  }
}
const mapDispatchToProps = dispatch => {
  return {
     patientCallTaskQuestionReq: id => dispatch(actionsMethods.patientCallTaskQuestionRequest(id)),
     patientSaveCallTaskQuestionReq: data => dispatch(actionsMethods.patientSaveCallTaskQuestionRequest(data)),
     showSnackBarNotification:data=>dispatch(actionsMethods.showSnackBarNotification(data))

}
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CallDetail));
