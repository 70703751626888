


import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    id:'',
    name: '',
    sections:[],
    redirect: false

};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.EDIT_MODULE_REQUEST:
            return updateObject(state,{
                id:'',
                name: '',
                sections:[],
                redirect: false           
            });
        case actionTypes.EDIT_MODULE_SUCCESS:
      return updateObject( state, {
        ...action.data,
        redirect: false
        });
        case actionTypes.EDIT_MODULE_FAILED:
            return updateObject(state,{
                id:'',
                name: '',
                sections:[],
                redirect: true           
            });
        default: 
            return state;
    }
};

export default reducer;
