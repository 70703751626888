export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const checkValidity = ( value, rules ) => {
    let isValid = true;
    if ( !rules ) {
        return true;
    }

    if ( rules.required ) {
        if(Array.isArray(value)){
            isValid=value.length>0?true:false;
        }else{
        isValid = value.trim() !== '' && isValid;
        }
    }

    if ( rules.minLength && value !=''  ) {
        isValid = value.length >= rules.minLength && isValid
    }

    if ( rules.maxLength && value !=''  ) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if ( rules.isEmail && value !='' ) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test( value ) && isValid
    }

    if ( rules.isNumeric && value !='' ) {
        const pattern = /^\d+$/;
        isValid = pattern.test( value ) && isValid
    }
    if ( rules.maxValue && value !=''  ) {
        isValid = value <= rules.maxValue && isValid
    }
    if ( rules.minValue && value !=''  ) {
        isValid = value >= rules.minValue && isValid
    }
    if(rules.isMobile && value !=''){
        var pattern = /^[0]?[789]\d{9}$/
if (pattern.test(value)) {
    isValid=true;
}else{
      if(value.split(value.slice(-10))[0]=='91' || value.split(value.slice(-10))[0]=='+91' ){
        isValid=true; 
      }else{
      isValid=false;
      }

}

    }
    return isValid;
}
export const checkValidityType = ( value, rules ) => {
    let isValid = true;
    if ( !rules ) {
        return '';
    }

    if ( rules.required ) {
        if(Array.isArray(value)){
            isValid=value.length>0?true:false;
        }else{
        isValid = value.trim() !== '' && isValid;
        }
        if( !isValid){
            return 'required';
        }
    }

    if ( rules.minLength && value !=''  ) {
        isValid = value.length >= rules.minLength && isValid
        if( !isValid){
            return 'minLength';
        }
    }

    if ( rules.maxLength && value !='' ) {
        isValid = value.length <= rules.maxLength && isValid
        if( !isValid){
            return 'maxLength';
        }
    }

    if ( rules.isEmail && value !='' ) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test( value ) && isValid
        if( !isValid){
            return 'isEmail';
        }
    }

    if ( rules.isNumeric && value !='' ) {
        const pattern = /^\d+$/;
        isValid = pattern.test( value ) && isValid
        if( !isValid){
            return 'isNumeric';
        }
    }
    if ( rules.maxValue && value !='' ) {
        isValid = value <= rules.maxValue && isValid
        if( !isValid){
            return 'maxValue';
        }
    }
    if ( rules.minValue && value !='' ) {
        isValid = value >= rules.minValue && isValid
        if( !isValid){
            return 'minValue';
        }
    }

    if(rules.isMobile && value !=''){
        var pattern = /^[0]?[789]\d{9}$/
if (pattern.test(value)) {
    isValid=true;
}else{
    console.log(value.split(value.slice(-10))[0]);
      if(value.split(value.slice(-10))[0]=='91' || value.split(value.slice(-10))[0]=='+91' ){
        isValid=true; 
      }else{
      isValid=false;
      }
      if( !isValid){
        return 'isMobile';
    }

}

    }

    return isValid;
}

export const convertToOptionObject=data=>{
    let data1=[];
    if(data.length>0){
        for(let i=0;i<data.length;i++){
        data1.push({value:data[i].id,displayValue:data[i].name});
        }
    }

    return data1;
}

export function insertKey(key,value,obj,pos){
    return Object.keys(obj).reduce((ac,a,i) => {
      if(i === pos) ac[key] = value;
      ac[a] = obj[a]; 
      return ac;
    },{})
  }

  export function removeKtdatatableCache(){
    localStorage.removeItem("kt_datatable-1-meta");
    localStorage.removeItem("child_data_ajax_5-2-meta");
  }

  export function getDateOnly(d){
   if(d=='' || d=='-'){
       return '';
   }
    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  let nowDate = new Date(d);
  let lastActive = nowDate.getDate()+' '+monthNames[nowDate.getMonth()]+' '+nowDate.getFullYear();  
  return lastActive;
  }

  export function getDay(d){
   
   let nowDate = new Date(d);
   let lastActive = nowDate.getDate();  
   return lastActive;
   }
   
  export function getMonth(d){
     const monthNames = ["January", "February", "March", "April", "May", "June",
     "July", "August", "September", "October", "November", "December"
   ];
   let nowDate = new Date(d);
   let lastActive = monthNames[nowDate.getMonth()];  
   return lastActive;
   }