import React from 'react';
import Input from '../../../../components/UI/Input';
import { updateObject,checkValidity,checkValidityType } from '../../../../shared/utility';
import {createQuestion,otherFields,isconnected} from './Fields';
import '../teleCallCreate.css';
import { connect } from 'react-redux';
import * as actionsMethods from '../../../../store/actions/index';
import * as Constants from '../../../../shared/constants';
import clsx from "clsx";
import {DynamicField} from './DynamicField';
import { Redirect } from 'react-router';
import { FormattedMessage,injectIntl } from "react-intl";

 class EditQuestion extends React.Component {
    constructor(props){
        super(props);
        this.state={
            isSubmitting:false,
            loadingButtonStyle:{
                paddingRight: "1rem"
              },
            loading:false,
            Question:{...createQuestion},
            options:{},
            nestedOptions:{},
            otherFields:{...otherFields},
            dynamicFields:{},
            formIsValid:false,
            QuestionFormIsValid:false,
            optionsFormIsValid:true,
            otherFieldsFormIsValid:true,
            nestedOptionsFormIsValid:true,
            isFormReset:false,
            redirect: false
           };
    }   
    componentDidMount(){
        this.props.getQuestionType();
        this.props.getConnectedQuestions();
        this.props.editQuestion({id:this.props.match.params.id});
    }
    resetForm=async()=>{
       await this.setState({
        isSubmitting:false,
        loadingButtonStyle:{
            paddingRight: "1rem"
          },
        loading:false,
            Question:{...createQuestion},
            options:{},
            nestedOptions:{},
            otherFields:{...otherFields},
            dynamicFields:{},
            formIsValid:false,
            QuestionFormIsValid:false,
            optionsFormIsValid:true,
            otherFieldsFormIsValid:true,
            nestedOptionsFormIsValid:true,
            isFormReset:false
        });
        this.props.getQuestionType();
        this.props.getConnectedQuestions();

    }
    componentDidUpdate(prevProps, prevState) {
        const {questionType,functionToCall,connectedQuestions,isCreateRequest,units,properties,editQuestionData} = this.props;
        if(properties !=prevProps.properties){  
        let DynamicFieldObj={}
        for(let i=0;i<properties.length;i++){
            let abc=editQuestionData.properties.filter((item,intt)=>{
              return   parseInt(item.property_id)===parseInt(properties[i].id)
            });
            DynamicFieldObj ={...DynamicFieldObj,[properties[i].id]:{...DynamicField(properties[i],abc)}};
        }
        this.setState({dynamicFields:DynamicFieldObj});
        }
        if(isCreateRequest==3 && this.state.isFormReset){//
       //   this.resetForm()
        }else if(isCreateRequest !=prevProps.isCreateRequest && isCreateRequest == false){
            this.setState({
                loading:false,
                loadingButtonStyle: {paddingRight: "1rem"},
                isSubmitting:false
              });
        }
    //     if(connectedQuestions !=prevProps.connectedQuestions){
        //this.addOption();
    //    }
        if(functionToCall !=prevProps.functionToCall){
            let updatedFormElement = updateObject(this.state.otherFields['function_to_call'], {
                elementConfig:functionToCall
            });
            const updatedOtherFieldForm = updateObject(this.state.otherFields, {
              ['function_to_call']: updatedFormElement
          });
          this.setState({otherFields : updatedOtherFieldForm});
        }
        if(questionType != prevProps.questionType){
          let updatedFormElement = updateObject(this.state.Question['type'], {
            elementConfig:questionType
        });
      
            let updatedFormElement1 = updateObject(this.state.Question['units'], {
                elementConfig:units
            });
          const updatedOrderForm = updateObject(this.state.Question, {
            ['type']: updatedFormElement,
            ['units']: updatedFormElement1
        });
          this.setState({Question : updatedOrderForm});
        }
        if(editQuestionData !=prevProps.editQuestionData){
            if(editQuestionData.redirect==true){
                this.setState({redirect:true});
              }
            this.props.getQuestionProReq(editQuestionData.type);
            let type = updateObject(this.state.Question['type'], {
                value:editQuestionData.type,
                valid:true
            });
            let placeholder = updateObject(this.state.Question['placeholder'], {
                value:editQuestionData.placeholder,
                valid:true
            });
            let question = updateObject(this.state.Question['question'], {
                value:editQuestionData.question,
                valid:true
            });
            let unitsIds=[];
            let selectedUnits=[];
            const selectedQuestionType=questionType.options.filter(q=>q.value==editQuestionData.type);
            const seld={...selectedQuestionType[0]};
            if(seld.has_unit==1){
            for(let i=0;i<editQuestionData.units.length;i++){
                unitsIds.push(editQuestionData.units[i].unit_id);
            }
             selectedUnits=units.options.filter(item=>unitsIds.indexOf(item.value) !=-1);
            }
            let updateUnites = updateObject(this.state.Question['units'], {
                value:unitsIds,
                valueObj:selectedUnits,
                valid:true
            });
            let funcToCallIds=[];
            for(let i=0;i<editQuestionData.function_to_call.length;i++){
                funcToCallIds.push(parseInt(editQuestionData.function_to_call[i].function));
            }
            let selectedFunction=functionToCall.options.filter(item=>funcToCallIds.indexOf(item.value) !=-1);
            let updateFunctionToCall = updateObject(this.state.otherFields['function_to_call'], {
                value:funcToCallIds,
                valueObj:selectedFunction,
                valid:true
            });
            let isConnected={};
            if(this.isShowAddOptionBtn(editQuestionData.type)){
            
        
                isConnected={is_connected:{...isconnected,
                value:editQuestionData.is_connected}};
                };
            const updatedOrderForm = updateObject(this.state.Question, {
                ['type']: type,
                ['placeholder']: placeholder,
                ['question']:question,
                ['units']:updateUnites,
                ...isConnected
            });
            if(seld.has_unit==0){
            delete updatedOrderForm.units;
           }
            let mandatory = updateObject(this.state.otherFields['mandatory'], {
                value:editQuestionData.mandatory,
                valid:true
            });
            let date_specific = updateObject(this.state.otherFields['date_specific'], {
                value:editQuestionData.date_specific?editQuestionData.date_specific:0,
                valid:true
            });
            const otherFieldFormElement = updateObject(this.state.otherFields, {
              ['mandatory']: mandatory,
              ['date_specific']:date_specific,
              ['function_to_call']:updateFunctionToCall
          });

         this.updateOption(editQuestionData);
              this.setState({Question : updatedOrderForm,otherFields:otherFieldFormElement,QuestionFormIsValid:true,otherFieldsFormIsValid:true});
        }
      
      }

      updateOption= async editQuestionData=>{
        for(let i=0;i<editQuestionData.options.length;i++){
          await  this.initAddOption(editQuestionData.type,editQuestionData.options[i]);
         }
      }

    inputChangedHandler = (event, inputIdentifier,value) => {
        if(inputIdentifier=='type'){
            this.props.getQuestionProReq(event.target.value);
        }
        this.commonChangedHandler(event, inputIdentifier,value,'Question');

      }
      optionChangedHandler = (event, inputIdentifier,value) => {
        this.commonChangedHandler(event, inputIdentifier,value,'options');
      }
      otherChangedHandler = (event, inputIdentifier,value) => {
        this.commonChangedHandler(event, inputIdentifier,value,'otherFields');
      }
      nOptionChangedHandler = (event, inputIdentifier,value) => {
        this.commonChangedHandler(event, inputIdentifier,value,'nestedOptions');
      }
      dynamicChangedHandler = (event, inputIdentifier,value) => {
        this.commonChangedHandler(event, inputIdentifier,value,'dynamicFields');
      }
      commonChangedHandler = async(event, inputIdentifier,value,stateName) => {
        let valueObj=[];
        let actualValue;
         if(inputIdentifier=='function_to_call' || inputIdentifier=='units' || this.state[stateName][inputIdentifier].elementType=='multiple_autocomplete'){
           valueObj=value;
           actualValue=[];
               value.forEach(item=>{
                   actualValue.push(item.value);
               });
         }else{
            actualValue=value==undefined?event.target.value:value.value;
            actualValue= (actualValue==undefined) ?event.target.checked:actualValue;
            if(inputIdentifier=='mandatory' || inputIdentifier=='date_specific'){
                actualValue=event.target.checked?1:0;
            }
         }
        const updatedFormElement = updateObject(this.state[stateName][inputIdentifier], {
                value: actualValue,
                 valueObj:valueObj,
                valid: checkValidity(actualValue, this.state[stateName][inputIdentifier].validation),
                touched: true,
                error:checkValidityType(actualValue, this.state[stateName][inputIdentifier].validation)==''?'':this.state[stateName][inputIdentifier].errorMessage[checkValidityType(actualValue, this.state[stateName][inputIdentifier].validation)],
          });
          const updatedOrderForm = updateObject(this.state[stateName], {
              [inputIdentifier]: updatedFormElement
          });
          let formisValid=true;
          for (let inputIdentifier in updatedOrderForm) {
              formisValid= updatedOrderForm[inputIdentifier].valid &&formisValid ;
          }
          let Vname=`${stateName}FormIsValid`;
         await this.setState({[stateName]: updatedOrderForm,  [Vname]: formisValid});
          if(inputIdentifier=='type'){
            this.addOption(2);
          }
    }
    getQuestionTypeById=id=>{
        let typeObj=this.props.questionType.options.filter(item=>item.value==id);
        if(typeObj.length>0){
            return typeObj[0].type;
        }
        return 'text';
    }
    isShowAddOptionBtn= (type=undefined) =>{
        let id=((type==undefined)?this.state.Question['type']['value']:type);
       
        let typeObj=this.props.questionType.options.filter(item=>item.value==id);
       
        if(typeObj.length>0){
            switch( typeObj[0].type){
                case "radio":
                case "select2": 
                case "checkbox":
                case "select":
                    return true;
                default:
                    return false;
            }
        }
        return false;
    }

    addOption=(from=1)=>{
        let optionF=optionField(this.getQuestionTypeById(this.state.Question['type']['value']));
       
        if(optionF==''){
            this.setState({options: {},nestedOptions:{}
            });
            let stateObj= {...this.state.Question};
            delete stateObj.is_connected;
 
            this.setState({Question:stateObj});
            return 
        }
        let  Noption={}
    //   if(this.state.Question.connectedQuestion.value==true){   
          Noption=NestedOptionField(this.props.connectedQuestions,this.state.Question['type']['value']);
    //   }
    if(from==2){
  
        if(this.isShowAddOptionBtn()){
            
            let updatedObj=updateObject(this.state.Question, {
                is_connected:{...isconnected}
            });
            this.setState({Question:updatedObj});

        }
        this.setState({options: {
            ['option_0'
        ]: optionF
        },nestedOptions:{
            ['option_0'
        ]: Noption
        }});
    }else{
      let optionlenght=Object.keys(this.state.options).length;
        const updatedOrderForm = updateObject(this.state.options, {
            ['option_'+optionlenght
        ]: optionF
        });
        const updatedNestedField = updateObject(this.state.nestedOptions, {
            ['option_'+optionlenght
        ]: Noption
        });
        this.setState({options: updatedOrderForm,nestedOptions:updatedNestedField});
    }
    }

    initAddOption=async(type,item)=>{
      

        
        let optionF=optionField(this.getQuestionTypeById(type),item.name,item.id);
        if(optionF==''){
            this.setState({options: {},nestedOptions:{}
            });
            let stateObj= {...this.state.Question};
            delete stateObj.is_connected;
 
            this.setState({Question:stateObj});
            return 
        }

        let  Noption={}
        let NestedSelectedIds=[];
        if(item.links!=undefined){
            for(let a in item.links){
                NestedSelectedIds.push(parseInt(item.links[a].child_question_id));
            }
        }
    //   if(this.state.Question.connectedQuestion.value==true){   
          Noption=NestedOptionField(this.props.connectedQuestions,type,NestedSelectedIds);
    //   }
  
      let optionlenght=Object.keys(this.state.options).length;
        const updatedOrderForm = updateObject(this.state.options, {
            ['option_'+optionlenght
        ]: optionF
        });
        const updatedNestedField = updateObject(this.state.nestedOptions, {
            ['option_'+optionlenght
        ]: Noption
        });
        this.setState({options: updatedOrderForm,nestedOptions:updatedNestedField});
    }
    
    deleteOption=(event,inputIdentifier) => {
       let stateOptionObject= {...this.state.options};
       delete stateOptionObject[inputIdentifier];
       this.setState({options: stateOptionObject});

    }
    handleSubmit= (e)=>{
        e.preventDefault();
        if(!this.isFormValid()){
            this.props.showSnackBarNotification({
                message:'Please Fill required fields',
                type:Constants.notificationType.ERROR,
                open:true
              });
              return;
        }
        const formData = {};
        let units=[]
        for (let formElementIdentifier in this.state.Question) {
            if(formElementIdentifier=='units'){
                for (let i=0;i<this.state.Question[formElementIdentifier].value.length;i++){
                    units.push({
                        priority:i+1,
                        unit_id:this.state.Question[formElementIdentifier].value[i],
                        id:''
                    });
                }
            }else{
            formData[formElementIdentifier] = this.state.Question[formElementIdentifier].value;
            }
            
        }
        if(!formData.hasOwnProperty('is_connected')){
            formData.is_connected=false;
        }
        for (let formElementIdentifier in this.state.otherFields) {
            formData[formElementIdentifier] = this.state.otherFields[formElementIdentifier].value;
        }
    let    formData1=[];
    
        for (let formElementIdentifier in this.state.options) {
            if(this.state.Question.is_connected.value==true){
                if(this.state.nestedOptions[formElementIdentifier].value.length==0){
                    formData1.push({
                        option:this.state.options[formElementIdentifier].value,
                        id:this.state.options[formElementIdentifier].databaseId
                    });
                }else{
                    let LinksArray=[];
                    for(let z=0;z<this.state.nestedOptions[formElementIdentifier].value.length;z++){
                        LinksArray.push({child_question_id:this.state.nestedOptions[formElementIdentifier].value[z]});
                    }
                formData1.push({
                    option:this.state.options[formElementIdentifier].value,
                    id:this.state.options[formElementIdentifier].databaseId,
                    links:LinksArray
                });
            }
            }else{
            formData1.push({option:this.state.options[formElementIdentifier].value,
            id:this.state.options[formElementIdentifier].databaseId});
            }
        }
        let properties=[];
        for (let formElementIdentifier in this.state.dynamicFields) {
            let VALUE=this.state.dynamicFields[formElementIdentifier].value;
            properties.push({
                property_id:formElementIdentifier,
                    value:Array.isArray(VALUE)?VALUE.join():VALUE,
                    id:''
                });
        }
        let updateObject={...formData,
            option:formData1,
            units:units,
            program_specific:0,
            properties:properties,
            id:this.props.match.params.id};
            this.setState({loadingButtonStyle:{ paddingRight: "3.5rem" },loading:true,isSubmitting:true});
            this.setState({isFormReset:true});
        this.props.createQuestion(updateObject);
    }
   isFormValid=()=>{
    if(this.state.QuestionFormIsValid && this.state.optionsFormIsValid &&  this.state.otherFieldsFormIsValid ){
        return true;
    }
    return false;
   }

    render(){ 
        if (this.state.redirect) {
            return <Redirect to='/admin/question-list'/>;
          }
const formElementsArray = [];
for (let key in this.state.Question) {
    formElementsArray.push({
        id: key,
        config: this.state.Question[key]
    });
}
const optionArray = [];
for (let key in this.state.options) {
    optionArray.push({
        id: key,
        config: this.state.options[key]
    });
}
const otherFieldArray = [];
for (let key in this.state.otherFields) {
    otherFieldArray.push({
        id: key,
        config: this.state.otherFields[key]
    });
}

const dynamicElementsArray = [];
for (let key in this.state.dynamicFields) {
    dynamicElementsArray.push({
        id: key,
        config: this.state.dynamicFields[key]
    });
}
let form = (
    <form className="kt-form" id="kt_form" onSubmit={e=>this.handleSubmit(e)}>
        <div className="kt-wizard-v1__form row">
        {formElementsArray.map((formElement,index) => (
            <Input 
                key={index}
                isradio={formElement.config.isradio==1?1:0}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.isProgram==1?this.props.codes:formElement.config.elementConfig}
                col={formElement.config.col}
                label={formElement.config.label}
                value={formElement.config.value}
                valueObj={formElement.config.valueObj}

                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                fullRow={formElement.config.fullRow}
                heading={formElement.config.heading}
                divider={formElement.config.divider}
                leftText={formElement.config.leftText}
                error={formElement.config.error}
              
                changed={(event,value) => this.inputChangedHandler(event, formElement.id,value)}
              
                />
        ))}
         {optionArray.map((formElement,index) => {
             
            let option= (
            <Input 
                key={index}
                isCrossBtn={index+1==optionArray.length?index==0?0:1:0}
                isradio={0}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                col={formElement.config.col}
                label={formElement.config.label}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                fullRow={formElement.config.fullRow}
                heading={formElement.config.heading}
                divider={formElement.config.divider}
                leftText={formElement.config.leftText}
                error={formElement.config.error}
                changed={(event,value) => this.optionChangedHandler(event, formElement.id,value)}
                deleteMethod={(event) => this.deleteOption(event, formElement.id)}
                />
        );
        if(this.state.Question.is_connected.value==true){
            let nestedOption=(<Input 
            key={`${index}1`}
            isradio={0}
            elementType={this.state.nestedOptions[formElement.id]['elementType']}
            elementConfig={this.state.nestedOptions[formElement.id]['isProgram']==1?this.props.codes:this.state.nestedOptions[formElement.id]['elementConfig']}
            col={this.state.nestedOptions[formElement.id]['col']}
            label={this.state.nestedOptions[formElement.id]['label']}
            value={this.state.nestedOptions[formElement.id]['value']}
            valueObj={this.state.nestedOptions[formElement.id]['valueObj']}
            invalid={!this.state.nestedOptions[formElement.id]['valid']}
            shouldValidate={this.state.nestedOptions[formElement.id]['validation']}
            touched={this.state.nestedOptions[formElement.id]['touched']}
            fullRow={this.state.nestedOptions[formElement.id]['fullRow']}
            heading={this.state.nestedOptions[formElement.id]['heading']}
            divider={this.state.nestedOptions[formElement.id]['divider']}
            leftText={this.state.nestedOptions[formElement.id]['leftText']}
            error={this.state.nestedOptions[formElement.id]['error']}
          
            changed={(event,value) => this.nOptionChangedHandler(event, formElement.id,value)}
           
            />
    );
    return (<>
    {option}
    {nestedOption}
    </>);
        }
        return option;
    }
        )}
       {this.isShowAddOptionBtn() &&(<div className="form-group col-md-12">
          <div className="btn btn-primary btn-sm font-weight-bolder text-uppercase "  type="submit" onClick={e=>this.addOption()} >Add Option<i className="fa fa-plus-circle ml-1 p-0"></i></div>
          </div>)
        }

          {otherFieldArray.map((formElement,index) => (
            <Input 
                key={index}
                isradio={formElement.config.isradio==1?1:0}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                col={formElement.config.col}
                label={formElement.config.label}
                value={formElement.config.value}
                valueObj={formElement.config.valueObj}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                fullRow={formElement.config.fullRow}
                heading={formElement.config.heading}
                divider={formElement.config.divider}
                leftText={formElement.config.leftText}
                error={formElement.config.error}
                changed={(event,value) => this.otherChangedHandler(event, formElement.id,value)}
                />
        ))}
          {dynamicElementsArray.map((formElement,index) => (
            <Input 
                key={index}
                isradio={formElement.config.isradio==1?1:0}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                col={formElement.config.col}
                label={formElement.config.label}
                value={formElement.config.value}
                valueObj={formElement.config.valueObj}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                fullRow={formElement.config.fullRow}
                heading={formElement.config.heading}
                divider={formElement.config.divider}
                leftText={formElement.config.leftText}
                error={formElement.config.error}
                changed={(event,value) => this.dynamicChangedHandler(event, formElement.id,value)}
                />
        ))}
        
        </div>
        <div className="kt-form__actions">
        <div className="flex-grow-1">
        </div>
      <div >
          {/* <span className={`btn  btn-outline-dark btn-elevate kt-login__btn-primary mr-2 `}  onClick={()=>this.resetForm()} >Reset</span> */}
               

                <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={this.state.isSubmitting}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                      }
                    )}`}
                    style={this.state.loadingButtonStyle}
                  >
                      <FormattedMessage id="ADMIN.BTN.EDIT_QUESTION" />
                  </button>
                </div>
                </div>
    </form>
);

  return (
  <>
     
     <h3 class="font-weight-bold text-dark"><FormattedMessage id="ADMIN.EDIT_QUESTION" /></h3>
        <span className="text-muted font-weight-bold d-block mb-10"><FormattedMessage id="ADMIN.EDIT_QUESTION.SUB_TITLE" /></span>

        {form}   
   
    </>
  );
}
}

const mapStateToProps= state=>{
   return {
       questionType:state.questionReducer.questionType,
       connectedQuestions:state.questionReducer.connectedQuestions,
       functionToCall:state.questionReducer.function_to_call,
       isCreateRequest:state.questionReducer.isCreateQuestionRequest,
       units:state.questionReducer.units,
       properties:state.questionReducer.properties,
       editQuestionData:state.editQuestionReducer
   }
  }
  const mapDispatchToProps = dispatch => {
    return {
        getQuestionType: data => dispatch(actionsMethods.getQuestionRequest()),
        createQuestion: data => dispatch(actionsMethods.createQuestionRequest(data)),
        getConnectedQuestions: ()=>dispatch(actionsMethods.getConnectedQuestionRequest()),
        showSnackBarNotification:data=>dispatch(actionsMethods.showSnackBarNotification(data)),
        getQuestionProReq:id => dispatch(actionsMethods.getQuestionPropertiesRequest(id)),
        editQuestion:data =>dispatch(actionsMethods.editQuestionRequest(data))

  }
  }
  export default connect(mapStateToProps, mapDispatchToProps)(EditQuestion);

const optionField= (type,value='',dbId='')=>{
        switch(type){
        case "radio":
        case "select2": 
        case "checkbox":
        case "select":
    return (
        {
            elementType: 'input',
            label:'',
            col:8,
            fullRow:4,
            error:'',
            divider:0,
            databaseId:dbId,
            heading:'',
            elementConfig: {
                type: 'text',
                placeholder: 'Option',
            },
            value: value,
            validation: {
                required: true,
            },
            errorMessage:{
              required: 'Option Feild is required',
            },
            valid: (value==''?false:true),
            touched: false
        }
    );
    default:
        return '';
    }
};

const NestedOptionField= (questions,type,selectedId='')=>{
let selectObjct=questions.options.filter(it=>selectedId.indexOf(parseInt(it.value))!=-1);
console.log(questions,selectedId,selectObjct);

    return ({
            elementType: 'multiple_autocomplete',
            label:'',
            heading:'',
            error:'',
            elementConfig: {
                options: questions.options
            },
            value: selectedId,
            valueObj:selectObjct,
            col:8,
            fullRow:4,
            databaseId:'',
            validation: {
              required:true
            },
            errorMessage:{
             required:'Nested Option is required'
            },
            valid: false,
            touched:false
          }    
    );
}