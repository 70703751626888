import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
export default class ApexChart extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
      
        series: [{
          name: 'Call',
          data:[]
        }],
        options: {
          chart: {
            height: "200px",
            type: 'area',
           // stacked: true,
            sparkline: {
                enabled: true,
            }, 
            zoom: {
              enabled: false
          }, 
            dropShadow: {
              enabled: true,
              enabledOnSeries: undefined,
              top: 5,
              left: 0,
              blur: 3,
              color: '#D13647',
              opacity: 0.5
          },
          
            toolbar: {
                show: false,
              },
          },
          plotOptions: {},
          legend: {
            show: false
        },
          dataLabels: {
            enabled: false
          },
          fill: {
            type: 'solid',
            opacity: 0
        },
          stroke: {
            curve: 'smooth',
            show: true,
            width: 3,
            colors: ['#D13647']
          },
          xaxis: {
         //   type: 'datetime',
            categories: ['a','d'],
            axisBorder: {
              show: false,
          },
          axisTicks: {
              show: false
          },
          },
          tooltip: {
            x: {
            //  format: 'dd/MM/yy HH:mm'
            },
            y: {
              formatter: function(val) {
                  return  val + " Users"
              }
          },
            axisTicks: {
                show: false
            },
            marker: {
              show: false
          }
          },
          markers: {
            colors: ['#fff'],
            strokeColor: ['#D13647'],
            strokeWidth: 3
        },
          grid: {
            show: true,
            borderColor: '#D13647',
            strokeDashArray: 0,
            position: 'back',
            xaxis: {
                lines: {
                    show: false
                }
            },   
            yaxis: {
                lines: {
                    show: false
                }
            },  
            row: {
              colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },  
            column: {
                colors: undefined,
                opacity: 0
            }, 
            colors: ['transparent'], 
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            },  
        }
        },
      
      
      };
    }

    componentDidUpdate(prevProps,prevState){
      if(prevProps.heading !=this.props.heading){
        let options={...this.state.options};
        let abc={...options.xaxis,...{categories:[...this.props.heading]}};
        let c={...options,...{
          xaxis:abc
        }};

         this.setState({options:c,series: [{
          name: 'Total Patients',
          data:this.props.data
        }]});
      }

    }
  

    render() {
      return (
        

  <div id="chart">
<ReactApexChart options={this.state.options} series={this.state.series} type="area"  height="100%" />
</div>
      )
    }
}