
import React, { useMemo,Suspense,useState,useEffect  } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { metronic } from "../../../../_metronic";
import { Redirect,Link, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../../_metronic";
import HeaderBreadcrumb from '../../../components/UI/HeaderBreadcrumb';
import QuickStatsChart from "../../../widgets/QuickStatsChart";
import AreaChart from './AreaChart';
import PieChart from './PieChart1';
import BarChart from './BarChart';
import DaySchedule from './Day';
import Week from './week';
import Month from './Month';
import { FormattedMessage, injectIntl,useIntl } from "react-intl";
import * as actionsMethods from '../../../store/actions/index';
const breadcrumb=[
  {
    title: "Dashboard",
    page: "dashboard"
  }];

const Dashboard = props=>{
	const intl = useIntl();
	const [currentTab, setChangeTab] = useState(1);
	const {totalPatients}=useSelector(state=>state.cousellerDashboard);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(actionsMethods.counsellorDashboardDetailRequest());
	  }, []);
    return(
        <>
         <HeaderBreadcrumb breadcrumb={breadcrumb} title="Dashboard" />
         {/* <div className="row">
                    <div className="col-xl-4">
                        <div className="card card-custom bg-gray-100 gutter-b card-stretch">
                              <div className="card-header border-0 bg-danger py-5">
                                    <h3 className="card-title font-weight-bolder text-white">{intl.formatMessage({ id: "DASHBOARD.TOTAL_PATIENTS" })}</h3>
                                    <div className="card-toolbar">

                                    </div>
                              </div>
                              <div className="card-body p-0 position-relative overflow-hidden">
                                    <div id="kt_mixed_widget_1_chart" className="card-rounded-bottom bg-danger" style={{height: "200px"}}>
                                    <AreaChart heading={totalPatients.graph_categories} data={totalPatients.graph_data}/>
                                    </div>
                                    <div className="card-spacer mt-n25">
                                    <div className="row m-0">
										<div className="col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7">
											<span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                                            <svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon points="0 0 24 0 24 24 0 24"/>
        <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3"/>
        <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fillRule="nonzero"/>
    </g>
    </svg>
	{totalPatients.onboarding}
                                            </span>
                                            <a href="#" className="text-warning font-weight-bold font-size-h6">Onboarded Today</a>
                                        </div>
                                    	<div className="col bg-light-primary px-6 py-8 rounded-xl mb-7">
											<span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																	<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																		<polygon points="0 0 24 0 24 24 0 24" />
																		<path d="M18,8 L16,8 C15.4477153,8 15,7.55228475 15,7 C15,6.44771525 15.4477153,6 16,6 L18,6 L18,4 C18,3.44771525 18.4477153,3 19,3 C19.5522847,3 20,3.44771525 20,4 L20,6 L22,6 C22.5522847,6 23,6.44771525 23,7 C23,7.55228475 22.5522847,8 22,8 L20,8 L20,10 C20,10.5522847 19.5522847,11 19,11 C18.4477153,11 18,10.5522847 18,10 L18,8 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
																		<path d="M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero" />
																	</g>
																</svg>
																{totalPatients.follow_up}

                                            </span>
                                            <a href="#" className="text-primary font-weight-bold font-size-h6 mt-2">Follow up Today</a>
                                        </div>    
                                    </div>  
									<div className="row m-0">
	<div className="col bg-light-danger px-6 py-8 rounded-xl mr-7">
		<span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
		<svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon points="0 0 24 0 24 24 0 24"/>
        <path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3"/>
        <path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="#000000" fillRule="nonzero"/>
    </g>
    </svg>		
	{totalPatients.total_users}								
	</span>
	<a href="#" className="text-danger font-weight-bold font-size-h6 mt-2">Total Users</a>
	</div>
	<div className="col bg-light-success px-6 py-8 rounded-xl ">

	</div>
													</div>
                                    </div>


                              </div>
                        </div>
                    </div>
                    <div className="col-xl-4">
										<div className="card card-custom gutter-b card-stretch">
											<div className="card-header border-0  py-5">
												<div className="card-title font-weight-bolder">
                                                <h3 className="card-title font-weight-bolder">{intl.formatMessage({ id: "DASHBOARD.TOTAL_DUE_ALERTS" })}</h3>
															</div>
												<div className="card-toolbar">
													
												</div>
											</div>
											
											<div className="card-body d-flex flex-column">
												<div id="kt_mixed_widget_16_chart" style={{height: "200px"}}>
                                                <PieChart />
                                                </div>
												
												<div className="mt-10 mb-5">
													<div className="row row-paddingless mb-10">
														<div className="col">
															<div className="d-flex align-items-center mr-2">
																<div className="symbol symbol-45 symbol-light-info mr-4 flex-shrink-0">
																	<div className="symbol-label">
																		<span className="svg-icon svg-icon-lg svg-icon-info">
																			
                <svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"/>
        <path d="M7.14296018,11.6653622 C7.06267828,11.7456441 6.95746388,11.7962128 6.84462255,11.8087507 C6.57016914,11.8392455 6.32295974,11.641478 6.29246492,11.3670246 L5.76926113,6.65819047 C5.76518362,6.62149288 5.76518362,6.58445654 5.76926113,6.54775895 C5.79975595,6.27330553 6.04696535,6.07553802 6.32141876,6.10603284 L11.0302529,6.62923663 C11.1430942,6.64177456 11.2483086,6.69234321 11.3285905,6.77262511 C11.5238526,6.96788726 11.5238526,7.28446974 11.3285905,7.47973189 L9.94288211,8.86544026 L11.4443443,10.3669024 C11.6396064,10.5621646 11.6396064,10.8787471 11.4443443,11.0740092 L10.7372375,11.781116 C10.5419754,11.9763782 10.2253929,11.9763782 10.0301307,11.781116 L8.52866855,10.2796538 L7.14296018,11.6653622 Z" fill="#000000" fillRule="nonzero" opacity="0.3"/>
        <path d="M12.0799676,14.7839934 L14.2839934,12.5799676 C14.8927139,11.9712471 15.0436229,11.0413042 14.6586342,10.2713269 L14.5337539,10.0215663 C14.1487653,9.25158901 14.2996742,8.3216461 14.9083948,7.71292558 L17.6411989,4.98012149 C17.836461,4.78485934 18.1530435,4.78485934 18.3483056,4.98012149 C18.3863063,5.01812215 18.4179321,5.06200062 18.4419658,5.11006808 L19.5459415,7.31801948 C20.3904962,9.0071287 20.0594452,11.0471565 18.7240871,12.3825146 L12.7252616,18.3813401 C11.2717221,19.8348796 9.12170075,20.3424308 7.17157288,19.6923882 L4.75709327,18.8875616 C4.49512161,18.8002377 4.35354162,18.5170777 4.4408655,18.2551061 C4.46541191,18.1814669 4.50676633,18.114554 4.56165376,18.0596666 L7.21292558,15.4083948 C7.8216461,14.7996742 8.75158901,14.6487653 9.52156634,15.0337539 L9.77132688,15.1586342 C10.5413042,15.5436229 11.4712471,15.3927139 12.0799676,14.7839934 Z" fill="#000000"/>
    </g>
</svg>
	</span>
																	</div>
																</div>
																
																<div>
																	<div className="font-size-h4 text-dark-75 font-weight-bolder">10</div>
																	<div className="font-size-sm text-muted font-weight-bold mt-1">Follow up Calls</div>
																</div>
															</div>
														</div>
														
														<div className="col">
															<div className="d-flex align-items-center mr-2">
																
																<div className="symbol symbol-45 symbol-light-danger mr-4 flex-shrink-0">
																	<div className="symbol-label">
																		<span className="svg-icon svg-icon-lg svg-icon-danger">
                                                                     <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon points="0 0 24 0 24 24 0 24"/>
        <path d="M18,8 L16,8 C15.4477153,8 15,7.55228475 15,7 C15,6.44771525 15.4477153,6 16,6 L18,6 L18,4 C18,3.44771525 18.4477153,3 19,3 C19.5522847,3 20,3.44771525 20,4 L20,6 L22,6 C22.5522847,6 23,6.44771525 23,7 C23,7.55228475 22.5522847,8 22,8 L20,8 L20,10 C20,10.5522847 19.5522847,11 19,11 C18.4477153,11 18,10.5522847 18,10 L18,8 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3"/>
        <path d="M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero"/>
    </g>
</svg>
																		</span>
																	</div>
																</div>
																
																<div>
																	<div className="font-size-h4 text-dark-75 font-weight-bolder">10</div>
																	<div className="font-size-sm text-muted font-weight-bold mt-1">Patient Onboarding</div>
																</div>
															</div>
														</div>
													</div>
													<div className="row row-paddingless">
														<div className="col">
															<div className="d-flex align-items-center mr-2">
															
																<div className="symbol symbol-45 symbol-light-success mr-4 flex-shrink-0">
																	<div className="symbol-label">
																		<span className="svg-icon svg-icon-lg svg-icon-success">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"/>
        <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3"/>
        <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000"/>
        <rect fill="#000000" opacity="0.3" x="10" y="9" width="7" height="2" rx="1"/>
        <rect fill="#000000" opacity="0.3" x="7" y="9" width="2" height="2" rx="1"/>
        <rect fill="#000000" opacity="0.3" x="7" y="13" width="2" height="2" rx="1"/>
        <rect fill="#000000" opacity="0.3" x="10" y="13" width="7" height="2" rx="1"/>
        <rect fill="#000000" opacity="0.3" x="7" y="17" width="2" height="2" rx="1"/>
        <rect fill="#000000" opacity="0.3" x="10" y="17" width="7" height="2" rx="1"/>
    </g>
</svg>
																		</span>
																	</div>
																</div>
															
																<div>
																	<div className="font-size-h4 text-dark-75 font-weight-bolder">1</div>
																	<div className="font-size-sm text-muted font-weight-bold mt-1">Tasks</div>
																</div>
															</div>
														</div>
													
														<div className="col">
															<div className="d-flex align-items-center mr-2">
																<div className="symbol symbol-45 symbol-light-primary mr-4 flex-shrink-0">
																	<div className="symbol-label">
																		<span className="svg-icon svg-icon-lg svg-icon-primary">
																			
																		<svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																	<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																		<rect x="0" y="0" width="24" height="24"></rect>
																		<rect fill="#000000" opacity="0.3" x="13" y="4" width="3" height="16" rx="1.5"></rect>
																		<rect fill="#000000" x="8" y="9" width="3" height="11" rx="1.5"></rect>
																		<rect fill="#000000" x="18" y="11" width="3" height="9" rx="1.5"></rect>
																		<rect fill="#000000" x="3" y="13" width="3" height="7" rx="1.5"></rect>
																	</g>
																</svg>
																		</span>
																	</div>
																</div>
																
																<div>
																	<div className="font-size-h4 text-dark-75 font-weight-bolder">2</div>
																	<div className="font-size-sm text-muted font-weight-bold mt-1">Doctor Report</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									
									</div>
                     	
    <div className="col-xl-4">
										<div className="card card-custom bg-gray-100 gutter-b card-stretch">
											<div className="card-header border-0 bg-primary py-5">
												<h3 className="card-title font-weight-bolder text-white">{intl.formatMessage({ id: "DASHBOARD.PROGRAMS" })}</h3>
												<div className="card-toolbar">
													
												</div>
											</div>
										
											<div className="card-body d-flex flex-column p-0">
												<div id="kt_mixed_widget_5_chart" className="card-rounded-bottom bg-primary" style={{height: '200px'}}>
													<BarChart />
												</div>
												
												<div className="card-spacer bg-white card-rounded flex-grow-1">
													<div className="row m-0">
														<div className="col px-8 py-6 mr-8">
															<div className="font-size-sm text-muted font-weight-bold">Covid</div>
															<div className="font-size-h4 font-weight-bolder">100</div>
														</div>
														<div className="col px-8 py-6">
															<div className="font-size-sm text-muted font-weight-bold">GutFit</div>
															<div className="font-size-h4 font-weight-bolder">50</div>
														</div>
													</div>
												
													<div className="row m-0">
														<div className="col px-8 py-6 mr-8">
															<div className="font-size-sm text-muted font-weight-bold">Zydus</div>
															<div className="font-size-h4 font-weight-bolder">10</div>
														</div>
														<div className="col px-8 py-6">
															<div className="font-size-sm text-muted font-weight-bold">Roche</div>
															<div className="font-size-h4 font-weight-bolder">30</div>
														</div>
													</div>
												</div>
											
											</div>
										</div>
									</div>




									
          </div> */}
		 {/* <div className="row mt-5">
            <div className="col-md-12">
                <div className="card card-custom">
                    <div className="card-header">
                        <div className="card-title font-weight-bolder">
                            <div className="card-label">To Do
							<div className="font-size-sm text-muted mt-2">Two Tasks required immediate actions</div>
							</div>
                        </div>
                        <div className="card-toolbar">
                           
                            {/* <span  className="btn btn-light-primary font-weight-bold mr-1" onClick={() => setChangeTab(1)}>
							<FormattedMessage id="DASHBOARD.DAY" />

							</span>

                            <span className="btn btn-light-primary font-weight-bold mr-1"  onClick={() => setChangeTab(2)}>
							<FormattedMessage id="DASHBOARD.WEEK" />
							</span>
                            <span className="btn btn-light-primary font-weight-bold"  onClick={() => setChangeTab(3)}>
							<FormattedMessage id="DASHBOARD.MONTH" />
							</span> 
                           
                           
                        </div>
                    </div>
                     <div className="card-body">
					<DaySchedule/>
                   {currentTab==1 && (<DaySchedule/>)} 
				   {currentTab==2 && (<Week/>)}
                    {currentTab==3 && (<Month/>)}

				         {/* </div> 
            </div>
            </div>      
        </div> */}
		<DaySchedule/>
       </>
);
}

export default Dashboard;