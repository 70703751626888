import { put, call,delay  } from 'redux-saga/effects';
import axios from 'axios';
import * as actions from '../actions';
import * as Constants from '../../shared/constants';
import Qs from 'qs'

export function* initGetTaskTypeSaga(action) {
    try {
      axios.defaults.withCredentials = true;
      const response = yield axios.get( process.env.REACT_APP_API_URL+'task-type');
      yield put(actions.getTaskTypeSuccess(response.data));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
      }
      yield put(actions.getTaskTypeFailed(error));
    }
   
  }

  export function* initCreateTaskSaga(action) {
    try {
      axios.defaults.withCredentials = true;
      const response = yield axios.post( process.env.REACT_APP_API_URL+'create-task',action.data);
      yield put(actions.showSnackBarNotification({
        message:action.data.id==undefined?'Task Created successfully':'Task updated successfully',
        type:Constants.notificationType.SUCCESS,
        open:true
       }));
      yield put(actions.createTaskSuccess(response.data));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
      }
      yield put(actions.showSnackBarNotification({
        message:action.data.id==undefined?'There is error in Creating Task':'There is error in updating task',
        type:Constants.notificationType.ERROR,
        open:true
  }));
      yield put(actions.createTaskFailed(error));
    }
   
  }

  
  export function* initGetAllTasksSaga(action) {
    try {
      axios.defaults.withCredentials = true;
    const response = yield axios.get( 
      `${process.env.REACT_APP_API_URL}getall-task`,
      {params:{...action.data},paramsSerializer: params => {
      return Qs.stringify(params)
    }});
      yield put(actions.getAllTasksSuccess(response.data));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
      }else{
        yield put(actions.getAllTasksFailed(error));
      }
    }
  }


  export function* initEditTaskSaga(action) {
    try {
      axios.defaults.withCredentials = true;
      const response = yield axios.get( `${process.env.REACT_APP_API_URL}edit-task/${action.data.id}`);
    
      yield put(actions.editTaskSuccess(response.data));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
      }
    
      yield put(actions.editTaskFailed(error));
    }
   
  }
