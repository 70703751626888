import React from 'react';
import HeaderBreadcrumb from '../../../components/UI/HeaderBreadcrumb';

import './teleCallCreate.css';
import EditTask from './task/Edit';
import EditModule from './module/Edit';
import EditQuestion from './question/Edit';
import { Redirect,Link, Route, Switch,useParams } from "react-router-dom";
import {withRouter} from 'react-router-dom';
import { FormattedMessage,injectIntl } from "react-intl";

let breadcrumb=[
 ];

  const TAB_NAMES=[
    {id:1,uid:'create-question',name:<FormattedMessage id="ADMIN.QUESTIONS" />,icon:'fa fa-tasks', link:'/admin/edit-telecall/edit-question', matchlink:'edit-question',listingRoute:'/admin/question-list'},
    {id:2,uid:'create-task',name:<FormattedMessage id="ADMIN.TASKS" />,icon:'fa fa-tasks', link:'/admin/edit-telecall/edit-task',matchlink:'edit-task',listingRoute:'/admin/tasks-list'},
    {id:3,uid:'create-module',name:<FormattedMessage id="ADMIN.MODULES" />,icon:'fa fa-list-alt',link:'/admin/edit-telecall/edit-module',
    matchlink:'edit-module',listingRoute:'/admin/modules-list'},
];

export default class EditTeleCall extends React.Component {
    constructor(props){
        super(props);
        this.state={
            value:0,
            tab:1
        };
    }   

    getListingRoute=()=>{
      let urlArray=this.props.location.pathname.split('/');
      let lastParameter=urlArray[urlArray.length-2];
      let currentTab=TAB_NAMES.filter(it=>it.matchlink==lastParameter);
      if(currentTab.length==0){
        return true;
      }
      breadcrumb[0]={
       title: currentTab[0].name,
       page: currentTab[0].listingRoute };
    
       breadcrumb[1]={
         title: 'Edit',
         page: this.props.location.pathname };
         return currentTab[0].name;
     }

    render(){ 
      let mainName=this.getListingRoute();
      if (mainName==true) {
        return <Redirect to='/admin/question-list'/>;
      }
      

     let urlArray=this.props.location.pathname.split('/');
     let lastParameter=urlArray[urlArray.length-1];

    let tabs=TAB_NAMES.map((item,index)=>(
    
     <li className={`nav-item `}  key={index}>
      <Link to={`${item.link}/${lastParameter}`} className={`nav-link ${this.props.location.pathname==item.link?'active':''}`} onClick={(e)=>this.setState({tab:item.id})}>
        <span className="nav-icon"><i className={item.icon}></i></span>
        <span className="nav-text1">{item.name}</span>
      </Link>
  </li>
));



  return (
  <>
  <HeaderBreadcrumb breadcrumb={breadcrumb} title={mainName} />
    <div className="row telecall-tab">
       <div className="col-md-12">
       <div className="card card-custom">

       <div className="card-header card-header-tabs-line">
		<div className="card-toolbar">
			<ul className="nav nav-tabs nav-bold nav-tabs-line">

				{tabs}
			</ul>
		</div>
		<div className="card-toolbar">
        
                
		</div>
	</div> 
   
  <div className="card-body">
                    <Switch>
                        {
                          <Redirect exact from={this.props.match.path} to={`${this.props.match.path}/edit-question/:id`} />
                        }
                        <Route path={`${this.props.match.path}/edit-task/:id`}  component={EditTask} />
                        <Route path={`${this.props.match.path}/edit-module/:id`} component={EditModule} exact />
                        <Route path={`${this.props.match.path}/edit-question/:id`} component={EditQuestion} exact>
                        </Route>
                    </Switch>  
	</div>
    </div>
    </div>
    </div>
    </>
  );
}
}
