import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LayoutSplashScreen } from "./LayoutContext";
import * as builder from "../ducks/builder";
import MenuConfigAdmin from "./MenuConfigAdmin";
import MenuConfig from "./MenuConfig";
import CartAdmin from './roleMneu/CartAdmin';
import * as actionsMethods from '../../app/store/actions/index';

/**
 * Used to synchronize current layout `menuConfig`, `layoutConfig` and
 * `htmlClassService` with redux store.
 */
export default function LayoutInitializer({
                                            children,
                                            menuConfig,
                                            layoutConfig,
                                            htmlClassService
                                          }) {
  const dispatch = useDispatch();
  const builderState = useSelector(({ builder }) => builder);
  const auth = useSelector(state => state.auth);
  const navMenu = useSelector(state=>state.navUserManu.menus)
  useEffect(() => {
    let menu=getMenu(auth.user.type,auth.user.roles,navMenu);
    dispatch(builder.actions.setMenuConfig(menu));
  }, [dispatch, menuConfig,navMenu]);

  useEffect(() => {
    if (layoutConfig.demo !== builderState.layoutConfig.demo) {
      dispatch(builder.actions.setLayoutConfigs(layoutConfig));
    }
  }, [dispatch, builderState, layoutConfig]);

  useEffect(() => {
    dispatch(builder.actions.setHtmlClassService(htmlClassService));
  }, [dispatch, htmlClassService]);

  useEffect(() => {
    dispatch(actionsMethods.navUserMenuRequest());
  }, []);

  return htmlClassService === builderState.htmlClassServiceObjects ? (
      // Render content when `htmlClassService` synchronized with redux store.
      <>{children}</>
  ) : (
      // Otherwise sow loading splash screen.
      <LayoutSplashScreen />
  );
}

function getMenu(type,roles,navMenu=[]){
  let menu1={...MenuConfigAdmin};
  let headerUpdate1={...menu1['header'],...{
    items:navMenu
  }};
  let menudatedMenu1={...menu1,...{
    header:headerUpdate1
  }}
  return menudatedMenu1;
  // switch(type){
  //   case ('SuperAdmin'):
  //     let menu={...MenuConfigAdmin};
  //     if(roles.indexOf('cart admin') !=-1){
  //         let adminMenu=CartAdmin;
  //             let appendedMenu=[...menu['header']['items'],...adminMenu];
  //             // {...menu['header'],{
  //               let headerUpdate={...menu['header'],...{
  //                 items:appendedMenu
  //               }};
  //               let menudatedMenu={...menu,...{
  //                 header:headerUpdate
  //               }}
              
  //        return menudatedMenu;
  //     }
  //     return MenuConfigAdmin;
  //     break;
  //   default:
  //     return MenuConfig;

//  }
}