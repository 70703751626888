import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
     relation:{
        options:[{
            value:"",
            displayValue:"Choose Relation"
        }]
     },
   
};
const getcareTakerRelationSuccess = (state, action) => {
    let data=[{
        value:"",
        displayValue:"Choose Relation"
    }];
     if(action.data.relations.length>0){
        for(let i=0;i<action.data.relations.length;i++){
         data.push({value:action.data.relations[i].relation_id,displayValue:action.data.relations[i].title});
         }
     }
   
 let abc=updateObject( state, {
    relation:{options:data}
    });
   return abc;
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.CARE_TAKER_RELATION_SUCCESS: 
           return getcareTakerRelationSuccess(state,action);
        default: 
            return state;
    }
};

export default reducer;
