import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  menus:[]
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.NAV_USER_MENU_SUCCESS:
            return updateObject( state, {
                menus:Object.values(action.data)
            });
        default: 
            return state;
    }
};

export default reducer;
