export const DynamicField =(data,selected=[])=>{
    if(data.field_type==1){
        let Options=[]
        let selectedElement=[];
        let selectedValue=[];
        for(let i=0;i<data.value.length;i++){
            if(selected.length>0){
                if(selected[0].value.indexOf(data.value[i]) != -1){
                    selectedElement.push({
                        value:data.value[i],
                        displayValue:data.value[i]
                    });
                    selectedValue.push(data.value[i]);
                }

            }
            Options.push({
                value:data.value[i],
                displayValue:data.value[i]
            });
        }
        return {
            elementType: 'multiple_autocomplete',
            label:data.name,
            heading:'',
            error:'',
            elementConfig: {
                options: Options
            },
            value:selectedValue,
            valueObj:selectedElement,
            col:8,
            fullRow:0,
            validation: {
            //  required:true
            },
            errorMessage:{
            // required:'Nested Option is required'
            },
            valid: true,
            touched:false
          };
    }else{
        let selectedValue='';
        if(selected.length>0){
            selectedValue=selected[0].value;
        }
        return {
            elementType: 'input',
            label:data.name,
            col:8,
            fullRow:0,
            error:'',
            divider:0,
            heading:'',
            elementConfig: {
                type: 'text',
                placeholder: 'Enter '+data.name,
            },
            value: selectedValue,
            validation: {
               // required: true,
            },
            errorMessage:{
            //  required: 'Placeholder field  is required',
            },
            valid: true,
            touched: false
        };
    }
}

