import { put,takeLatest} from 'redux-saga/effects';
import axios from 'axios';
import * as actions from '../actions';
import * as Constants from '../../shared/constants';
import Qs from 'qs'

export function* initCareTakerRelationSaga(action) {
    try {
      axios.defaults.withCredentials = true;
      const response = yield axios.get( process.env.REACT_APP_API_URL+'care-taker/relations');
     console.log(response);
       yield put(actions.careTakerRelationSuccess(response.data));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
      }
      yield put(actions.careTakerRelationFailed(error));
    }
  }

