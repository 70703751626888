export const createQuestion={
    question: {
        elementType: 'input',
        label:'Question',
        col:8,
        fullRow:0,
        error:'',
        divider:0,
        heading:'',
        elementConfig: {
            type: 'text',
            placeholder: 'Type Question Here',
        },
        value: '',
        validation: {
            required: true,
        },
        errorMessage:{
          required: 'Question field is required',
        },
        valid: false,
        touched: false
    },
    type: {
        elementType: 'select',
        label:'Question Type',
        heading:'',
        error:'',
        elementConfig: {
            options: [
              
            ]
        },
        value: '',
        col:4,
        fullRow:0,
        validation: {
          required:true
        },
        errorMessage:{
         required:'Question Type is required'
        },
        valid: false,
        touched:false
      },
      placeholder: {
        elementType: 'input',
        label:'Placeholder',
        col:8,
        fullRow:0,
        error:'',
        divider:0,
        heading:'',
        elementConfig: {
            type: 'text',
            placeholder: 'Placeholder Tex here',
        },
        value: '',
        validation: {
            required: true,
        },
        errorMessage:{
          required: 'Placeholder field  is required',
        },
        valid: false,
        touched: false
    },
    units:{
      elementType: 'multiple_autocomplete',
      label:'Units',
      heading:'',
      error:'',
      elementConfig: {
          options: []
      },
      value: [],
      valueObj:[],
      col:4,
      fullRow:0,
      validation: {
      //  required:true
      },
      errorMessage:{
      // required:'Nested Option is required'
      },
      valid: true,
      touched:false
    }
  }


  export const isconnected={
    elementType: 'mcheckbox',
    label:'Connected Question Exists',
    col:12,
    fullRow:0,
    error:'',
    divider:0,
    heading:'',
    elementConfig: {
        type: 'checkbox',
    },
    value: false,
    validation: {
    },
    errorMessage:{
    },
    valid: true,
    touched: false
  };


  export const otherFields={
    mandatory: {
        elementType: 'switch',
        label:'',
        col:8,
        fullRow:0,
        heading:'',
        error:'',
        elementConfig: {
            type: 'checkbox',
        },
        value: 0,
        leftText:'Mandatory',
        validation: {
           // required: true
        },
        errorMessage:{
         
        },
        valid: true,
        //touched: false
      },
      date_specific: {
        elementType: 'switch',
        label:'',
        col:4,
        fullRow:0,
        heading:'',
        error:'',
        elementConfig: {
            type: 'checkbox',
        },
        value: 0,
        leftText:'Date Specific',
        validation: {
           // required: true
        },
        errorMessage:{
         
        },
        valid: true,
        //touched: false
      },
      function_to_call:{
        elementType: 'multiple_autocomplete',
        label:'Call To Function',
        heading:'',
        error:'',
        elementConfig: {
            options: []
        },
        value: [],
        valueObj:[],
        col:8,
        fullRow:4,
        validation: {
        //  required:true
        },
        errorMessage:{
        // required:'Nested Option is required'
        },
        valid: true,
        touched:false
      }
     
  }

  export const  unitField=options=>{
    return {
      elementType: 'multiple_autocomplete',
      label:'Units',
      heading:'',
      error:'',
      elementConfig: {
          options: options
      },
      value: [],
      valueObj:[],
      col:4,
      fullRow:0,
      validation: {
      //  required:true
      },
      errorMessage:{
      // required:'Nested Option is required'
      },
      valid: true,
      touched:false
    };
  }
 