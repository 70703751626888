import React, { Component } from "react";
import {Link} from 'react-router-dom';

import {
  Portlet,
  PortletBody} from "../../../partials/content/Portlet";
import './Patients.css';
import { updateObject,checkValidity } from '../../../shared/utility';
import DataTable from 'react-data-table-component';
import differenceBy from 'lodash/differenceBy';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Delete from '@material-ui/icons/Delete';
// import Add from '@material-ui/icons/Add';
import memoize from 'memoize-one';
import TextField from '@material-ui/core/TextField';
import ActionMenu from '../../../components/UI/ActionMenu';
//import {data} from './tableData';
import { CircularProgress, Typography } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import HeaderBreadcrumb from '../../../components/UI/HeaderBreadcrumb';
import { connect } from 'react-redux';
import * as actionsMethods from '../../../store/actions/index';
import TableCell from '@material-ui/core/TableCell';

const breadcrumb=[
  {
    title: "Patient List",
    page: "patient"
  }];

  const columnStyleWithWidth = {
    top: "0px",
    left: "0px",
    zIndex: "100",
    position: "sticky",
    backgroundColor: "#fff",
    width: "150px"
 }

const sortIcon = <ArrowDownward />;
const selectProps = { indeterminate: isIndeterminate => isIndeterminate };
const titleSetion=(
    <>
    <h3 style={{display:'inline-block', padding: '0',  margin: '0',marginRight: '1rem'}}> Patient List</h3>
    <span className="kt-subheader__separator kt-subheader__separator--v" />
    {/* <TextField id="outlined-basic" label="Search" variant="outlined" size="small" style={{ margin: '0' }} /> */}

    </>
)
const actions = (
   
  <IconButton
    color="primary"
  >
                    

  {/* <Add /> */}
  <Link to="/patient-add" className="btn btn-label-primary btn-bold btn-icon-h">Add Patient</Link>
  </IconButton>
);
const contextActions = memoize(deleteHandler => (
  <IconButton
    color="secondary"
    onClick={deleteHandler}
  >
    <Delete />
  </IconButton>
));

const columns = [
  {
    cell: row => <ActionMenu options={menuOption} row={row} />,
    allowOverflow: true,
    button: true,
    width: '56px', // custom width for icon button
  },
  {
    name: 'Patient Name',
    selector: 'patient_name',
    sortable: true,
  },
  {
    name: 'Mobile Number',
    selector: 'mobileNumber',
    sortable: true,
   // right: true,
  },
  {
    name: 'Program Code',
    selector: 'programCode',
    sortable: true,
  //  right: true,
  },
  {
    name: 'Status',
   selector: 'status',
    sortable: true,
   // right: true,
    cell: row => <span className={`badge badge-${row.status}`}>{row.status}</span>,

  },
  {
    name: 'Follow-up Due',
    selector: 'followUp',
    sortable: true,
  //  right: true,
  cell: row => <span className={`badge badge-${row.followUp}`}>{row.followUp}</span>
  },
  // {
  //   name: 'HCP',
  //   selector: 'HCP',
  //   sortable: true,
  //   //right: true,
  // },
  {
    name: 'Mode',
    selector: 'mode',
    sortable: true,
    cell: row => <><span className={`mode-${row.mode}`}></span>{row.mode}</>

  //  right: true,
  },
  {
    name: 'Last Active in App',
    selector: 'lastActiveInApp',
    sortable: true,
   // right: true,
  },{
    name: 'Email id',
    selector: 'emailId',
    sortable: true,
  //  right: true,
  }
];
 const menuOption=[
   {
     title:'Patient Profile',
     type:1,
     redirectTo:'/patient-profile/'
   }
  //  ,
  //  {
  //   title:'Patient Edit',
  //   type:2,
  //   redirectTo:'/patient-add'
  // }
 ];
 const STATUS=[
 {name:'Status',value:''},
 {name:'Updated',value:'Updated'},
 {name:'Requested',value:'Requested'},
 {name:'Pending',value:'Pending'},
 {name:'Cancelled',value:'Cancelled'},

 ];
 const FOLLOW_UP_OPTION=[
  {name:'Follow up Due',value:''},
  {name:'call due',value:'call due'},
  {name:'scheduled',value:'scheduled'},
  {name:'completed',value:'completed'},
 
  ];
  const MODE=[
    {name:'Mode',value:''},
    {name:'Online',value:'1'},
    {name:'OffLine',value:'0'},

   
    ];

class ListPatient extends React.Component {
  constructor(props){
      super(props);
  
  this.state = {
    page: 0,
    count: 1,
    rowsPerPage: 5,
    start:0,
	optionFilter:{
		status:'',
		follow_up:'',
		mode:'',
	},
    sortOrder:{name: "cust_app_id", direction: "desc"},
    search:{value:'',regex:false},
    order:[{column:0, dir:'desc'}],
    serverSortingCol : [
        {data:"cust_app_id",name:"cust_app_id",searchable:true,orderable:true,search:{value:"",regex:false}},
        {data:"name",name:"name",searchable:true,orderable:true,search:{value:"",regex:false}},
        {data:"phone",name:"phone",searchable:true,orderable:true,search:{value:"",regex:false}},
        {data:"code",name:"code",searchable:true,orderable:true,search:{value:"",regex:false}},
        {data:"status",name:"status",searchable:true,orderable:true,search:{value:"",regex:false}},
        {data:"follow_up",name:"follow_up",searchable:true,orderable:true,search:{value:"",regex:false}},
        // {data:"hcp",name:"email",searchable:true,orderable:true,search:{value:"",regex:false}},
        {data:"mode",name:"mode",searchable:true,orderable:true,search:{value:"",regex:false}},
        {data:"last_active",name:"last_active",searchable:true,orderable:true,search:{value:"",regex:false}},
        {data:"email",name:"email",searchable:true,orderable:true,search:{value:"",regex:false}},
    ],
    data: [["Loading Data..."]],
    columns: [
	  {
        name: "cust_app_id",
        label: "ID",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            // See the console for a detailed look at this object.
        
            return value;
          }
        },
      },
      {
        name: "name",
        label: "Patient name",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            
            // Here you can render a more complex display.
            // You're given access to tableMeta, which has
            // the rowData (as well as the original object data).
            // See the console for a detailed look at this object.
        
            return value;
          }
        },
      },
      {
        name: "phone",
        label: "Moblie Number",
        options: {},
      },
      {
        name: "code",
        label: "Program code",
        options: {
            filter: false,
            sort:true,
            customBodyRender: (value, tableMeta, updateValue) => {
				
				return value;

        },
    }
      },
      {
        name: "status",
        label: "Status",
        options: {
            filter: false,
            sort:false,
            customBodyRender: (value, tableMeta, updateValue) => {
				
              return (<><span className="badge badge-Completed">Completed</span></>);
        },
      }
    },
	{
        name: "follow_up",
        label: "Follow-up Due",
        options: {
            filter: false,
            sort:false,
            customBodyRender: (value, tableMeta, updateValue) => {
  
               return value;
        },
      }
    },
    // {
    //     name: "hcp",
    //     label: "HCP",
    //     options: {
    //         filter: false,
    //         sort:false,
    //         customBodyRender: (value, tableMeta, updateValue) => {
  
    //            return "-";
    //     },
    //   }
    // },
    {
        name: "mode",
        label: "Mode",
        options: {
            filter: false,
            sort:false,
            customBodyRender: (value, tableMeta, updateValue) => {
  
               return value == 1  ? 'Online' : 'Offline';
        },
      }
    },{
        name: "last_active",
        label: "Last Active in App",
        options: {
            filter: false,
            sort:false,
            customBodyRender: (value, tableMeta, updateValue) => {
  
               return value;
        },
      }
    },{
        name: "email",
        label: "Email ID",
        options: {
            filter: false,
            sort:false,
            customBodyRender: (value, tableMeta, updateValue) => {
               return value;
        },
      }
    },
    {
      name: "cust_app_id",
      label: "Action",
      options: {
          filter: false,
          sort:false,
          customHeadRender: ({index, ...column}) =>{
            return (
              <TableCell key={index} style={columnStyleWithWidth}>
                  <div >{column.label} </div>   
              </TableCell>
            )
         },
          customBodyRender: (value, tableMeta, updateValue) => {
            let editurl=`/patient-profile/${value}`;
            return (<>{value>0 && (<Link to={editurl} className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
            <span className="svg-icon svg-icon-primary svg-icon-2x"><svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"/>
        <path d="M3,12 C3,12 5.45454545,6 12,6 C16.9090909,6 21,12 21,12 C21,12 16.9090909,18 12,18 C5.45454545,18 3,12 3,12 Z" fill="#000000" fillRule="nonzero" opacity="0.3"/>
        <path d="M12,15 C10.3431458,15 9,13.6568542 9,12 C9,10.3431458 10.3431458,9 12,9 C13.6568542,9 15,10.3431458 15,12 C15,13.6568542 13.6568542,15 12,15 Z" fill="#000000" opacity="0.3"/>
    </g>
</svg>
</span>
          </Link>)}
             {/* {value>0 && (<a href="#" class="btn btn-icon btn-light btn-hover-primary btn-sm">
<span class="svg-icon svg-icon-md svg-icon-primary">
<svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
<rect x="0" y="0" width="24" height="24"></rect>
<path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fill-rule="nonzero"></path>
<path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"></path>
</g>
</svg>
</span>
</a>)} */}

</>);
      }, 
    }
  }
    ],
    isLoading: true
  };
}
    /*state = { selectedRows: [], toggleCleared: false, data: data,filterText:'',resetPaginationToggle:false,
  optionFilter:{
    status:'',
    follow_up:'',
    mode:''
  }};*/
     filteredItems =() => {
       return this.state.data.filter(item => 
        ((item.patient_name && item.patient_name.toLowerCase().includes(this.state.filterText.toLowerCase()))
         || (item.mobileNumber && item.mobileNumber.includes(this.state.filterText))
          || (item.emailId && item.emailId.toLowerCase().includes(this.state.filterText.toLowerCase()))
          || (item.programCode && item.programCode.toLowerCase().includes(this.state.filterText.toLowerCase()))
       )&&((item.status && item.status.toLowerCase().includes(this.state.optionFilter.status.toLowerCase())))
       &&((item.followUp && item.followUp.toLowerCase().includes(this.state.optionFilter.follow_up.toLowerCase())))
       &&((item.mode && item.mode.toLowerCase().includes(this.state.optionFilter.mode.toLowerCase())))
           );
     }

    handleChange = state => {
      this.setState({ selectedRows: state.selectedRows });
    };
  
    handleRowClicked = row => {
      
      console.log(`${row.name} was clicked!`);
    }
  
    deleteAll = () => {
      const { selectedRows } = this.state;
      const rows = selectedRows.map(r => r.name);
      
      if (window.confirm(`Are you sure you want to delete:\r ${rows}?`)) {
        this.setState(state => ({ toggleCleared: !state.toggleCleared, data: differenceBy(state.data, state.selectedRows, 'name') }));
      }
    }
 handleActionClick=()=>{

 }
	 sort = (page, sortOrder) => {
	  let Index=this.state.serverSortingCol.findIndex(item=>item.name==sortOrder.name);
	  this.props.initGetPatients({start:this.state.start,length:this.state.rowsPerPage,columns:this.state.serverSortingCol,order: [{column: Index, dir:sortOrder.direction}]});
		 this.setState({ isLoading: true,
		order:[{column: Index, dir:sortOrder.direction}],
	  sortOrder:{...sortOrder}});
   
  }
  changePage = (page, sortOrder) => {
   
      this.props.initGetPatients({start:page*this.state.rowsPerPage,length:this.state.rowsPerPage,columns:this.state.serverSortingCol,order:this.state.order,search:this.state.search});
    this.setState({
      isLoading: true,
    });

  };
  searchText=(text)=>{
      let searchObj={value:text,regex:false};
      this.props.initGetPatients({start:0*this.state.rowsPerPage,length:this.state.rowsPerPage,columns:this.state.serverSortingCol,order:this.state.order,search:searchObj});
      this.setState({
        isLoading: true,
        search:searchObj
      });

  }
  
	componentDidMount() {
		//alert(this.state.rowsPerPage);
		this.props.initGetPatients({start:this.state.start,length:this.state.rowsPerPage,columns:this.state.serverSortingCol,order:this.state.order});
	}
	componentDidUpdate(prevProps,prevState){
		const {patientList}=this.props;
      if(patientList.data !=prevProps.patientList.data){
        this.setState({ 
            data: patientList.data, 
           page: patientList.page, 
           isLoading: false, 
            count: patientList.totalRows,
          });     
    }
  }
  
  render() {

    const { data, page, count, isLoading, rowsPerPage, sortOrder } = this.state;

    const options = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'vertical',
      serverSide: true,
      count: count,
      rowsPerPage: rowsPerPage,
      rowsPerPageOptions: [],
      sortOrder: sortOrder,
      customToolbar: () => {
        return <FilterComponent onStatus={ this.changeTextHandler} optionFilter={this.state.optionFilter} onFilter={ (event) => this.changeTextHandler(event,1)} onClear={(event)=>this.handleClear(event)} filterText={this.state.filterText} />;
      },
      onTableChange: (action, tableState) => {
     
        // a developer could react to change on an action basis or
        // examine the state as a whole and do whatever they want

        switch (action) {
          case 'changePage':
            this.changePage(tableState.page, tableState.sortOrder);
            break;
          case 'sort':
            this.sort(tableState.page, tableState.sortOrder);
            break;
          case 'search':
              this.searchText(tableState.searchText);
              break;  
          default:
            console.log('action not handled.');
        }
      }
    };

    

    return (
      <>
		<HeaderBreadcrumb breadcrumb={breadcrumb} title="Patient List" />
		<div className="row ">
		<div className="col-md-12">
      
        <MUIDataTable title={<Typography variant="h6">
          Patient List | Total {this.state.count}
          {isLoading && <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} />}
          </Typography>
          } data={data} columns={this.state.columns} options={options} />
      	</div>
    </div>
    
      </>
    );

  }

   handleClear = () => {
    if (this.state.filterText) {
      this.setState({filterText:''}) 
   //  this.setState({resetPaginationToggle:!resetPaginationToggle}) ;

  };
}
changeTextHandler=(e,type)=>{
  if(type==1){
  this.setState({filterText:e.target.value});
  }else if(type==2){
    let optionF={...this.state.optionFilter}
    optionF.status=e.target.value;
    this.setState({optionFilter:optionF});

  }else if(type==3){
    let optionF={...this.state.optionFilter}
    optionF.follow_up=e.target.value;
    this.setState({optionFilter:optionF});

  }else{
    let optionF={...this.state.optionFilter}
    optionF.mode=e.target.value;
    this.setState({optionFilter:optionF});
  }
};
   subHeaderComponentMemo = () => {
  

    return <FilterComponent onStatus={ this.changeTextHandler} optionFilter={this.state.optionFilter} onFilter={ (event) => this.changeTextHandler(event,1)} onClear={(event)=>this.handleClear(event)} filterText={this.state.filterText} />;
  };


}

 const FilterComponent = props1 => (
  <>
  <div className="fields-container">
  <select className="form-control" name="mode" style={{width:'150px',marginRight:'5px'}} 
  
  onChange={ (event) =>props1.onStatus(event,4)}    value={props1.optionFilter.mode}
  >
  
  {MODE.map((item,index)=>(
            <option key={index} value={item.value}>{item.name}</option>
      ))}
      </select>
  <select className="form-control" name="follow_up" style={{width:'150px',marginRight:'5px'}} 
  
  onChange={ (event) =>props1.onStatus(event,3)}    value={props1.optionFilter.follow_up}
  >
  
  {FOLLOW_UP_OPTION.map((item,index)=>(
            <option key={index} value={item.value}>{item.name}</option>
      ))}
      </select>
    <select className="form-control" name="status" onChange={ (event) =>props1.onStatus(event,2)}   value={props1.optionFilter.status} style={{width:'150px',marginRight:'5px'}} >
      {STATUS.map((item,index)=>(
            <option key={index} value={item.value}>{item.name}</option>
      ))}
      </select>
	  </div>
  </>
);



const mapStateToProps= state=>{
    return {
       patientList:state.patientListing
    }
   }
   const mapDispatchToProps = dispatch => {
     return {
        initGetPatients: data => dispatch(actionsMethods.initGetPatients(data))
 
   }
   }
   export default connect(mapStateToProps, mapDispatchToProps)(ListPatient);