/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { connect } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";

class UserProfile extends React.Component {
  render() {
    const { user, showHi, showAvatar, showBadge } = this.props;

    return (
      <>
      <div className="dropdown d-flex align-items-center">
      <div className="topbar-item" data-toggle="dropdown" data-offset="0px,0px" aria-expanded="false">
        <div className="btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto">
        {showHi && (<><span className="text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
          <span className="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4">{user.name}</span>
          </>)}
          <span className="symbol symbol-35">
          {showAvatar && <img alt="Pic" src={user.pic} />}
          {showBadge && (
               <span className="symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30 kt-header__topbar-icon">
                {/* TODO: Should get from currentUser */}
                {user && user.name.substring(0,1)}
                </span>
            )}
          </span>
        </div>
      </div>
      <div className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0" >
        <div className="d-flex align-items-center p-8 rounded-top">
          <div className="symbol symbol-md bg-primary mr-3 flex-shrink-0">
          {showAvatar && <img alt="Pic" src={user.pic} />}
          {showBadge && (
               <span className="symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30 kt-header__topbar-icon">
                {/* TODO: Should get from currentUser */}
                {user && user.name.substring(0,1)}
                </span>
            )}
          </div>
        
          <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">{user.name}</div>
         
        </div>
        <div className="separator separator-solid"></div>
        
        <div className="navi navi-spacer-x-0 pt-0">
         
        
        
     
          <div className="navi-footer px-8 py-5">
          <Link
                to="/logout"  className="btn btn-light-primary font-weight-bold">Sign Out</Link>
          </div>
        </div>
      </div>
    </div>  </>  );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps)(UserProfile);
