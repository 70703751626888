import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
     isSubmitting:false,
     isSubmitSuccess:false,
     call_id:0
};


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
            case actionTypes.CREATE_CALL_SCHEDULE_REQUEST:
                return updateObject( state, {
                    isSubmitting:true,
                    isSubmitSuccess:false
                    });

            case actionTypes.CREATE_CALL_SCHEDULE_SUCCESS:
                return updateObject( state, {
                    isSubmitting:false,
                    isSubmitSuccess:true
                    });
            case actionTypes.CREATE_CALL_SCHEDULE_FAILED:
                return updateObject( state, {
                    isSubmitting:false,
                    isSubmitSuccess:false
                    });
            case actionTypes.CHECK_CALL_SCHEDULE_REQUEST:
            case actionTypes.CHECK_CALL_SCHEDULE_FAILED:
                return updateObject(state,{
                    call_id:0
                });
            case actionTypes.CHECK_CALL_SCHEDULE_SUCCESS:
                return updateObject(state,{
                    call_id:action.data.call_id
                }); 


        default: 
            return state;
    }
};

export default reducer;
