import { delay } from 'redux-saga';
import { put, call } from 'redux-saga/effects';
import axios from 'axios';
import * as actions from '../actions';


export function* initDoctorsListSaga(action) {
    try {
      const response = yield axios.get(process.env.REACT_APP_API_URL+'doctors');
      console.log("Doctor Saga call");
      console.log(response.data);
      yield put(actions.doctorsListSuccess(response.data));
    } catch (error) {
      if(error.response.status==401){
        yield put(actions.logout());
        }
    }
  }