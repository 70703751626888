import * as actionTypes from './actionTypes';


export const sendDocSmsRequest=data=>{
    return {
      type: actionTypes.SEND_DOC_SMS_REQUEST,
      data:data
    }
  };

  export const sendDocSmsSuccess=data=>{
    return {
      type: actionTypes.SEND_DOC_SMS_SUCCESS,
      data:data
    }
  };

  export const sendDocSmsFailed=data=>{
    return {
      type: actionTypes.SEND_DOC_SMS_FAILED,
      data:data
    }
  };