import React from "react";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
//import Login from "./Login";
import Login1 from "./Login1";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
//import OtpScreen from "./Otpscreen";
const textColor={
  color: '#282a3c'
}
const BACKGROUND=[
  '/media/bg/background-casweb-01.jpg',
  '/media/bg/background-casweb-02.jpg',
  '/media/bg/background-casweb-03.jpg',
]
function shuffle() {
 let a=[...BACKGROUND];
  for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
  }
  return a[0];
}
export default function AuthPage() {
  console.log("authPage");
  return (
      <>
        <div className="kt-grid kt-grid--ver kt-grid--root">
          <div
              id="kt_login"
              className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
          >
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
              <div
                  className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
                  style={{
                    backgroundImage: `url(${toAbsoluteUrl(shuffle())})`
                  }}
              >
                <div className="kt-grid__item">
                  <Link to="/" className="kt-login__logo">
                    <img
                        alt="Logo"
                        src={toAbsoluteUrl("/media/logos/logo.png")}
                        style={{width:'150px'}}
                    />
                  </Link>
                </div>
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                  {/* <div className="kt-grid__item kt-grid__item--middle">
                    <h3 className="kt-login__title" style={textColor}>Welcome to HCAH</h3>
                    <h4 className="kt-login__subtitle" style={textColor}>
                    HealthCare atHOME (HCAH), is a leading home healthcare service provider in India.
                    </h4>
                  </div> */}
                </div>
                <div className="kt-grid__item">
                  <div className="kt-login__info">
                    <div className="kt-login__copyright" style={textColor}>
                      &copy; 2020 HealthCare at HOME
                    </div>
                    {/* <div className="kt-login__menu">
                      <Link to="/terms" className="kt-link">
                        Privacy
                      </Link>
                      <Link to="/terms" className="kt-link">
                        Legal
                      </Link>
                      <Link to="/terms" className="kt-link">
                        Contact
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
                <Switch>
                  <Route path="/auth/login" component={Login1} />
                 {/* <Route path="/auth/otp" component={OtpScreen} />*/}
                  {/* <Route path="/auth/registration" component={Registration} /> */}
                  {/* <Route
                      path="/auth/forgot-password"
                      component={ForgotPassword}
                  /> */}
                  <Redirect from="/auth" exact={true} to="/auth/login" />
                  <Redirect to="/auth/login" />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </>
  );
}
