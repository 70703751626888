import { takeEvery, all, takeLatest,take } from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import { 
  initCreatePatientSaga,
  initGetPatientSaga,
  getProgramCodeSaga,
  getProgramCodeDetailSaga,
  patientSendOtpSaga,
  patientOtpVerifySaga,
  getUserModeSaga,
 initProfileCompleteStepSaga} from './patient';

import {
  initGetQuestionTypeSaga,
  initCreateQuestionSaga,
  initGetAllQuestionSaga,
  initGetConnectedQuestionSaga,
  initGetQuestionPropertiesSaga,
  initEditQuestionSaga} from './question';

import {initDoctorsListSaga} from './doctors';
import {initGetTaskTypeSaga,
  initCreateTaskSaga,
  initGetAllTasksSaga,
  initEditTaskSaga} from './tasks';

import {initGetQuestionTaskSaga,
  initCreateModuleSaga,
  initGetSearchModuleSaga,
  initGetAllModulesSaga,
  initEditModuleSaga} from './modules';

import {
  initCreateCallScheduleSaga,
  initGetAllCallsSaga,
  initEditCallScheduleSaga,
  checkCallScheduleSaga
 } from './callSchedule';

import {
  initGetPatientProfileDetailSaga,
  initGetAllKeyParametersSaga,
  initGetPatientModulesSaga,
} from './patientProfile';

import {initDashboardDetailSaga} from './counsellorDashboard';
import {initAssignDietChartSaga} from './assignDietChart';
import {initSendDocSmsSaga} from './sendDocSms';
import  {initCareTakerRelationSaga} from './careTakerRelation';

import {initEditPatientProfileSaga} from './editPatientProfile';
import { 
  getStateSaga,
  getCitySaga
} from './common';

import {
  initNavUserMenuSaga
} from './navUserMenu';
import {
getPatientCallScheduleSaga,
getPatientCallTaskQuestionSaga,
savePatientCallTaskQuestionSaga
} from './patientCallSchedule';

export function* watchAllSaga() {
    yield takeLatest(actionTypes.INIT_CREATE_PATIENT, initCreatePatientSaga);
    yield takeLatest(actionTypes.INIT_GET_PATIENTS, initGetPatientSaga);
    yield takeLatest(actionTypes.GET_PROGRAM_CODES, getProgramCodeSaga);
    yield takeLatest(actionTypes.GET_PROGRAM_CODE_DETAIL, getProgramCodeDetailSaga);
    yield takeLatest(actionTypes.DOCTORS_LIST_REQUEST, initDoctorsListSaga);
    yield takeLatest(actionTypes.PATIENT_SEND_OTP_REQUEST,patientSendOtpSaga);
    yield takeLatest(actionTypes.PATIENT_OTP_VERIFY_REQUEST,patientOtpVerifySaga);
    yield takeLatest(actionTypes.GET_QUESTION_TYPE_REQUEST,initGetQuestionTypeSaga);
    yield takeLatest(actionTypes.CREATE_QUESTION_REQUEST,initCreateQuestionSaga);
    yield takeLatest(actionTypes.GET_ALL_QUESTION_REQUEST,initGetAllQuestionSaga);
    yield takeLatest(actionTypes.GET_CONNECTED_QUESTION_REQUEST,initGetConnectedQuestionSaga);

    yield takeLatest(actionTypes.GET_TASK_TYPE_REQUEST,initGetTaskTypeSaga);
    yield takeLatest(actionTypes.CREATE_TASK_REQUEST,initCreateTaskSaga);

    yield takeLatest(actionTypes.GET_QUESTION_TASK_REQUEST,initGetQuestionTaskSaga);
    yield takeLatest(actionTypes.CREATE_MODULE_REQUEST,initCreateModuleSaga);
    yield takeLatest(actionTypes.GET_SEARCH_MODULE_REQUEST,initGetSearchModuleSaga);

    yield takeLatest(actionTypes.CREATE_CALL_SCHEDULE_REQUEST,initCreateCallScheduleSaga);
    yield takeLatest(actionTypes.GET_ALL_TASKS_REQUEST,initGetAllTasksSaga);
    yield takeLatest(actionTypes.GET_QUESTION_PROPERTIES_REQUEST,initGetQuestionPropertiesSaga);
	
  yield takeLatest(actionTypes.GET_ALL_MODULES_REQUEST,initGetAllModulesSaga);
  yield takeLatest(actionTypes.EDIT_TASK_REQUEST,initEditTaskSaga);
  yield takeLatest(actionTypes.EDIT_QUESTION_REQUEST,initEditQuestionSaga);
	yield takeLatest(actionTypes.GET_ALL_MODULES_REQUEST,initGetAllModulesSaga);
  yield takeLatest(actionTypes.GET_ALL_CALLS_REQUEST,initGetAllCallsSaga);
  yield takeLatest(actionTypes.EDIT_CALL_SCHEDULE_REQUEST,initEditCallScheduleSaga);
  yield takeLatest(actionTypes.EDIT_MODULE_REQUEST,initEditModuleSaga);

  yield takeLatest(actionTypes.GET_PATIENT_PROFILE_DETAIL_REQUEST,initGetPatientProfileDetailSaga);
  yield takeLatest(actionTypes.CHECK_PROGRAM_MODULES_REQUEST,initGetPatientModulesSaga);
  yield takeLatest(actionTypes.GET_KEY_PARAMETERS_REQUEST,initGetAllKeyParametersSaga);

  yield takeLatest(actionTypes.COUNSELLOR_DASHBOARD_DETAIL_REQUEST,initDashboardDetailSaga);
  yield takeLatest(actionTypes.GET_USER_MODE_REQUEST,getUserModeSaga);
  yield takeLatest(actionTypes.ASSIGN_DIET_CHART_REQUEST,initAssignDietChartSaga);
  yield takeLatest(actionTypes.SEND_DOC_SMS_REQUEST,initSendDocSmsSaga);

  yield takeLatest(actionTypes.CARE_TAKER_RELATION_REQUEST,initCareTakerRelationSaga);
  yield takeLatest(actionTypes.PATIENT_PROFILE_COMPLETE_STEP_REQUEST,initProfileCompleteStepSaga);

  yield takeLatest(actionTypes.GET_STATE_REQUEST,getStateSaga);
  yield takeLatest(actionTypes.GET_CITY_REQUEST,getCitySaga);
  yield takeLatest(actionTypes.CHECK_CALL_SCHEDULE_REQUEST,checkCallScheduleSaga);

  yield takeLatest(actionTypes.EDIT_PATIENT_PROFILE_REQUEST,initEditPatientProfileSaga);
  yield takeLatest(actionTypes.NAV_USER_MENU_REQUEST,initNavUserMenuSaga);
  yield takeLatest(actionTypes.PATIENT_CALL_SCHEDULE_REQUEST,getPatientCallScheduleSaga);
  yield takeLatest(actionTypes.PATIENT_CALL_TASK_QUESTION_REQUEST,getPatientCallTaskQuestionSaga);
  yield takeLatest(actionTypes.PATIENT_SAVE_CALL_TASK_QUESTION_REQUEST,savePatientCallTaskQuestionSaga);
} 