import React from 'react';
import Input from '../../../../components/UI/Input';
import CModal from '../../../../components/UI/Modal';
import ModuleSection from '../../../../components/UI/ModuleSection';
import { updateObject,checkValidity,checkValidityType } from '../../../../shared/utility';
import {module,section} from './Fields';
import '../teleCallCreate.css';
import { connect } from 'react-redux';
import * as actionsMethods from '../../../../store/actions/index';
import * as Constants from '../../../../shared/constants';
import clsx from "clsx";
import { Redirect } from 'react-router'
import { ReactSortable, Sortable, MultiDrag, Swap } from "react-sortablejs";
Sortable.mount(new MultiDrag(), new Swap());

class EditModule extends React.Component {
    constructor(props){
        super(props);
        this.state={
            openModal:false,
            isSubmitting:false,
            loadingButtonStyle:{
                paddingRight: "1rem"
              },
              loading:false,
            module:module,
            section:[],
            formisValid:false,
            moduleFormIsValid:false,
            sectionFormIsValid:false,
            questionTaskObj:[],
            redirect: false
        };
    }  
    resetForm=async()=>{
        await  this.setState({
            isSubmitting:false,
            loadingButtonStyle:{
                paddingRight: "1rem"
              },
              loading:false,
            module:module,
            section:[],
            formisValid:false,
            moduleFormIsValid:false,
            sectionFormIsValid:false,
            questionTaskObj:[],
            redirect: false
        });
        this.props.getQuestionTask();

 
     }  
   async componentDidMount(){
       await this.props.getQuestionTask();
        this.props.editModule(this.props.match.params.id);

    }
    componentDidUpdate(prevProps,prevState){
        const {questionTask,isSubmitting,editModuleData}=this.props;
        if(questionTask !=prevProps.questionTask){
            this.setState({questionTaskObj:questionTask.options});
        }
        if(isSubmitting !=prevProps.isSubmitting && isSubmitting==false){
            this.setState({
              loading:false,
              loadingButtonStyle: {paddingRight: "1rem"},
              isSubmitting:false
            });
           
        }
        if(editModuleData !=prevProps.editModuleData){
          if(editModuleData.redirect==true){
            this.setState({redirect:true});
          }
          this.updateInitData(editModuleData);
        }
    }
    updateInitData=async editModuleData =>{
    
      let updatemoduleName = updateObject(this.state.module['name'], {
        value:editModuleData.name,
        valid:true
    });
   
    const updatedModuleFields = updateObject(this.state.module, {
      ['name']: updatemoduleName
    });
    for(let i=0;i<editModuleData.sections.length;i++){
    await this.initAddSection(editModuleData.sections[i]);
    }
    this.setState({module:updatedModuleFields, moduleFormIsValid:true,
      sectionFormIsValid:true});
  };

  initAddSection=async item=>{
    let index=this.state.section.length+1;
    let usection=[...this.state.section];
    usection.push({id:index,data:section(this.state.questionTaskObj,item)});
    this.setState({section:usection});
}
    inputChangedHandler = (event, inputIdentifier,value) => {
        
        this.commonChangedHandler(event, inputIdentifier,value,'module');

      }

      selectChangedHandler = (event, inputIdentifier,value,arrayIndex) => {
      
        this.commonChangedHandler1(event, inputIdentifier,value,'section',arrayIndex);

      }
    
      commonChangedHandler1 = (event, inputIdentifier,value,stateName,arrayIndex) => {
        let valueObj=[];
        let   actualValue;
         if(inputIdentifier=='question_task'){
            if(value==null) {return ;}
            valueObj=this.state[stateName][arrayIndex]['data'][inputIdentifier].valueObj;
            actualValue=this.state[stateName][arrayIndex]['data'][inputIdentifier].value;
            let isTQe=valueObj.filter(ita=>ita.value==value.value);
            if(isTQe.length>0){
              this.props.showSnackBarNotification({
                message:'This Question/Task already added',
                type:Constants.notificationType.ERROR,
                open:true
              });
            }else{
              valueObj.push(value);
              actualValue.push({id:value.id,value:value.type});
            }
         }else{
   actualValue=value==undefined?event.target.value:value.value;
       actualValue= actualValue==undefined?event.target.checked:actualValue;
         }  
             const updatedFormElement = updateObject(this.state[stateName][arrayIndex]['data'][inputIdentifier], {
                 value: actualValue,
                     valid: checkValidity(actualValue, this.state[stateName][arrayIndex]['data'][inputIdentifier].validation),
                     touched: true,
                     error:checkValidityType(actualValue, this.state[stateName][arrayIndex]['data'][inputIdentifier].validation)==''?'':this.state[stateName][arrayIndex]['data'][inputIdentifier].errorMessage[checkValidityType(actualValue, this.state[stateName][arrayIndex]['data'][inputIdentifier].validation)],
               });
               const updatedForm1 = updateObject(this.state[stateName][arrayIndex]['data'], {
             [inputIdentifier]: updatedFormElement 
               });
               const updatedForm2 = updateObject(this.state[stateName][arrayIndex], {
                ['data']: updatedForm1 
      });
      const updatedForm3= updateObject(this.state[stateName], {
        [arrayIndex]: updatedForm2 
});
      let formisValid=true;
    //   for (let inputIdentifier in updatedForm3) {
    //       for(let objecttt in updatedForm3[inputIdentifier]){
    //       formisValid= updatedForm2[inputIdentifier][][objecttt].valid &&formisValid ;
    //       }
    //   }
      let Vname=`${stateName}FormIsValid`;
          this.setState({[stateName]: Object.values(updatedForm3),[Vname]: formisValid});
         }

      commonChangedHandler = (event, inputIdentifier,value,stateName) => {
        
   let actualValue=value==undefined?event.target.value:value.value;
        actualValue= actualValue==undefined?event.target.checked:actualValue;
         
        const updatedFormElement = updateObject(this.state[stateName][inputIdentifier], {
            value: actualValue,
                valid: checkValidity(actualValue, this.state[stateName][inputIdentifier].validation),
                touched: true,
                error:checkValidityType(actualValue, this.state[stateName][inputIdentifier].validation)==''?'':this.state[stateName][inputIdentifier].errorMessage[checkValidityType(actualValue, this.state[stateName][inputIdentifier].validation)],
          });
          const updatedOrderForm = updateObject(this.state[stateName], {
              [inputIdentifier]: updatedFormElement
          });
          let formisValid=true;
          for (let inputIdentifier in updatedOrderForm) {
              formisValid= updatedOrderForm[inputIdentifier].valid &&formisValid ;
          }
          let Vname=`${stateName}FormIsValid`;
          this.setState({[stateName]: updatedOrderForm,[Vname]: formisValid});
    }
   arrayConvertion=(data)=>{
       let dataArray=[];
    for (let key in data) {
        dataArray.push({
            id: key,
            config: data[key]
        });
    }
       return dataArray;
   }

   addSection=()=>{
    let index=this.state.section.length+1;
    let usection=[...this.state.section];
    usection.push({id:index,data:section(this.state.questionTaskObj)});
    this.setState({section:usection});
}

removeSection=(event,i)=>{
let section=[...this.state['section']];
section.splice(i, 1);
if(section.length==0){
    this.setState({sectionFormIsValid:false});
}
    this.setState({section: section});

}

removeQuestionTask=(event,index,i)=>{
 
    let abc=[...this.state['section'][i]['data']['question_task']['valueObj']];
    let a=[...this.state['section'][i]['data']['question_task']['value']];
    abc.splice(index, 1);
    a.splice(index,1);

const updatedFormElement = updateObject(this.state['section'][i]['data']['question_task'], {
    value: a,
    valueObj:abc
  });
  const updatedForm1 = updateObject(this.state['section'][i]['data'], {
['question_task']: updatedFormElement 
  });
  const updatedForm3 = updateObject(this.state['section'][i], {
    ['data']: updatedForm1
      });
  const updatedForm2 = updateObject(this.state['section'], {
   [i]: updatedForm3 
});
this.setState({section: Object.values(updatedForm2)});

}
   
handleSubmit= (e)=>{
    e.preventDefault();
    if(!this.isFormValid()){
        this.props.showSnackBarNotification({
            message:'Please Fill required fields',
            type:Constants.notificationType.ERROR,
            open:true
          });
          return;
    }
 //   module:module,
 //   section:[],
    const formData = {};
    for (let formElementIdentifier in this.state.module) {
        formData[formElementIdentifier] = this.state.module[formElementIdentifier].value;
    }
    let sections=[]
    for (let formElementIdentifier in this.state.section) {
        let priority=parseInt(formElementIdentifier)+1;
        let question=[];
        let task=[];
        for(let i in this.state.section[formElementIdentifier]['data']['question_task'].value){
            if(this.state.section[formElementIdentifier]['data']['question_task']['value'][i].value=='Q'){
                question.push(this.state.section[formElementIdentifier]['data']['question_task']['value'][i].id);
            }else{
                task.push(this.state.section[formElementIdentifier]['data']['question_task']['value'][i].id); 
            }
        }
        sections.push({
            name:this.state.section[formElementIdentifier]['data'].task_name.value,
            priority:priority,
            question:question,
            task:task
        });
    }

    let updateObject={...formData,
        sections:sections,
        id:this.props.match.params.id
    };

      this.setState({loadingButtonStyle:{ paddingRight: "3.5rem" },loading:true,isSubmitting:true});
//         this.setState({isFormReset:true});
    this.props.createModule(updateObject);
}
isFormValid=()=>{
if(this.state.sectionFormIsValid && this.state.moduleFormIsValid){
     return true;
 }
return false;
}

 handleModalOpen = () => {
    this.setState({openModal:true});
  };

   handleModalClose = () => {
this.setState({openModal:false});
};
    render(){ 
      if (this.state.redirect) {
        return <Redirect to='/admin/modules-list'/>;
      }
        let     letSection=this.state.section;
const formElementsArray =this.arrayConvertion(this.state.module);


let form = (
    <form className="kt-form" id="kt_form" onSubmit={e=>this.handleSubmit(e)}>
        <div className="kt-wizard-v1__form row">
        {formElementsArray.map((formElement,index) => (
            <Input 
                key={index}
                isradio={formElement.config.isradio==1?1:0}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.isProgram==1?this.props.codes:formElement.config.elementConfig}
                col={formElement.config.col}
                label={formElement.config.label}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                fullRow={formElement.config.fullRow}
                heading={formElement.config.heading}
                divider={formElement.config.divider}
                leftText={formElement.config.leftText}
                error={formElement.config.error}
                changed={(event,value) => this.inputChangedHandler(event, formElement.id,value)}
              
                />
        ))}
      
      {this.state.section.length>0 && (<ReactSortable
        list={this.state.section}
        setList={newState => this.setState({ section: newState })}
        className="sortlist"
        ghostClass= 'blue-background-class'
    
      >
        {letSection.map((item,i)=>{
           const sectionArray =this.arrayConvertion(item.data);
        return (<div className="col-md-12 sort-div" key={item.id}>
           <div className="row innersecton">
               <div className="col-md-12">
                   <div className="right-side-section">
               <i className="fa fa-trash" aria-hidden="true" onClick={e=>this.removeSection(e,i)}></i>
               </div>
               </div>

        {sectionArray.map((formElement,index) => {
           return (
           <ModuleSection 
                key={index}
                isradio={formElement.config.isradio==1?1:0}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.isProgram==1?this.props.codes:formElement.config.elementConfig}
                col={formElement.config.col}
                label={formElement.config.label}
                value={formElement.config.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validation}
                touched={formElement.config.touched}
                fullRow={formElement.config.fullRow}
                heading={formElement.config.heading}
                divider={formElement.config.divider}
                leftText={formElement.config.leftText}
                error={formElement.config.error}
                changed={(event,value) => this.selectChangedHandler(event, formElement.id,value,i)}
                valueObj={formElement.config.valueObj}
                removeItemClicked={this.removeQuestionTask}
                optionIndex={i}
                />
                );
    })
}

</div>
</div> 
);
})}   
</ReactSortable>)
}
  <div className="form-group col-md-12">
          <div className="btn btn-primary btn-sm font-weight-bolder text-uppercase"  type="submit" onClick={e=>this.addSection()} >Add Section<i className="fa fa-plus-circle ml-1 p-0"></i></div>
         {this.state.section.length>1 && (<div className="btn btn-primary btn-sm font-weight-bolder text-uppercase float-right"  type="submit" onClick={e=>this.setState({openModal:true})} >Sort Section<i className="flaticon2-sort float-right"></i></div>)}
          </div>
        </div>
        <div className="kt-form__actions">
        <div className="flex-grow-1">
        </div>
      <div >      <button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={this.props.isSubmitting}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": this.state.loading
                      }
                    )}`}
                    style={this.state.loadingButtonStyle}
                  >
                      Update
                  </button>

                </div>
                </div>
    </form>
);

  return (
  <>

<h3 className="font-weight-bold text-dark">Edit Module</h3>
        <span className="text-muted font-weight-bold d-block mb-10">Edit a new Module</span>
        {form}   
        <CModal open={this.state.openModal} handleModalClose={e=>this.handleModalClose()} handleModalOpen={e=>this.handleModalOpen()}>
        <div className="modal-dialog modal-md">
        <div className="modal-content ">
      <div className="modal-header">
        <h5 className="modal-title">Rearrange Section</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e=>this.handleModalClose()}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body1">

           {this.state.section.length>0 && (<ReactSortable
        list={this.state.section}
        setList={newState => this.setState({ section: newState })}
        className="sortlist-2"
        ghostClass= 'blue-background-class'
    
      >
         
          {letSection.map((item,i)=>{
  return (<div className="list-group-item" key={item.id}>Section {item.id}
  <i className=" text-dark-50 flaticon2-sort float-right"></i>
  </div>);
          })
        }

</ReactSortable>)
}
</div>
<div className="modal-footer">
          <button type="button" className="btn btn-default" data-dismiss="modal"onClick={e=>this.handleModalClose()}>Done</button>
        </div>
           </div>
           
           </div>
        </CModal>
    </>
  );
}
}
const mapStateToProps= state=>{
    return {
      questionTask:state.moduleReducer.questionTask,
      isSubmitting:state.moduleReducer.isSubmitting,
      isSubmitSuccess:state.moduleReducer.isSubmitSuccess,
      editModuleData:state.editModuleReducer
    }
   }
   const mapDispatchToProps = dispatch => {
     return {
         getQuestionTask: () => dispatch(actionsMethods.getQuestionTaskRequest()),
         showSnackBarNotification:data=>dispatch(actionsMethods.showSnackBarNotification(data)),
         createModule:data=>dispatch(actionsMethods.createModuleRequest(data)),
         editModule:id=>dispatch(actionsMethods.editModuleRequest(id))
   }
   }
   export default connect(mapStateToProps, mapDispatchToProps)(EditModule);