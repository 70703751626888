export const TaskType={
  type_id: {
        elementType: 'select',
        label:'Task type',
        heading:'',
        error:'',
        elementConfig: {
            options: [
                {value: '', displayValue: 'Select Task Type'},
                {value: ' Check-box', displayValue: ' Check-box'},
                {value: 'file-upload', displayValue: 'File Upload'},
                {value: 'text-input', displayValue: 'Text Input'},
                {value: 'Image Selection', displayValue: 'Image Selection'},
                {value: 'date-time', displayValue: 'Date/Time'},
            ]
        },
        value: '',
        col:6,
        fullRow:6,
        validation: {
          required:true
        },
        errorMessage:{
         required:'Question Type is required'
        },
        valid: false,
        touched:false
      },
};




export function dynamicFields(type,name=''){
// if(type=='file-upload'){
// return  (
//  { 
//   name:{
//        elementType: 'input',
//        label:'Task',
//        col:6,
//        fullRow:6,
//        error:'',
//        divider:0,
//        heading:'',
//        elementConfig: {
//            type: 'text',
//            placeholder: 'Type task Here...',
//        },
//        value: '',
//        validation: {
//            required: true,
//        },
//        errorMessage:{
//          required: 'Task Feild is required',
//        },
//        valid: false,
//        touched: false
//    },
//    max_file: {
//        elementType: 'input',
//        label:'Maximun number of file allowed',
//        col:4,
//        fullRow:0,
//        error:'',
//        divider:0,
//        heading:'',
//        elementConfig: {
//            type: 'number',
//        },
//        value: '1',
//        validation: {
//        },
//        errorMessage:{
//        },
//        valid: true,
//        touched: false
//      },
//      min_size: {
//       elementType: 'input',
//       label:'Minimum file size (in MB)',
//       col:2,
//       fullRow:0,
//       error:'',
//       divider:0,
//       heading:'',
//       elementConfig: {
//           type: 'number',
//       },
//       value: '1',
//       validation: {
//       },
//       errorMessage:{
//       },
//       valid: true,
//       touched: false
//     },
//     max_size: {
//       elementType: 'input',
//       label:'Maximun file size (in MB)',
//       col:2,
//       fullRow:4,
//       error:'',
//       divider:0,
//       heading:'',
//       elementConfig: {
//           type: 'number',
//       },
//       value: '1',
//       validation: {
//       },
//       errorMessage:{
//       },
//       valid: true,
//       touched: false
//     },
//     file_format: {
//       elementType: 'multiple_autocomplete',
//       label:'File Format',
//       heading:'',
//       error:'',
//       elementConfig: {
//           options: [
//               {value: '', displayValue: 'Select File Format'},
//               {value: 'jpg', displayValue: 'jpg'},
//               {value: 'png', displayValue: 'Png'},
//               {value: 'Pdf', displayValue: 'Pdf'},
//               {value: 'docx', displayValue: 'Docs'}
//             ]
//       },
//       value: ['jpg'],
//       valueObj:[ {value: 'jpg', displayValue: 'jpg'}],
//       col:8,
//       fullRow:4,
//       validation: {
//         required:true
//       },
//       errorMessage:{
//        required:'File Format field is required'
//       },
//       valid: false,
//       touched:false
//     }
// }
// );
// }else{
return (
     { 
      name:{
          elementType: 'input',
          label:'Task',
          col:6,
          fullRow:6,
          error:'',
          divider:0,
          heading:'',
          elementConfig: {
              type: 'text',
              placeholder: 'Enter task Action Here...',
          },
          value: name,
          validation: {
              required: true,
          },
          errorMessage:{
            required: 'Task Action Feild is required',
          },
          valid: name==''?false:true,
          touched: false
      },
      // connected_task: {
      //     elementType: 'mcheckbox',
      //     label:'Connected Tasks Exists',
      //     col:12,
      //     fullRow:0,
      //     error:'',
      //     divider:0,
      //     heading:'',
      //     elementConfig: {
      //         type: 'checkbox',
      //     },
      //     value: '',
      //     validation: {
      //     },
      //     errorMessage:{
      //     },
      //     valid: true,
      //     touched: false
      //   }

  }
  );
// }
};



export const otherFields={
  mandatory: {
      elementType: 'switch',
      label:'',
      col:6,
      fullRow:0,
      heading:'',
      error:'',
      elementConfig: {
          type: 'checkbox',
      },
      value: 0,
      leftText:'Mandatory',
      validation: {
         // required: true
      },
      errorMessage:{
       
      },
      valid: true,
      //touched: false
    }   
}

