import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
     questionTask:{
        options:[]
     },
     isSubmitting:false,
     isSubmitSuccess:false,
     searchModule:{
         options:[]
     }
};
const getQuestionTaskSuccess = (state, action) => {
    let data=[];
     if(action.data.question.length>0){
        for(let i=0;i<action.data.question.length;i++){
         data.push({value:'Q-'+action.data.question[i].id,displayValue:action.data.question[i].name,id:action.data.question[i].id,type:'Q'});
         }
     }
    if(action.data.task.length>0){
        for(let i=0;i<action.data.task.length;i++){
        data.push({value:'T-'+action.data.task[i].id,displayValue:action.data.task[i].name,id:action.data.task[i].id,type:'T'});
        }
    }
   
 let abc=updateObject( state, {
    questionTask:{options:data}
    });
   return abc;
};

const getSearchModuleSuccess =(state,action)=>{
    let data=[];
    if(action.data.length>0){
       for(let i=0;i<action.data.length;i++){
        data.push({value:action.data[i].id,displayValue:action.data[i].name});
        }
    }
   return updateObject( state, {
          searchModule:{options:data}
    });
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_QUESTION_TASK_SUCCESS: 
            return getQuestionTaskSuccess(state,action);
        
            case actionTypes.CREATE_MODULE_REQUEST:
                return updateObject( state, {
                    isSubmitting:true,
                    isSubmitSuccess:false
                    });

        case actionTypes.CREATE_MODULE_SUCCESS:
            return updateObject( state, {
                isSubmitting:false,
                isSubmitSuccess:true
                });
        case actionTypes.CREATE_MODULE_FAILED:
            return updateObject( state, {
                isSubmitting:false,
                isSubmitSuccess:false
                });

        case actionTypes.GET_SEARCH_MODULE_SUCCESS:
            return getSearchModuleSuccess(state,action);


        default: 
            return state;
    }
};

export default reducer;
