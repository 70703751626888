import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid'
import ReactDOM from 'react-dom';
import { Collapse,Button } from 'react-bootstrap';
import './patientTrackingDetail.scss';
import './patientTrackingDetail.scss' // webpack must be configured to do this
import Tracking from './tracking';
import HeaderBreadcrumb from '../../../components/UI/HeaderBreadcrumb';
const breadcrumb=[
  {
    title: "patient list",
    page: "patient"
  }, {
    title: "patient profile",
    page: "patient-profile/1"
  }, {
    title: "patient tracking",
    page: "patient-tracking-detail"
  }];


const EVENT=[
  {
    id:3,
    title: 'Activity',
    start: '2020-06-06',
    description: '30 minutes a day',
    className: "fc-event-danger fc-event-solid-warning",
    icon:'flaticon2-drop',
    type:3,
    per:70
},
    {
        id:1,
        title: 'Water',
        start: '2020-06-03',
        description: '6 cups of day',
        className: "fc-event-danger fc-event-solid-warning",
        icon:'flaticon2-drop',
        per:80,
        type:1,
        
    },
  
    {
      id:5,
      title: 'Water',
      start: '2020-06-06',
      description: '6 cups of day',
      className: "fc-event-danger fc-event-solid-warning",
      icon:'flaticon2-drop',
      per:40,
      type:1,
      
  },
  {
      id:2,
      title: 'Fruits',
      start: '2020-06-03',
      description: '300 grams of day', 
      className: "fc-event-danger fc-event-solid-warning",
      icon:' fab fa-accessible-icon  ',
      per:50,
      type:2
  },  
{
  id:4,
  title: 'Sleep',
  start: '2020-06-05',
  description: '8 hours a day',
  className: "fc-event-danger fc-event-solid-warning",
  icon:'fab fa-accessible-icon',
  per:60,
  type:4
}
   
];
export default class PatientTrackingDetail extends React.Component {
    calendarComponentRef = React.createRef()
    
    constructor(props, context) {
      super(props, context);
  
   
  
      this.state = {
        calendarWeekends: true,
        calendarEvents: EVENT,
        show:true,
        modalData:{
          title:'',
          description:''
        }
      }
    }
  toggleWeekends = () => {
    this.setState({ // update a property
      calendarWeekends: !this.state.calendarWeekends
    })
  }

  gotoPast = () => {
    let calendarApi = this.calendarComponentRef.current.getApi()
    calendarApi.gotoDate('2000-01-01') // call a method on the Calendar object
  }

  handleDateClick = (arg) => {
    // if (confirm('Would you like to add an event to ' + arg.dateStr + ' ?')) {
      this.setState({  // add new event data
        calendarEvents: this.state.calendarEvents.concat({ // creates a new array
          title: 'New Event',
          start: arg.date,
          allDay: arg.allDay
        })
      })
   // }
  }

  render() {
    return (
      <>
       <HeaderBreadcrumb breadcrumb={breadcrumb} title="Patient Tracking" />

        <div className="row">
            <div className="col-md-10 offset-md-1 mb-5 mt-3">
        <div className="card card-custom">
									<div className="card-header">
										<div className="card-title">
											<h3 className="card-label">Calendar List View</h3>
										</div>
										<div className="card-toolbar">
											<a href="#" className="btn btn-light-primary font-weight-bold">
											<i className="fa fa-plus icon-md mr-2"></i>Add Event</a>
										</div>
									</div>
									<div className="card-body">
                            <FullCalendar defaultView="listWeek"
            header={{
              left: 'prev,next today',
              center: 'title',
            //  right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
            right: 'dayGridMonth,timeGridWeek,listWeek'
            }}
            plugins={[ dayGridPlugin, timeGridPlugin, interactionPlugin,listPlugin ]}
            ref={ this.calendarComponentRef }
            weekends={ this.state.calendarWeekends }
            events={ this.state.calendarEvents }
            dateClick={ this.handleDateClick }
            themeSystem="bootstrap"
             eventRender={this.EventDetail}  
            //eventClick={this.handleEventClick}

            />
									</div>
								</div>
     
      </div>
      </div>
  

      </>
    )
  }
  EventDetail = ({ event, el,view }) => {
    console.log(event.allDay);
    if(view.type=="listWeek"){
     
    const content = (  
<div className="card">
    <div className="card-header" onClick={(event1) => {
       var x=document.getElementById("activity-body-"+event.id);
        if (x.style.display === "none") {
          x.style.display = "block";
          document.getElementById("icon-"+event.id).className="far fa-eye";
        } else {
          x.style.display = "none";
          document.getElementById("icon-"+event.id).className="far fa-eye-slash";

        }}
      } >
      <h5 className="mb-0">
    <span className="badge badge-success"> 
    {event.allDay==true?'All Day':`${event.start.getHours()}:${event.start.getMinutes()+1}`}</span>
    {event.title}
     <i className="far fa-eye-slash" id={`icon-${event.id}`} style={{float:'right'}}></i>
    
      </h5>
    </div>

    <div  id={`activity-body-${event.id}`} className="collapse show collapse-body" style={{display:'none'}} aria-labelledby="headingOne" data-parent="#accordion">
      <div className="card-body">
      <h4>Carb (<span data-carb-range="78.83-85.99" data-carb-color="Green" className="n_carb">37.8175</span>) | Protein (<span data-protein-range="14.33-28.66" data-protein-color="Amber" className="n_pro">9.78563</span>) | Fat (<span data-fat-range="12.74-15.92" data-fat-color="Green" className="n_fat">9.015</span>) | Fiber (<span data-fiber-range="8.03-12.08" data-fiber-color="Red" className="n_fiber">3.36</span>)</h4>
      <p data-diet-id="7" data-diet-date="2017-04-17" data-food-id="886" data-food-name="Raita - Vegetable" data-measurement-id="5" data-measurement-name="Katori (200 gms)" data-food-qty="1"> Raita - Vegetable &nbsp;&nbsp;&nbsp;&nbsp; 1 &nbsp;&nbsp; Katori (200 gms)  
                                                        
                                                </p> <br/>
      <p data-diet-id="7" data-diet-date="2017-04-17" data-food-id="913" data-food-name="Chapati" data-measurement-id="173" data-measurement-name="Small (30 gms)" data-food-qty="2"> Chapati &nbsp;&nbsp;&nbsp;&nbsp; 2 &nbsp;&nbsp; Small (30 gms)  
                                                        
                                                </p> <br />
      <p data-diet-id="7" data-diet-date="2017-04-17" data-food-id="71" data-food-name="Bharwan Karela" data-measurement-id="4" data-measurement-name="grams" data-food-qty="0.25"> Bharwan Karela &nbsp;&nbsp;&nbsp;&nbsp; 0.25 &nbsp;&nbsp; grams  
                                                        
                                                </p>
      </div>
    </div>
  </div>  
  );
    ReactDOM.render(<Tracking events={event} />, el);
    return el;
    }
  };
  // handleEventClick = ({ event, el }) => {
  //   let modalData={...this.state.modalData};
  //   modalData.title=event.title;
  //   modalData.description=event.extendedProps.description;
  //   this.setState({modalData:modalData});
  //   this.setState({show:!this.state.show});
    
  // };

}