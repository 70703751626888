import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    loading:false,
    sendOtpLoading:false,
    isOtpVerified:-1,
    step1:{
    firstSuccess:false,
    token:''
    },
    step2:{
        loading:false,
        secondSuccess:false
    }
};


const initCreatePatient = (state, action) => {
    return updateObject( state, {
      loading:true
    } );
};

const createPatientSuccess = (state, action) => {
    return updateObject( state, {loading:false,step1:{
        firstSuccess:true,
        token:action.data.token
        }} );
};
const createPatientFailed = (state, action) => {
    return updateObject( state, {loading:false,step1:{
        firstSuccess:false,
        token:''
        }} );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.INIT_CREATE_PATIENT: 
            return initCreatePatient(state, action);    
        case actionTypes.CREATE_PAITENT_SUCCESS: 
            return createPatientSuccess(state, action);
        case actionTypes.CREATE_PAITENT_FAILED:
            return createPatientFailed(state, action);
        case actionTypes.PATIENT_SEND_OTP_REQUEST:
            return updateObject( state, {sendOtpLoading:true } );
        case actionTypes.PATIENT_SEND_OTP_SUCCESS:
            return updateObject( state, {sendOtpLoading:false } );   
        case actionTypes.PATIENT_SEND_OTP_FAILED:
            return updateObject(state, {sendOtpLoading:false } ); 
        case actionTypes.PATIENT_OTP_VERIFY_REQUEST:
            return updateObject(state,{isOtpVerified:-1});
        case actionTypes.PATIENT_OTP_VERIFY_SUCCESS:
            return updateObject(state,{isOtpVerified:true});
        case actionTypes.PATIENT_OTP_VERIFY_FAILED:
            return updateObject(state,{isOtpVerified:false}); 
        case actionTypes.PATIENT_PROFILE_COMPLETE_STEP_REQUEST:
        return updateObject( state, {step2:{
            loading:true,
            secondSuccess:false
            }} );
        case actionTypes.PATIENT_PROFILE_COMPLETE_STEP_SUCCESS:
            return updateObject( state, {step2:{
                loading:false,
                secondSuccess:true
                }});
        case actionTypes.PATIENT_PROFILE_COMPLETE_STEP_FAILED:
            return updateObject( state, {step2:{
                loading:false,
                secondSuccess:false
                }});
        default: 
            return state;
    }
};

export default reducer;