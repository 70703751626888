import React from 'react';
import BreadCrumbs from "../../../_metronic/layout/sub-header/components/BreadCrumbs";

import {Link} from 'react-router-dom';

const HeaderBreadcrumb = props => {
return (
<>
<div className="subheader py-12 subheader-transparent" id="kt_subheader">
							<div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap" style={{maxWidth:"100%"}}>
								<div className="d-flex align-items-center flex-wrap mr-1">
									<div className="d-flex flex-column">
										<h2 className="text-white font-weight-bold my-2 mr-5">{props.title}</h2>
										<div className="d-flex align-items-center font-weight-bold my-2">
                              <Link to="/" className="opacity-75 hover-opacity-100">
												<i className="flaticon2-shelter text-white icon-1x"></i>
											</Link>
                                 {props.breadcrumb.map((it,i)=>(
                                    <span key={i}>
                                    <span className="label label-dot label-sm bg-white opacity-75 mx-3" ></span>
                                    <Link to={it.page} className="text-white text-hover-white opacity-75 hover-opacity-100">{it.title}</Link>
                                    </span>
                                 ))}
											
										</div>
									</div>
								</div>
								{props.actions!=undefined && (<div className="d-flex align-items-center">
									{/* <a href="#" className="btn btn-transparent-white font-weight-bold py-3 px-6 mr-2">Reports</a> */}
									<div className="dropdown dropdown-inline ml-2" >
										<a href="#" className="btn btn-white font-weight-bold py-3 px-6" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Quick Action</a>
										<div className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right">
										
												<ul className="navi navi-hover py-5">
													{props.actions.map((it,ind)=>(<React.Fragment key={ind}>
														{it.divider==1 && (<li className="navi-separator my-3"></li>)}
													<li className="navi-item" key={ind}>
												<Link to={it.url} className="navi-link">
													<span className="navi-icon">
														<i className={it.icon}></i>
													</span>
													<span className="navi-text">{it.name}</span>
													{it.notification!=undefined && (<span className="navi-link-badge">
															<span className={`label  label-inline font-weight-bold ${it.ncolor}`}>{it.notification}</span>
													</span>)
													}
												</Link>
											</li>
											</React.Fragment>))}
											</ul>
										</div>
									</div>
								</div>)}
							</div>
						</div>
     </>   
);
}

export default HeaderBreadcrumb;