import React, { Component } from "react";
import Chart from "react-apexcharts";

class BarChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          id: "basic-bar",
          toolbar: {
            show: false
        },
        sparkline: {
            enabled: true
        },
        },
        plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: ['50px'],
                    endingShape: 'rounded'
                },
            },
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 1,
                colors: ['transparent']
            },
            colors: ['#fff'],
        xaxis: {
          categories: ['Covid', 'Gutfit', 'Zydus','Roche','Fibroscan','Covid', 'Gutfit', 'Zydus','Roche','Fibroscan','Covid', 'Gutfit', 'Zydus','Roche','Fibroscan','Covid', 'Gutfit', 'Zydus','Roche','Fibroscan']
        }
      },
      series: [
        {
          name: "Program",
          data: [10, 20, 30, 40,150,60, 70, 80, 30,70,105,90, 80, 170, 60,150,40, 30, 20, 10]
        }
      ]
    };
  }

  render() {
    return (
            <Chart
              options={this.state.options}
              series={this.state.series}
              type="bar"
              height="200px"
            />
    );
  }
}

export default BarChart;
