import React from 'react';
import { FormattedMessage,injectIntl } from "react-intl";

 
export const Step1=intl=>{
  return {
           
    program_id: {
      isProgram:1,
      elementType: 'select',
      label:<FormattedMessage id="PATIENT.STEP1.PROGRAM_CODE" />,
      heading:<FormattedMessage id="PATIENT.STEP1.HEADING.PROGRAM_DETAIL" />,
      error:'',
      elementConfig: {
          options: []
      },
      value: '',
      col:6,
      fullRow:6,
      validation: {
        required: true
      },
      errorMessage:{
       required:'Program code field is required'
      },
      valid: false,
      touched: false
    },
    // userMode: {
    //   elementType: 'switch',
    //   label:'User Mode',
    //   col:6,
    //   fullRow:0,
    //   heading:'',
    //   error:'',
    //   elementConfig: {
    //       type: 'checkbox',
    //   },
    //   value: '',
    //   leftText:'Offline',
    //   validation: {
    //      // required: true
    //   },
    //   errorMessage:{
       
    //   },
    //   valid: true,
    //   //touched: false
    // },
    
    
    // paitentGoal: {
    //   elementType: 'select',
    //   label:'Patient Goal',
    //   heading:'',
    //   error:'',
    //   elementConfig: {
    //       options: [
    //           {value: '', displayValue: 'select patient goal'},
    //           {value: 'US', displayValue: 'USA'},
    //           {value: 'IN', displayValue: 'India'}
    //       ]
    //   },
    //   value: 'IN',
    //   col:6,
    //   fullRow:1,
    //   validation: {
    //     required:true
    //   },
    //   errorMessage:{
    //    required:'PatientGoal is required'
    //   },
    //   valid: false,
    //   touched:false
    // },
              mobileNumber: {
                elementType: 'input',
                label:<FormattedMessage id="PATIENT.STEP1.MOBILE_NUMBER" />,
                col:6,
                fullRow:0,
                error:'',
                divider:1,
                heading:<FormattedMessage id="PATIENT.STEP1.HEADING.PATIENT_CONTACT_DETAIL" />,
                elementConfig: {
                    type: 'text',
                    placeholder:intl.formatMessage({id: 'PATIENT.STEP1.MOBILE_NUMBER.PLACEHOLDER'}),
                },
                value: '',
                validation: {
                    required: true,
                    isNumeric: true,
                    isMobile:true
                },
                errorMessage:{
                  required: 'Mobile Number field is required',
                  isNumeric: 'Mobile Number should be numeric',
                  isMobile:'Mobile number is not valid'
                },
                valid: false,
                touched: false
            },
            otp: {
              elementType: 'input',
              label:<FormattedMessage id="PATIENT.STEP1.OTP" />,
              col:6,
              fullRow:0,
              heading:'',
              error:'',
              successMsg:'',
              elementConfig: {
                  type: 'text',
                  placeholder:intl.formatMessage({id: 'PATIENT.STEP1.OTP.PLACEHOLDER'}),
              },
              value: '',
              validation: {
                  required: true
              },
              errorMessage:{
                required: 'OTP field is required',
              },
              valid: false,
              touched: false,
              otp_resend:true,
              skip_otp:true,
              isOtpMatch:false
          },
          first_name: {
            elementType: 'input',
            label:intl.formatMessage({id: 'PATIENT.STEP1.FIRST_NAME'}),
            col:6,
            fullRow:0,
            heading:'',
            error:'',
            elementConfig: {
                type: 'text',
                placeholder:intl.formatMessage({id: 'PATIENT.STEP1.FIRST_NAME.PLACEHOLDER'}),
            },
            value: '',
            validation: {
                required: true
            },
            errorMessage:{
              required: 'Patient First Name field is required',
            },
            
            valid: false,
            touched: false
        },
        last_name: {
          elementType: 'input',
          label:intl.formatMessage({id: 'PATIENT.STEP1.LAST_NAME'}),
          col:6,
          fullRow:0,
          heading:'',
          error:'',
          elementConfig: {
              type: 'text',
              placeholder: intl.formatMessage({id: 'PATIENT.STEP1.LAST_NAME.PLACEHOLDER'}),
          },
          value: '',
          validation: {
              required: true
          },
          errorMessage:{
            required: 'Patient Last Name field is required',
          },
          
          valid: false,
          touched: false
      },
      gender: {
        elementType: 'select',
        isradio:1,
        label:intl.formatMessage({id: 'PATIENT.STEP1.GENDER'}),
        heading:'',
        elementConfig: {
            options: [
                {value: '1', displayValue: intl.formatMessage({id: 'PATIENT.STEP1.MALE'})},
                {value: '2', displayValue: intl.formatMessage({id: 'PATIENT.STEP1.FEMALE'})}
            ]
        },
        value: '1',
        col:4,
        fullRow:0,
        validation: {
          required: true
        },
          errorMessage:{
            required: 'This field is required',
          },
        valid: true,
        touched: false
      },
    };
      };

    export const Step2={
        dob: {
          elementType: 'input',
          label:'Date of Birth',
          col:4,
          fullRow:8,
          heading:'Patient Profile Information',
          error:'',
          elementConfig: {
              type: 'date',
              placeholder: 'DD/MM/YY',
              //min:'1985-01-01',
              max:new Date().toJSON().slice(0,10).replace(/-/g,'-')
          },
          value: '1985-01-01',
          validation: {
             // required: true
          },
          errorMessage:{
           // required: 'Date of Birth field is required',
          },
          valid: true,
          touched: false
      },
    
    //   age: {
    //     elementType: 'input',
    //     label:'Age',
    //     col:4,
    //     fullRow:4,
    //     heading:'',
    //     error:'',
    //     elementConfig: {
    //         type: 'text',
    //         placeholder: 'Age',
    //     },
    //     value: '',
    //     validation: {
    //         //required: true
    //     },
    //     errorMessage:{
        
    //     },
    //     valid: true,
    //     touched: false
    // },
    
    height: {
      elementType: 'input',
      label:'Height',
      col:4,
      fullRow:0,
      heading:'',
      error:'',
      elementConfig: {
          type: 'text',
          placeholder: 'Enter Height in CM',
      },
      value: '',
      validation: {
          //required: true
          isNumeric:true,
          minValue:30,
          maxValue:213
      },
      errorMessage:{
        isNumeric: 'Height should be numeric',
        minValue:'Min Height should be 30',
        maxValue:'Max Height should be 213',
      },
      valid: true,
      touched: false
    },
    heightCm: {
    elementType: 'select',
    label:"type",
    heading:'',
    error:'',
    elementConfig: {
        options: [
            {value: '1', displayValue: 'CM'},
            {value: '2', displayValue: 'Feet'}

        ]
    },
    value: '1',
    col:2,
    fullRow:0,
    validation: {
    //  required: true
    },
    errorMessage:{
    },
    valid: true,
    touched: false
    },
    
    weight: {
      elementType: 'input',
      label:'Weight',
      col:4,
      fullRow:0,
      heading:'',
      error:'',
      elementConfig: {
          type: 'text',
          placeholder: 'Enter Weight',
      },
      value: '',
      validation: {
          //required: true
          isNumeric: true,
          minValue:2,
          maxValue:150
      },
      errorMessage:{
        isNumeric: 'Weight should be numeric',
        minValue:'Min Weight should be 2',
        maxValue:'Max Weight should be 150',
      },
      valid: true,
      touched: false
    },
    weightType: {
    elementType: 'select',
    label:"type",
    heading:'',
    error:'',
    elementConfig: {
        options: [
            {value: '1', displayValue: 'Kgs'},
            {value: '2', displayValue: 'Pound'}
        ]
    },
    value: '1',
    col:2,
    fullRow:0,
    validation: {
    //  required: true
    },
    errorMessage:{
    },
    valid: true,
    touched: false
    },
    
    waistSize: {
      elementType: 'input',
      label:'Waist',
      col:4,
      fullRow:0,
      heading:'',
      error:'',
      elementConfig: {
          type: 'text',
          placeholder: 'Enter Size',
      },
      value: '',
      validation: {
          //required: true
          isNumeric: true,
          minValue:16,
          maxValue:50
      },
      errorMessage:{
        isNumeric: 'Waist value should be numeric',
        minValue:'Waist Min size should be 16',
        maxValue:'Waist Max size should be 50',

      },
      valid: true,
      touched: false
    },
    waistType: {
    elementType: 'select',
    label:"type",
    heading:'',
    error:'',
    elementConfig: {
        options: [
            {value: 'in', displayValue: 'in'}
        ]
    },
    value: 'in',
    col:2,
    fullRow:6,
    validation: {
    //  required: true
    },
    errorMessage:{
    },
    valid: true,
    touched: false
    },
    activity: {
      elementType: 'select',
      isradio:1,
      label:'Activity',
      heading:'',
      elementConfig: {
          options: [
              {value: '1', displayValue: 'Sedentary'},
              {value: '2', displayValue: 'Active'},
              {value: '3', displayValue: 'Highly Active'}
          ]
      },
      value: '1',
      col:6,
      fullRow:6,
      validation: {
      //  required: true
      },
      errorMessage:{
      },
      error:'',
      valid: true,
      touched: false
    },
    // doctor: {
    //   elementType: 'autocomplete',
    //   label:'Doctor',
    //   heading:'',
    //   error:'',
    //   elementConfig: {
    //       options: [
    //           {value: '', displayValue: 'select doctor'},
    //           {value: 'US', displayValue: 'USA'},
    //           {value: 'IN', displayValue: 'India'}
    //       ]
    //   },
    //   value: 'IN',
    //   col:4,
    //   fullRow:1,
    //   validation: {
    //   },
    //   errorMessage:{
       
    //   },
    //   valid: true
    // },
    
    
    
    address: {
      elementType: 'input',
      label:'House Address',
      col:8,
      fullRow:0,
      heading:'Patient Address',
      divider:true,
      error:'',
      elementConfig: {
          type: 'text',
          placeholder: 'Enter Patient\'s door number, street name,locality',
      },
      value: '',
      validation: {
         // required: true
      },
      errorMessage:{
       // required:'House Address field is required'
       },
      valid:true,
      touched: false
    },
    locality: {
      elementType: 'input',
      label:'Locality',
      col:4,
      fullRow:0,
      heading:'',
      error:'',
      elementConfig: {
          type: 'text',
          placeholder: 'Enter Locality',
      },
      errorMessage:{
       // required:'Locality field is required'
       },
      value: '',
      validation: {
         // required: true
      },
      valid: true,
      touched: false
    },
   
    state: {
      elementType: 'select',
      label:'State',
      heading:'',
      error:'',
      elementConfig: {
          options: [
              {value: '', displayValue: 'select State'},
          ]
      },
      value: '',
      col:4,
      fullRow:0,
      validation: {
          //required: true
      },
      errorMessage:{
      //  required:'State field is required'
      },
      valid: true,
      touched: false
    },
    city: {
      elementType: 'select',
      label:'City',
      col:4,
      fullRow:0,
      heading:'',
      error:'',
      elementConfig:  {
        options: [
            {value: '', displayValue: 'select city'},
        ]
    },
      value: '',
      validation: {
       //   required: true
      },
      errorMessage:{
      //  required:'City field is required'
       },
      valid: true,
      touched: false
    },
    pincode: {
      elementType: 'input',
      label:'Pincode',
      col:4,
      fullRow:0,
      heading:'',
      elementConfig: {
          type: 'text',
          placeholder: 'Enter Pincode',
      },
      value: '',
      validation: {
        //  required: true
      },
      errorMessage:{
     //   required:'Pincode field is required'
       },
      valid: true,
      touched: false
    },
    patientEmail: {
      elementType: 'input',
      label:'Email ID',
      col:8,
      fullRow:0,
      heading:'',
      error:'',
      elementConfig: {
          type: 'email',
          placeholder: 'Enter Patient\'s email address',
      },
      value: '',
      validation: {
        //  required: true,
          isEmail: true
      },
      errorMessage:{
     //   required: 'Email field is required',
        isEmail: 'Email is not valid',
      },
      valid: true,
      touched: false
    },
    
    //    password: {
    //     elementType: 'input',
    //     label:'Password',
    //     col:4,
    //     fullRow:0,
    //     heading:'',
    //     error:'',
    //     elementConfig: {
    //         type: 'password',
    //         placeholder: 'between 8 to 10 characters',
    //     },
    //     value: '',
    //     validation: {
    //         required: true,
    //         minLength: 8,
    //         maxLength: 10,
        
    //     },
    //     errorMessage:{
    //       required: 'Password field is required',
    //       minLength: 'Password min length is not valid',
    //       maxLength: 'Password max length is not valid',
    //     },
    //     valid: false,
    //     touched: false
    // },
    alternativeMobileNumber: {
      elementType: 'input',
      label:'Alternative Mobile Number',
      col:4,
      fullRow:0,
      error:'',
      divider:0,
      heading:'',
      elementConfig: {
          type: 'text',
          placeholder: 'Enter Alternative Mobile Number of the Patient',
      },
      value: '',
      validation: {
          // required: true,
          isNumeric: true,
          isMobile:true
      },
      errorMessage:{
        // required: 'Mobile Number field is required',
       isNumeric: 'Mobile Number should be numeric',
       isMobile:'Mobile number is not valid'
      },
      valid: true,
      touched: false
    },
    
    memberFirstName: {
      elementType: 'input',
      label:'First Name',
      col:4,
      fullRow:0,
      heading:'Family Member Details (Optional)',
      divider:true,
      elementConfig: {
          type: 'text',
          placeholder: 'Enter First Name of the Family Member',
      },
      value: '',
      validation: {
         // required: true
         maxLength: 20
      },
      errorMessage:{
        maxLength: 'Max 20 char is not valid',
      },
      error:'',
      valid:true,
      touched: false
    },
     memberLastName: {
      elementType: 'input',
      label:'Last Name',
      col:4,
      fullRow:4,
      heading:'',
      divider:false,
      elementConfig: {
          type: 'text',
          placeholder: 'Enter Last Name of the Family Member',
      },
      value: '',
      validation: {
         // required: true
         maxLength: 20
      },
      errorMessage:{
        maxLength: 'Max 20 char is not valid',
      },
      error:'',
      valid:true,
      touched: false
    },
    relation: {
      elementType: 'select',
      label:'Relation',
      heading:'',
      elementConfig: {
          options: [
              {value: '', displayValue: 'Choose Relation'}
              // {value: '1', displayValue: 'Father'},
              // {value: '2', displayValue: 'Mother'},
              // {value: '3', displayValue: 'Brother'},
              // {value: '3', displayValue: 'Sister'}
    
          ]
      },
      value: '',
      col:4,
      fullRow:8,
      validation: {
      //  required: true
      },
      errorMessage:{
      },
      error:'',
      valid: true,
      touched: false
    },
    familyMobileNumber: {
      elementType: 'input',
      label:'Mobile Number',
      col:4,
      fullRow:0,
      error:'',
      divider:0,
      heading:'',
      elementConfig: {
          type: 'text',
          placeholder: 'Enter Mobile Number of the Family Member',
      },
      value: '',
      validation: {
          // required: true,
          isNumeric: true,
          isMobile:true
      },
      errorMessage:{
        // required: 'Mobile Number field is required',
        isNumeric: 'Mobile Number should be numeric',
        isMobile:'Mobile number is not valid'

      },
      valid: true,
      touched: false
    },
    // allowAccess: {
    //   elementType: 'mcheckbox',
    //   label:'Allow Family member to access patient\'s account',
    //   col:12,
    //   fullRow:0,
    //   error:'',
    //   divider:0,
    //   heading:'',
    //   elementConfig: {
    //       type: 'checkbox',
    //   },
    //   value: '',
    //   validation: {
    //   },
    //   errorMessage:{
    //   },
    //   valid: true,
    //   touched: false
    // }
    };  
    export const userModeField=value=>({
      elementType: 'switch',
      label:<FormattedMessage id="PATIENT.STEP1.USER_MODE" />,
      col:6,
      fullRow:1,
      heading:'',
      error:'',
      elementConfig: {
          type: 'checkbox',
      },
      value: value,
      leftText:value==1?<FormattedMessage id="PATIENT.STEP1.ONLINE" />:<FormattedMessage id="PATIENT.STEP1.OFFLINE" />,
      validation: {
         // required: true
      },
      errorMessage:{
       
      },
      valid: true,
      //touched: false
    });

    export const associateCounsellor=(counsellor,value)=>({
        elementType: 'select',
        label:<FormattedMessage id="PATIENT.STEP1.ASSOCIATE_COUNSELLOR" />,
        heading:'',
        error:'',
        elementConfig: {
            options:counsellor
        },
        value: value,
        col:6,
        fullRow:1,
        validation: {
          required:true
        },
        errorMessage:{
         required:'Associate counsellor field is required'
        },
        valid: true,
        touched:false
      });


      export const programHCP=(counsellor,required)=>{
        let validation={};
        let errorMesage={};
        let required1=true;
        if(required==1){
          validation={
            required:true
          };
          errorMesage={
            required:'HCP field is required'
          };
          required1=false;
        }
        return ({

        elementType: 'select',
        label:<FormattedMessage id="PATIENT.STEP1.PROGRAM_HCP" />,
        heading:'',
        error:'',
        elementConfig: {
            options:counsellor
        },
        value:'',
        col:6,
        fullRow:1,
        validation:validation,
        errorMessage:errorMesage,
        valid:  required1,
        touched:false
      });
    }

    export const Inches=(colwidth,heading,Placeholder,minVal,maxVal)=>({
      elementType: 'input',
      label:heading,
      col:colwidth,
      fullRow:0,
      heading:'',
      error:'',
      elementConfig: {
          type: 'text',
          placeholder:Placeholder,
      },
      value: '',
      validation: {
          //required: true
          isNumeric: true,
          minValue:minVal,
          maxValue:maxVal
      },
      errorMessage:{
        isNumeric: heading+' should be numeric',
        minValue:'Min Height should be '+minVal,
        maxValue:'Max Height should be '+maxVal,
      },
      valid: true,
      touched: false
    });