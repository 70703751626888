import * as actionTypes from './actionTypes';

/**
 * GET STATE REQUEST
 */
export const getStateRequest=()=>{
    return {
      type: actionTypes.GET_STATE_REQUEST,
    }
  };

  export const getStateSuccess=data=>{
    return {
      type: actionTypes.GET_STATE_SUCCESS,
      data:data
    }
  };


  /**
 * GET CITY REQUEST
 */
export const getCityRequest=state_id=>{
    return {
      type: actionTypes.GET_CITY_REQUEST,
      state_id:state_id
    }
  };

  export const getCitySuccess=data=>{
    return {
      type: actionTypes.GET_CITY_SUCCESS,
      data:data
    }
  };



