import React from "react";
import { CircularProgress, Typography } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import HeaderBreadcrumb from '../../../../components/UI/HeaderBreadcrumb';
import { connect } from 'react-redux';
import KeyParametersField from './KeyParametersField';
import * as actionsMethods from '../../../../store/actions/index';
import './key-parameters.css';
import {Link} from 'react-router-dom';
import ReactDOM from "react-dom";
import $ from 'jquery';
import { removeKtdatatableCache} from '../../../../shared/utility';

const breadcrumb=[
    {
		title: "List",
		page: "patient"
	}, {
		title: "Profile",
		page: "patient"
	},{
		title: "Key Program Parameters",
		page: "patient"
	}];

class KeyParametersListing extends React.Component {
  constructor(props){
      super(props);
		removeKtdatatableCache();
		this.wrapperRef = React.createRef();
		this.state = {
			tab:0,
			keyProgramModules:[],
			isLoading: true
		};
	}

	columns=()=>{
    var _this = this;
		return [
		{
			field: 'id',
			title: '',
			sortable: false,
			width: 30,
			textAlign: 'center',
		},
		{
		  field: 'date',
		  title: 'DATE'
	  },{
		  field: 'time',
		  title: 'Time'
	  }]
  }
  
  columns2=()=>{
    var _this = this;
		return [
		{
			field: '',
			title: '',
			sortable: false,
			width: 10,
	  },{
			field: 'question',
			title: 'Question'
	  },{
			field: 'answer',
			title: 'Answer',
			template: function(row) {
				var div = document.createElement('div');
				ReactDOM.render(_this.htmlf(row),div);
				return div.innerHTML;
			},
	  }]
  }

	htmlf=row=>{
   return ( <>
	<KeyParametersField type={row.type} answer={row.answer} /> 
   </>);
  }

  componentDidMount() {

	let Link = `${process.env.REACT_APP_API_URL}patient-profile/${this.props.match.params.id}/${this.props.match.params.module_id}`;
   const column=this.columns();
   const column2=this.columns2();
    var _this=this;
	var id = this.props.match.params.module_id;

    $(document).ready(function() {
      window.KTDatatableChildRemoteDataSubTable.init(Link,column,this.wrapperRef,column2,id);
    }); //this.props.getKeyParametersRequest({start:this.state.start,length:this.state.rowsPerPage,columns:this.state.serverSortingCol,order:this.state.order,id:this.props.match.params.id,module_id:this.props.match.params.module_id});
    this.props.getPatientModulesRequest({id:this.props.match.params.id});
    
  }
  componentDidUpdate(prevProps,prevState){
	
	const {keyProgramModules}=this.props;
      if(keyProgramModules !=prevProps.keyProgramModules){
        this.setState({ 
            keyProgramModules: keyProgramModules.tabs,
		});     
    }
	
	const {tab}=this.state;
	
	if(tab !=prevState.tab){
		let Link = `${process.env.REACT_APP_API_URL}patient-profile/${this.props.match.params.id}/${this.props.match.params.module_id}`;
	   const column=this.columns();
	   const column2=this.columns2();
		var _this=this;
        window.KTDatatableChildRemoteDataSubTable.init(Link,column,this.wrapperRef,column2,this.props.match.params.module_id);     
    }
  }

  render() {
	
    const { keyProgramModules } = this.state;
	
	let tabs=keyProgramModules.map((item,index)=>(
			<li className={`nav-item `}  key={index}>
				<Link to={`/patient-profile/${this.props.match.params.id}/${item.id}`} className={`nav-link ${this.props.match.params.module_id==item.id?'active':''}`} onClick={(e)=>this.setState({tab:item.id})}>
					<span className="nav-icon"><i className="fa fa-tasks"></i></span>
					<span className="nav-text1">{item.module_name}</span>
				</Link>
			</li>
    ));
	
    return (
      <>		
          <HeaderBreadcrumb breadcrumb={breadcrumb} title="Key program parameters" />
			<div className="card card-custom">
				<div className="card-header flex-wrap border-0 pt-6 pb-0">
					<div className="tab-container col-12">
						<div className="card-header card-header-tabs-line">
							<div className="card-toolbar">
								<ul className="nav nav-tabs nav-bold nav-tabs-line">
									{tabs}
								</ul>
							</div>
						</div>
					</div>
					<div className="card-title">
						<h3 className="card-label">Key program parameters
						<span className="d-block text-muted pt-2 font-size-sm">Sorting &amp; pagination Key program parameters</span></h3>
					</div>
				</div>
				<div className="card-body">
					<div className="mb-7">
						<div className="row align-items-center">
							<div className="col-lg-9 col-xl-8">
								<div className="row align-items-center">
									<div className="col-md-4 my-2 my-md-0">
										<div className="input-icon">
											<input type="text" className="form-control" placeholder="Search..." id="kt_datatable_search_query" />
											<span>
												<i className="flaticon2-search-1 text-muted"></i>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				
					<div className="datatable datatable-bordered datatable-head-custom kt_datatable" id={`kt_datatable_${this.props.match.params.module_id}`}></div>
				</div>
			</div>
      </>
    );

  }
}

const mapStateToProps= state=>{
    return {
        keyParameters:state.keyParametersListing,
        keyProgramModules:state.keyProgramModules,
    }
   }
   const mapDispatchToProps = dispatch => {
     return {
       // getAllQuestionRequest: data => dispatch(actionsMethods.getAllQuestionRequest(data)),
        getKeyParametersRequest: (data) => dispatch(actionsMethods.getKeyParametersRequest(data)),
        getPatientModulesRequest: (data) => dispatch(actionsMethods.getPatientModulesRequest(data)),

 
   }
   }
   export default connect(mapStateToProps, mapDispatchToProps)(KeyParametersListing);
