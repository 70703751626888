import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
     taskType:{
        options:[]
     },
     isSubmitting:false,
     isSubmitSuccess:false,
};
const getTaskTypeSuccess = (state, action) => {
    let data=[];
    if(action.data.length>0){
        for(let i=0;i<action.data.length;i++){
        data.push({value:action.data[i].id,displayValue:action.data[i].name,type:action.data[i].value});
        }
    }
    let empty=[{
        value:'',
        displayValue:'Select Task Type',
        type:''
    }];
   
 let abc=updateObject( state, {
    taskType:{options:[...empty,...data]}
    });
    return abc;
};


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_TASK_TYPE_SUCCESS: 
            return getTaskTypeSuccess(state,action);
        case actionTypes.CREATE_TASK_REQUEST:
            return updateObject(state,{
                isSubmitting:true
            });    
            case actionTypes.CREATE_TASK_REQUEST:
                return updateObject( state, {
                    isSubmitting:true,
                    isSubmitSuccess:false
                    });

        case actionTypes.CREATE_TASK_SUCCESS:
            return updateObject( state, {
                isSubmitting:false,
                isSubmitSuccess:true
                });
        case actionTypes.CREATE_QUESTION_FAILED:
            return updateObject( state, {
                isSubmitting:false,
                isSubmitSuccess:false
                });

        default: 
            return state;
    }
};

export default reducer;
