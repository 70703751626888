export const ProgramFields={
  program_id: {
        elementType: 'select',
        label:'Program',
        heading:'',
        error:'',
        elementConfig: {
            options: []
        },
        value: '',
        col:6,
        fullRow:6,
        validation: {
         // required:true
        },
        errorMessage:{
        // required:'Question Type is required'
        },
        valid: true,
        touched:false
      },
      days_between_call:{
        elementType: 'input',
        label:'Number of days between each call',
        col:6,
        fullRow:6,
        error:'',
        divider:0,
        heading:'',
        elementConfig: {
            type: 'number',
            placeholder: '5 days',
        },
        value: '',
        validation: {
            required: true,
        },
        errorMessage:{
          required: 'Section Name Field is required',
        },
        valid: true,
        touched: false
    }
};




export function callSection(searchModule,sectionNo,item=''){
  let moduleValue=[];
  let moduleObj=[];
  let numberofTime='';
  if(item !=''){
    numberofTime=item.submodule.length>0? item.submodule.length+1:''
   for(let i in item.modules){
      moduleValue.push(item.modules[i].module_id);
   moduleObj=searchModule.options.filter(itt=>parseInt(itt.value)===parseInt(item.modules[i].module_id));
    };
  }
  console.log(moduleValue,searchModule);
  console.log(moduleObj);
return ( 
       { agenda:{
          elementType: 'input',
          label:'Call Agenda',
          col:8,
          fullRow:4,
          error:'',
          divider:0,
          heading:sectionNo>0?`Call ${sectionNo}`:`Call Schedule 0`,
          databaseId:'',
          elementConfig: {
              type: 'text',
              placeholder: 'Enter name of the call/call Agenda',
          },
          value: item==''?'':item.agenda,
          validation: {
              required: true,
          },
          errorMessage:{
            required: 'Call Agenda Field is required',
          },
          valid: item==''?false:true,
          touched: false
      },
      module_id:{
          elementType: 'multiple_autocomplete',
          label:'Module',
          col:8,
          fullRow:4,
          error:'',
          divider:0,
          heading:'Call Questions & Tasks',
          elementConfig: {
            options:searchModule.options
        },
        value: moduleValue,
        valueObj:moduleObj,
          validation: {
              required: true,
          },
          errorMessage:{
            required: 'Question task Field is required',
          },
          valid: moduleValue.length>0?true:false,
          touched: false
      },
      isRepeatCall: {
        elementType: 'mcheckbox',
      label:'Repeat Call',
      label1:'Check this box if the call is to be repeated in the schedule',
      col:12,
      fullRow:0,
      error:'',
      divider:1,
      heading:'',
      elementConfig: {
          type: 'checkbox',
      },
      value: numberofTime==''?false:true,
      validation: {
      },
      errorMessage:{
      },
      valid: true,
      touched: false
      },
      numbertimes:{
        elementType: 'input',
        label:'Number of Times',
        label1:'Select the number of times the call needs to be repeated',
        col:6,
        fullRow:6,
        error:'',
        divider:0,
        heading:'',
        elementConfig: {
            type: 'number',
            placeholder: 'Number of Times',
            disabled:true
        },
        value: numberofTime,
        validation: {
        //    required: true,
        },
        errorMessage:{
          //required: 'Section Name Field is required',
        },
        valid: true,
        touched: false
    },
    // isCallSequence: {
    //     elementType: 'mcheckbox',
    //   label:'Call Sequence',
    //   label1:'Check this box to set call sequence manually',
    //   col:12,
    //   fullRow:0,
    //   error:'',
    //   divider:0,
    //   heading:'',
    //   elementConfig: {
    //       type: 'checkbox',
    //       disabled:true
    //   },
    //   value: '',
    //   validation: {
    //   },
    //   errorMessage:{
    //   },
    //   valid: true,
    //   touched: false
    //   },
    }  
  );
};

export function callSequenceObj(){
return (
    {
        phone_number:{
            elementType: 'input',
            label:'Call Number',
            col:4,
            fullRow:0,
            error:'',
            divider:0,
            heading:'',
            elementConfig: {
                type: 'text',
                placeholder: 'Enter call number',
            },
            value: '',
            validation: {
                required: true,
            },
            errorMessage:{
              required: 'This field is required',
            },
            valid: true,
            touched: false
        },
        noOfDays:{
            elementType: 'input',
            label:'Number of Days',
            col:4,
            fullRow:4,
            error:'',
            divider:0,
            heading:'',
            elementConfig: {
                type: 'nuumber',
                placeholder: '10 days',
            },
            value: '',
            validation: {
                required: true,
            },
            errorMessage:{
              required: 'This field is required',
            },
            valid: true,
            touched: false
        },
    }
)
}



export function subCallSection(searchModule,sectionNo,item=''){
  let moduleValue=[];
  let moduleObj=[];
  if(item !=''){
   for(let i in item.modules){
      moduleValue.push(item.modules[i].module_id);
   moduleObj=searchModule.options.filter(itt=>parseInt(itt.value)===parseInt(item.modules[i].module_id));
    };
  }
return ( 
       { agenda:{
          elementType: 'input',
          label:'Call Agenda',
          col:8,
          fullRow:4,
          error:'',
          divider:0,
          heading:`Call ${sectionNo}`,
          databaseId:item.id,
          elementConfig: {
              type: 'text',
              placeholder: 'Enter name of the call/call Agenda',
          },
          value: item==''?'':item.agenda,
          validation: {
              required: true,
          },
          errorMessage:{
            required: 'Call Agenda Field is required',
          },
          valid: false,
          touched: false
      },
      module_id:{
          elementType: 'multiple_autocomplete',
          label:'Module',
          col:8,
          fullRow:4,
          error:'',
          divider:0,
          heading:'Call Questions & Tasks',
          elementConfig: {
            options:searchModule.options
        },
        value: moduleValue,
        valueObj:moduleObj,
          validation: {
              required: true,
          },
          errorMessage:{
            required: 'Question task Field is required',
          },
          valid: false,
          touched: false
      }
    }  
  );
};
