import React,{useState,useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux'
import { is } from 'date-fns/locale';
import * as actionsMethods from '../../../store/actions/index';
import clsx from "clsx";
import { FormattedMessage } from "react-intl";

const AssignDietChart= React.forwardRef((props, ref)=>{
	let selectedDiet=props.patientInfo.diets.filter(item=>parseInt(item.selected)==1);
	const [dietValue,changeDietValue]=useState({
		value:selectedDiet.length>0?selectedDiet[0].id:'',
		error:'',
		valid:selectedDiet.length>0?true:false,
		touch:false,
		currentViewFile:''
});
const assignDietReducer=useSelector(state=>state.assignDietchartReducer);

const [loading, setLoading] = useState(false);
const [loadingButtonStyle, setLoadingButtonStyle] = useState({
  paddingRight: "1rem"
});
useEffect(() => {
	if(assignDietReducer.isSubmitting==false){
		disableLoading()
	}
  },[assignDietReducer]);
    const changeDietHandler=async (e)=>{
		const value=e.target.value;
		const valid=e.target.value==''?false:true;
		const error=e.target.value==''?"Assign Diet Chart field is required":'';
		let currentViewFile='';
		const findDoc=props.patientInfo.diets.filter(item=>item.id==value);
		if(findDoc.length>0){
			currentViewFile=findDoc[0].link;
		}
		await changeDietValue(prevState => ({
			...prevState,
			error:error,
			valid:valid,
		   value:value,
		   touch:true,
		   currentViewFile:currentViewFile
        }));
	}
	const dispatch = useDispatch()
	const handleSubmit=event=>{
		event.preventDefault();
		dispatch(actionsMethods.assignDietChartRequest({id:dietValue.value,cust_app_id:props.patientInfo.cust_app_id}));
		enableLoading();
	}
	const getValidClass=()=>{
		if(dietValue.touch && dietValue.valid){
			return 'is-valid';
		}else if(dietValue.touch && !dietValue.valid){
			return 'is-invalid';
		}
		return '';
	}

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "1rem" });
  };

    return (<>
	<div className="modal-dialog" role="document">
		<div className="modal-content">
			<div className="modal-header">
				<h5 className="modal-title" id="exampleModalLabel">
					<FormattedMessage id="PATIENT_PROFILE.ASSIGN_DIET_CHART" />
				</h5>
				<button type="button" className="close" onClick={props.closeModal}>
				<span aria-hidden="true">&times;</span>
				</button>
			</div>
						<form className="form" id="kt_form_1" onSubmit={e=>handleSubmit(e)}>
			<div className="modal-body">
																	
																	<div className="form-group mb-6">
																		<select name="diet_id" className={`form-control border-0 form-control-solid text-muted font-size-lg font-weight-bolder pl-2 min-h-50px ${getValidClass()}`} id="exampleSelects" defaultValue={dietValue.value} onChange={e=>changeDietHandler(e)}>
																			<option value="">Assign Diet Chart</option>
																		{props.patientInfo.diets.map((it,i)=>(<option key={i} value={it.id} >{it.name}</option>))}
																		</select>
																		{dietValue.error !='' && (<div className="fv-plugins-message-container"><div data-field="address1" data-validator="notEmpty" className="fv-help-block">{dietValue.error}</div></div>)}
																	</div>
																
																
			</div>
			<div className="modal-footer">
			{selectedDiet.length>0 &&(<a href={`${selectedDiet[0].link}`} target="_blank" className="btn btn-light-primary font-weight-bold" >
														<FormattedMessage id="PATIENT_PROFILE.BTN.VIEW_CURRENT_ASSIGNED_CHART" />
														</a>)}
			{dietValue.currentViewFile !='' &&(<a href={`${dietValue.currentViewFile}`} target="_blank" className="btn btn-light-primary font-weight-bold" >
													<FormattedMessage id="PATIENT_PROFILE.BTN.VIEW_FILE" />
				</a>)}
				<button
                    id="kt_login_signin_submit"
                    type="submit"
                    disabled={assignDietReducer.isSubmitting || !dietValue.valid}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      }
                    )}`}
                    style={loadingButtonStyle}
                  >
						<FormattedMessage id="PATIENT_PROFILE.BTN.ASSIGN" />
                  </button>
			</div>
			</form>
		</div>
	</div>


                                                </>);
});

export default AssignDietChart;