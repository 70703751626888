import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./ducks/auth.duck";
import { metronic } from "../../_metronic";
import patientReducer from './reducers/patient';
import programCodesReducer from './reducers/programCodes';
import doctorsListReducer from './reducers/doctors';
import snackbarNotification from './reducers/snackbarNotification';
import questionReducer from './reducers/question';
import questionlistReducer from './reducers/questionListing';
import taskReducer from './reducers/tasks';
import moduleReducer from './reducers/module';
import callScheduleReducer from './reducers/callSchedule';
import tasksListingReducer from './reducers/tasksListing';
import modulesListingReducer from './reducers/moduleListing';
import patientListingReducer from './reducers/PatientListing';
import editTaskReducer from './reducers/editTask';
import editQuestionReducer from './reducers/editQuestion';
import callListingReducer from './reducers/callListing';
import editCallScheduleReducer from './reducers/editCallSchedule';
import editModuleReducer from './reducers/editModule';
import patientProfileReducer from './reducers/patientProfile';
import  cousellerDashboardReducer from './reducers/counsellorDashboard';
import getUserMode from './reducers/getUserMode';
import keyParametersListingReducer from './reducers/keyParametersListing';
import assignDietchartReducer from './reducers/assignDietChart';
import sendDocSmsReducer from './reducers/sendDocSms';
import careTakerRelation from './reducers/careTakerRelation';
import keyProgramModules from './reducers/keyProgramModules';
import common from './reducers/common';
import editPatientProfile from './reducers/editPatientProfile';
import patientCallSchedule from './reducers/patientCallSchedule';

import navUserManu from './reducers/navUserManu';
export const rootReducer = combineReducers({
  auth: auth.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  patient:patientReducer,
  programCodes:programCodesReducer,
  doctorsList:doctorsListReducer,
  snackbar:snackbarNotification,
  questionReducer:questionReducer,
  questionList:questionlistReducer,
  taskReducer:taskReducer,
  moduleReducer:moduleReducer,
  callScheduleReducer:callScheduleReducer,
  tasksListingReducer:tasksListingReducer,
  modulesListingReducer:modulesListingReducer,
  patientListing:patientListingReducer,
  editTaskReducer:editTaskReducer,
  editQuestionReducer:editQuestionReducer,
  callListing:callListingReducer,
  editCallScheduleReducer:editCallScheduleReducer,
  editModuleReducer:editModuleReducer,
  patientProfile:patientProfileReducer,
  cousellerDashboard:cousellerDashboardReducer,
  getUserModeReducer:getUserMode,
  keyParametersListing:keyParametersListingReducer,
  assignDietchartReducer:assignDietchartReducer,
  sendDocSmsReducer:sendDocSmsReducer,
  careTakerRelation:careTakerRelation,
  keyProgramModules:keyProgramModules,
  commonReducer:common,
  patientProfileInfo:editPatientProfile,
  navUserManu:navUserManu,
  patientCallSchedule:patientCallSchedule
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
