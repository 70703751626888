import * as actionTypes from './actionTypes';

export const getQuestionRequest =  ()  => {
  return {
    type: actionTypes.GET_QUESTION_TYPE_REQUEST,
  };
};
export const getQuestionSuccess =  (data)  => {
    return {
      type: actionTypes.GET_QUESTION_TYPE_SUCCESS,
      data:data
    };
  };

  export const getQuestionFailed =  (data)  => {
    return {
      type: actionTypes.GET_QUESTION_TYPE_FAILED,
      data:data
    };
  };


  /**
 * Create Question api call
*/

export const createQuestionRequest =  (data)  => {
  return {
    type: actionTypes.CREATE_QUESTION_REQUEST,
    data:data
  };
};

export const createQuestionSuccess =  (data)  => {
  return {
    type: actionTypes.CREATE_PAITENT_SUCCESS,
    data:data
  };
};

export const createQuestionFailed =  (data)  => {
  return {
    type: actionTypes.CREATE_PAITENT_FAILED,
    data:data
  };
};



  /**
 * Get All Question api call
*/

export const getAllQuestionRequest =  (data)  => {
  return {
    type: actionTypes.GET_ALL_QUESTION_REQUEST,
    data:data
  };
};

export const getAllQuestionSuccess =  (data)  => {
  return {
    type: actionTypes.GET_ALL_QUESTION_SUCCESS,
    data:data
  };
};

export const getAllQuestionFailed =  (data)  => {
  return {
    type: actionTypes.GET_ALL_QUESTION_FAILED,
    data:data
  };
};

/**
 * Get Connected Question api call
*/

export const getConnectedQuestionRequest =  ()  => {
  return {
    type: actionTypes.GET_CONNECTED_QUESTION_REQUEST,
  };
};

export const getConnectedQuestionSuccess =  (data)  => {
  return {
    type: actionTypes.GET_CONNECTED_QUESTION_SUCCESS,
    data:data
  };
};

export const getConnectedQuestionFailed =  (data)  => {
  return {
    type: actionTypes.GET_CONNECTED_QUESTION_FAILED,
    data:data
  };
};


/**
 * Get Question Properties
 */
export const getQuestionPropertiesRequest = id =>{
  return {
    type:actionTypes.GET_QUESTION_PROPERTIES_REQUEST,
    id:id
  };
};

export const getQuestionPropertiesSuccess = data =>{
  return {
    type:actionTypes.GET_QUESTION_PROPERTIES_SUCCESS,
    data:data
  }
}


  /**
 * Edit Question api call
*/

export const editQuestionRequest =  (data)  => {
  return {
    type: actionTypes.EDIT_QUESTION_REQUEST,
    data:data
  };
};

export const editQuestionSuccess =  (data)  => {
  return {
    type: actionTypes.EDIT_QUESTION_SUCCESS,
    data:data
  };
};

export const editQuestionFailed =  (data)  => {
  return {
    type: actionTypes.EDIT_QUESTION_FAILED,
    data:data
  };
};


